/* eslint-disable react/jsx-props-no-spreading */
import {
  DataTable,
  Link,
  Loading,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from 'carbon-components-react';
import {
  collection,
  doc,
  getFirestore,
  limit,
  query,
  where,
} from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import {
  useFirestoreCollectionData,
  useFirestoreDocData,
} from 'reactfire';

function FilenameCell({ projectId }) {
  const { status, data: value } = useFirestoreCollectionData(
    query(collection(getFirestore(), 'projects'), where('name', '==', projectId), limit(1)),
  );
  if (status === 'loading') {
    return 'Loading...';
  }
  if (!value || value.length === 0) {
    return 'Not Found';
  }
  return (
    <Link href={value[0].adminLink}>
      {value[0].filename}
    </Link>
  );
}

FilenameCell.propTypes = {
  projectId: PropTypes.string.isRequired,
};

function MaterialCell({ projectId }) {
  const { status, data: value } = useFirestoreCollectionData(
    query(collection(getFirestore(), 'projects'), where('name', '==', projectId), limit(1)),
  );
  if (status === 'loading') {
    return 'Loading...';
  }
  if (!value || value.length === 0) {
    return 'Not Found';
  }
  return value[0].material;
}

MaterialCell.propTypes = {
  projectId: PropTypes.string.isRequired,
};

function LineItems({
  lineItems,
}) {
  const headerData = [
    {
      header: 'Filename',
      key: 'filename',
      isSortable: false,
    },
    {
      header: 'Project ID',
      key: 'projectId',
      isSortable: false,
    },
    {
      header: 'Quantity',
      key: 'quantity',
      isSortable: false,
    },
    {
      header: 'Material',
      key: 'material',
      isSortable: false,
    },
    {
      header: 'Is Capped',
      key: 'bonding',
      isSortable: false,
    },
  ];

  const rowData = useMemo(() => {
    const rows = [];
    if (!lineItems) return rows;
    lineItems.forEach((lineItem) => {
      rows.push({
        id: lineItem.projectId + lineItem.bonding + lineItem.quantity,
        filename: lineItem.projectId,
        projectId: lineItem.projectId,
        quantity: lineItem.quantity,
        material: lineItem.projectId,
        bonding: lineItem.bonding,
      });
    });
    return rows;
  }, [lineItems]);

  return (
    <DataTable rows={rowData} headers={headerData} isSortable>
      {({
        rows, headers, getHeaderProps, getTableProps,
      }) => (
        <TableContainer>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader {...getHeaderProps({ header })} isSortable={header.isSortable}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  {row.cells.map((cell) => {
                    switch (cell.info.header) {
                      case 'filename': {
                        return (
                          <TableCell key={cell.id}>
                            <FilenameCell projectId={cell.value} />
                          </TableCell>
                        );
                      }
                      case 'material': {
                        return (
                          <TableCell key={cell.id}>
                            <MaterialCell projectId={cell.value} />
                          </TableCell>
                        );
                      }
                      default:
                        return <TableCell key={cell.id}>{cell.value}</TableCell>;
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  );
}

LineItems.propTypes = {
  lineItems: PropTypes.arrayOf(PropTypes.shape({
    adminLink: PropTypes.string,
    filename: PropTypes.string,
    projectId: PropTypes.string,
    quantity: PropTypes.number,
    bonding: PropTypes.bool,
  })),
};

LineItems.defaultProps = {
  lineItems: [],
};

function OrderDetail({
  id, isOpen, close,
}) {
  const { status, data: order } = useFirestoreDocData(doc(getFirestore(), 'orders', id));
  if (status === 'loading') {
    if (isOpen) {
      return <Loading withOverlay />;
    }
    return null;
  }

  if (typeof document === 'undefined') {
    return null;
  }

  return ReactDOM.createPortal(
    <Modal
      open={isOpen}
      passiveModal
      modalHeading={`Order Details (${order && order.name ? order.name : 'Loading...'})`}
      onRequestClose={close}
    >
      {order ? (
        <>
          <div className="bx--row">
            <div className="bx--col">
              <p>
                <span style={{ fontWeight: 800 }}>Customer:</span>
                {' '}
                {order.customer}
              </p>
              <p>
                <span style={{ fontWeight: 800 }}>Order Date:</span>
                {' '}
                {order.orderDate}
              </p>
            </div>
          </div>
          {(order.shipments || []).map((shipment) => (
            <div className="bx--row">
              <div className="bx--col">
                <p>
                  <span style={{ fontWeight: 800 }}>Target Ship Date:</span>
                  {' '}
                  {shipment.targetShipDate}
                </p>
                <p>
                  <span style={{ fontWeight: 800 }}>Shipping Method:</span>
                  {' '}
                  {shipment.shippingMethod}
                </p>
                <p>
                  <span style={{ fontWeight: 800 }}>Shipping Address:</span>
                  { shipment.address ? (
                    <>
                      <br />
                      <span dangerouslySetInnerHTML={{ __html: shipment.address.replaceAll('\n', '<br/>') }} />
                    </>
                  ) : null }
                </p>
                <LineItems lineItems={shipment.lineItems} />
              </div>
            </div>
          ))}
        </>
      ) : <p>Loading...</p>}
    </Modal>,
    document.body,
  );
}

OrderDetail.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default OrderDetail;
