import { Launch32 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectQuote } from '../../features/parts/partsSlice';

function OpenInFusion({ partID, kind }) {
  const { forgeProjectID } = useSelector(selectQuote(partID));

  const fusionURL = `fusion360://lineageUrn=${encodeURIComponent(forgeProjectID)}&hubUrl=https%3A%2F%2Fparallelfluidics1.autodesk360.com`;

  if (kind === 'button') {
    return (
      <Button
        disabled={!forgeProjectID}
        kind="secondary"
        renderIcon={Launch32}
        onClick={() => { window.open(fusionURL); }}
      >
        Open in Fusion
      </Button>
    );
  }
  return <a href={fusionURL} target="_blank" rel="noopener noreferrer">Open in Fusion</a>;
}

OpenInFusion.defaultProps = {
  kind: 'button',
};

OpenInFusion.propTypes = {
  partID: PropTypes.string.isRequired,
  kind: PropTypes.oneOf(['button', 'link']),
};

export default OpenInFusion;
