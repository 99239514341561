import './index.css';

import Flashbar from '@cloudscape-design/components/flashbar';
import Header from '@cloudscape-design/components/header';
import Pagination from '@cloudscape-design/components/pagination';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Table from '@cloudscape-design/components/table';
import {
  and,
  collection,
  getCountFromServer,
  getDocs,
  getFirestore,
  limit,
  or,
  orderBy,
  query,
  QueryFieldFilterConstraint,
  startAfter,
  where,
} from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

function Filter({
  tableId,
  setConstraints,
  filteringOptions,
  filteringProperties,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const propertyFilters = useMemo(() => {
    const propertyKeys = searchParams.getAll(`${tableId}-key`);
    const operators = searchParams.getAll(`${tableId}-operator`);
    const values = searchParams.getAll(`${tableId}-value`);
    const operation = searchParams.get(`${tableId}-operation`) || 'and';
    const tokens = [];
    for (let i = 0; i < propertyKeys.length; i += 1) {
      tokens.push({
        propertyKey: propertyKeys[i],
        operator: operators[i],
        value: values[i],
      });
    }
    return {
      tokens,
      operation,
    };
  }, [searchParams, tableId]);

  const updateSearchParams = useCallback(({ detail }) => {
    const newSearchParams = {};
    searchParams.forEach((value, key) => {
      if (!key.startsWith(`${tableId}-`)) {
        newSearchParams[key] = value;
      }
    });

    const filteredTokens = detail.tokens.filter(
      (token) => (token.value && token.operator && token.propertyKey),
    );
    if (filteredTokens.length > 0) {
      newSearchParams[`${tableId}-key`] = filteredTokens.map((token) => token.propertyKey);
      newSearchParams[`${tableId}-operator`] = filteredTokens.map((token) => token.operator);
      newSearchParams[`${tableId}-value`] = filteredTokens.map((token) => token.value);
      newSearchParams[`${tableId}-operation`] = detail.operation;
    }
    setSearchParams(newSearchParams);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (propertyFilters.tokens.length === 0) {
      setConstraints([]);
      return;
    }
    const operator = {
      and,
      or,
    }[propertyFilters.operation];
    const filters = [];
    for (let i = 0; i < propertyFilters.tokens.length; i += 1) {
      const token = propertyFilters.tokens[i];
      filters.push(where(
        token.propertyKey,
        { '=': '==', '!=': '!=' }[token.operator],
        token.value,
      ));
    }
    setConstraints([operator(...filters)]);
  }, [propertyFilters]);

  Filter.propTypes = {
    tableId: PropTypes.string.isRequired,
    setConstraints: PropTypes.func.isRequired,
    filteringOptions: PropTypes.arrayOf(PropTypes.shape({
      propertyKey: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    filteringProperties: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      operators: PropTypes.arrayOf(PropTypes.string).isRequired,
      propertyLabel: PropTypes.string.isRequired,
      groupValuesLabel: PropTypes.string.isRequired,
    })).isRequired,
  };

  return (
    <PropertyFilter
      onChange={updateSearchParams}
      query={propertyFilters}
      expandToViewport
      filteringOptions={filteringOptions}
      filteringProperties={filteringProperties}
    />
  );
}

function DataTable({
  id,
  header,
  type,
  filters,
  orderField,
  pageSize,
  columnDefinitions,
  filteringOptions,
  filteringProperties,
  stickyColumns,
  resizableColumns,
}) {
  const [page, setPage] = useState(1);
  const [resultCount, setResultCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [constraints, setConstraints] = useState([]);
  const [error, setError] = useState();
  const [items, setItems] = useState([]);

  const cursors = useRef({});
  const queryParams = useMemo(() => {
    cursors.current = {};
    setPage(1);
    return [
      collection(getFirestore(), type),
      and(...filters, ...constraints),
      orderBy(orderField, 'desc'),
    ];
  }, [type, filters, constraints, orderField]);

  const fetchRequests = useRef({});
  useEffect(() => {
    const requestId = Math.random();
    fetchRequests.current[requestId] = true;
    (async () => {
      const queryParamsCopy = [...queryParams];
      if (page > 1) {
        if (page in cursors.current) {
          queryParamsCopy.push(startAfter(cursors.current[page]));
        } else {
          setError(`No cursor for page ${page}`);
          setItems([]);
          setIsLoading(false);
          return;
        }
      } else {
        const countSnapshot = await getCountFromServer(query(...queryParamsCopy));
        if (fetchRequests.current[requestId]) {
          setResultCount(countSnapshot.data().count);
        }
      }

      setIsLoading(true);
      const snapshot = await getDocs(query(...queryParamsCopy, limit(pageSize)));
      const newItems = [];
      snapshot.forEach((doc) => {
        newItems.push({ id: doc.id, ...doc.data() });
      });
      if (fetchRequests.current[requestId]) {
        setItems(newItems);
        cursors.current[page + 1] = snapshot.docs[snapshot.docs.length - 1];
        setIsLoading(false);
      }
    })();
    return () => { fetchRequests.current[requestId] = false; };
  }, [page, queryParams]);

  return (
    <div className="data-table">
      <Table
        columnDefinitions={columnDefinitions}
        items={items}
        loadingText={`Loading ${type}`}
        loading={isLoading}
        filter={(
          <Filter
            tableId={id}
            setConstraints={setConstraints}
            filteringOptions={filteringOptions}
            filteringProperties={filteringProperties}
          />
        )}
        empty={
          error ? (
            <Flashbar items={[{
              header: error,
              type: 'error',
            }]}
            />
          ) : null
        }
        header={<Header>{header}</Header>}
        pagination={(
          <Pagination
            currentPageIndex={page}
            pagesCount={Math.min(
              Object.keys(cursors.current).length,
              Math.floor(resultCount / pageSize) + 1,
            )}
            openEnd={page < Math.floor(resultCount / pageSize) + 1}
            onChange={({ detail }) => { setPage(detail.currentPageIndex); }}
            disabled={isLoading}
          />
        )}
        stickyColumns={stickyColumns}
        resizableColumns={resizableColumns}
      />
    </div>
  );
}

DataTable.propTypes = {
  id: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.oneOfType([QueryFieldFilterConstraint])),
  pageSize: PropTypes.number.isRequired,
  orderField: PropTypes.string.isRequired,
  columnDefinitions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    cell: PropTypes.func.isRequired,
  })).isRequired,
  filteringOptions: PropTypes.arrayOf(PropTypes.shape({
    propertyKey: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  filteringProperties: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    operators: PropTypes.arrayOf(PropTypes.string).isRequired,
    propertyLabel: PropTypes.string.isRequired,
    groupValuesLabel: PropTypes.string.isRequired,
  })).isRequired,
  stickyColumns: PropTypes.shape({
    first: PropTypes.number,
    last: PropTypes.number,
  }),
  resizableColumns: PropTypes.bool,
};

DataTable.defaultProps = {
  filters: [],
  stickyColumns: undefined,
  resizableColumns: false,
};

export default DataTable;
