import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  parts: {},
  quotes: {},
};

export const partsSlice = createSlice({
  name: 'parts',
  initialState,
  reducers: {
    setPart: (state, action) => {
      state.parts[action.payload.id] = action.payload;
    },
    removePart: (state, action) => {
      delete state.parts[action.payload.id];
    },
    setQuote: (state, action) => {
      state.quotes[action.payload.id] = action.payload;
    },
    removeQuote: (state, action) => {
      delete state.quotes[action.payload.id];
    },
    resetParts: () => initialState,
  },
});

export const {
  setPart, removePart, setQuote, removeQuote, resetParts,
} = partsSlice.actions;

export const selectPart = (partID) => (state) => state.parts.parts[partID] || { id: partID };

export const selectQuote = (partID) => (state) => {
  const part = state.parts.parts[partID];
  if (part && part.quote) {
    return state.parts.quotes[part.quote] || {};
  }
  return {};
};

export const selectParts = (partIDs) => (state) => (
  partIDs.map((partID) => ({ quoteData: selectQuote(partID)(state), ...selectPart(partID)(state) }))
);

export default partsSlice.reducer;
