import {
  Modal,
  TextArea,
} from 'carbon-components-react';
import {
  doc,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useFirestoreDoc } from 'reactfire';
import * as Yup from 'yup';

import { usePLCValue, usePLCWrite } from '../../../features/plc/context';
import { useProgramHandle } from '../hooks/context';

function SessionInfo({ sFirebaseSessionID }) {
  const { data: session, status } = useFirestoreDoc(doc(getFirestore(), 'process_logs', sFirebaseSessionID));
  if (status === 'loading') {
    return <p>Loading...</p>;
  }

  if (status === 'error') {
    return <p>Error fetching session info.</p>;
  }

  if (!session.exists()) {
    return <p>Session not found</p>;
  }

  return (
    <>
      <p>
        <span style={{ fontWeight: 700 }}>Project:</span>
        {' '}
        {session.data().project}
      </p>
      <p>
        <span style={{ fontWeight: 700 }}>Number:</span>
        {' '}
        {session.data().number}
      </p>
    </>
  );
}

SessionInfo.propTypes = {
  sFirebaseSessionID: PropTypes.string.isRequired,
};

function AreYouSureModal({
  isOpen, close, confirm,
}) {
  const write = usePLCWrite();
  const programHandle = useProgramHandle();
  const handleClose = useCallback(() => {
    write({ handle: `${programHandle}.sFirebaseSessionID`, value: '' });
    close();
  }, [close, programHandle, write]);

  if (typeof document === 'undefined') {
    return null;
  }

  return (
    ReactDOM.createPortal(
      <Modal
        size="sm"
        open={isOpen}
        onRequestClose={handleClose}
        modalHeading="Are you sure you want to close without saving any observations?"
        primaryButtonText="Yes"
        secondaryButtonText="Cancel"
        onRequestSubmit={confirm}
      />,
      document.body,
    )
  );
}

AreYouSureModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

function EndAutomationModal({ children }) {
  const initialValues = { observations: '' };

  const validationSchema = Yup.object().shape({
    observations: Yup.string().required('Observation is required'),
  });

  const programHandle = useProgramHandle();
  const sessionHandle = useMemo(() => `${programHandle}.sFirebaseSessionID`, [programHandle]);
  const sessionID = usePLCValue(sessionHandle);

  const [areYouSureModalIsOpen, setAreYouSureModalIsOpen] = useState(false);

  const openHandle = useMemo(() => `${programHandle}.bShowObservationModal`, [programHandle]);
  const isOpen = usePLCValue(openHandle);

  const write = usePLCWrite();

  if (typeof document === 'undefined') {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={async (values, { setFieldValue, setSubmitting }) => {
            await updateDoc(doc(getFirestore(), 'process_logs', sessionID), {
              observations: values.observations,
            });
            setSubmitting(false);
            write({ handle: sessionHandle, value: '' });
            write({ handle: openHandle, value: false });
            setFieldValue('observations', '');
          }}
          onRequestClose={() => { setAreYouSureModalIsOpen(true); }}
        >
          {({
            values, handleBlur, handleChange, handleSubmit, errors,
          }) => (
            <Modal
              size="lg"
              open={isOpen}
              onRequestClose={() => {
                setAreYouSureModalIsOpen(true);
              }}
              modalHeading="Record observations"
              primaryButtonText="Save"
              secondaryButtonText="Cancel"
              onRequestSubmit={handleSubmit}
            >
              {sessionID ? (
                <div className="bx--row">
                  <div className="bx--col">
                    <SessionInfo sFirebaseSessionID={sessionID} />
                  </div>
                </div>
              ) : null}
              <div className="bx--row">
                <div className="bx--col">
                  <TextArea
                    labelText="Observations"
                    id="observations"
                    key="obesrvations"
                    value={values.observations}
                    rows={5}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    invalid={!!errors.observations}
                    invalidText={errors.observations}
                  />
                </div>
              </div>
              {children}
            </Modal>
          )}
        </Formik>,
        document.body,
      )}
      <AreYouSureModal
        isOpen={areYouSureModalIsOpen}
        close={() => { setAreYouSureModalIsOpen(false); }}
        confirm={() => {
          setAreYouSureModalIsOpen(false);
          write({ handle: openHandle, value: false });
        }}
      />
    </>
  );
}

EndAutomationModal.propTypes = {
  children: PropTypes.node,
};

EndAutomationModal.defaultProps = {
  children: null,
};

export default EndAutomationModal;
