import { InlineNotification } from 'carbon-components-react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ForgeReauthButton } from '../../components/Forge';
import {
  fetchForgeItems,
  selectForgeNeedsReauth,
  selectForgeStatus,
} from '../../features/forge/forgeSlice';
import ForgeOAuthCallbackPage from './callback';

function ForgeOAuthPage() {
  const dispatch = useDispatch();
  const needsReauth = useSelector(selectForgeNeedsReauth);
  const forgeStatus = useSelector(selectForgeStatus);

  useEffect(() => {
    if (forgeStatus === 'uninitialized') {
      dispatch(fetchForgeItems({ forceReload: true }));
    }
  }, [dispatch, forgeStatus]);

  return (
    <div>
      {needsReauth ? (
        <InlineNotification
          hideCloseButton
          lowContrast
          kind="warning"
          title="You need to re-authenticate with Autodesk Forge"
        />
      ) : (
        <InlineNotification
          hideCloseButton
          lowContrast
          kind="success"
          title="You are successfully authenticated with Autodesk Forge"
        />
      )}
      <ForgeReauthButton />

      {/* eslint-disable-next-line max-len */}
    </div>
  );
}

export default ForgeOAuthPage;
export { ForgeOAuthCallbackPage };
