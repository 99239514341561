/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unknown-property */
import {
  ArcballControls,
  Center,
  Edges,
} from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import {
  Button,
  DataTable,
  FileUploader,
  Select,
  SelectItem,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from 'carbon-components-react';
import {
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
} from 'firebase/storage';
import occtimportjs from 'occt-import-js';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import * as THREE from 'three';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';

import { selectPart } from '../../features/parts/partsSlice';

const wasmUrl = 'https://cdn.jsdelivr.net/npm/occt-import-js@0.0.18/dist/occt-import-js.wasm';

let occtImportPromise = null;
function loadOcctImport() {
  if (occtImportPromise) {
    return occtImportPromise;
  }
  const occt = occtimportjs({
    locateFile: () => wasmUrl,
  });
  occtImportPromise = occt;
  return occtImportPromise;
}

function Component({
  attributes, material, index,
}) {
  const positions = useMemo(
    () => new Float32Array(attributes.position.array),
    [attributes.position.array],
  );
  const normals = useMemo(() => {
    if (attributes.normal) {
      return new Float32Array(attributes.normal.array);
    }
    return [];
  }, [attributes.normal.array]);
  const indicies = useMemo(() => new Uint32Array(index.array), [index.array]);

  const { color, opacity } = material;

  return (
    // NOTE: If/when we upgrade to V8, we will need to use attach instead of attachObject
    // https://github.com/pmndrs/react-three-fiber/blob/06a603ffe74be8e6d9c22a2854bb124942ed7b7b/docs/tutorials/v8-migration-guide.mdx#real-world-use-cases
    <mesh>
      <bufferGeometry attach="geometry">
        <bufferAttribute attach="index" count={indicies.length} array={indicies} itemSize={1} />
        <bufferAttribute attachObject={['attributes', 'position']} count={positions.length / 3} array={positions} itemSize={3} />
        {attributes.normal ? <bufferAttribute attachObject={['attributes', 'normal']} count={normals.length / 3} array={normals} itemSize={3} /> : null}
      </bufferGeometry>
      <meshPhongMaterial attach="material" color={color} opacity={opacity} transparent={opacity !== 1.0} side={THREE.DoubleSide} />
      <Edges threshold={25} />
    </mesh>
  );
}

Component.propTypes = {
  attributes: PropTypes.shape({
    position: PropTypes.shape({
      array: PropTypes.arrayOf(PropTypes.number),
    }),
    normal: PropTypes.shape({
      array: PropTypes.arrayOf(PropTypes.number),
    }),
  }).isRequired,
  material: PropTypes.shape({
    color: PropTypes.string.isRequired,
    opacity: PropTypes.number,
  }).isRequired,
  index: PropTypes.shape({
    array: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

function Viewer({
  filename, meshes, materials, setDownloadFunc,
}) {
  const {
    camera, size, controls, scene,
  } = useThree();
  const groupRef = React.useRef();

  useEffect(() => {
  // create a scene with a camera and controls
  // https://stackoverflow.com/a/23451803
    const saveScene = ({ options, onSuccess, onFailure }) => {
      const exporter = new GLTFExporter();

      exporter.parse(
        scene,
        onSuccess,
        onFailure,
        options,
      );
    };

    setDownloadFunc(() => () => {
      const onSuccess = (glb) => {
        const blob = new Blob([glb]);

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.download = `${filename}.glb`;
        a.href = window.URL.createObjectURL(blob);

        a.click();
      };

      saveScene({
        options: {
          binary: true,
        },
        onSuccess,
        onFailure: (err) => { console.log({ err }); },
      });
    });
  }, [scene]);

  const centerPart = () => {
    controls.reset();

    const bbox = new THREE.Box3().setFromObject(groupRef.current);
    const center = bbox.getCenter(new THREE.Vector3());

    const radius = Math.max(...bbox.getSize(new THREE.Vector3()));
    camera.position.set(center.x + radius, center.y + radius, center.z + radius);
    camera.zoom = Math.min(size.width, size.height) / radius;
    camera.lookAt(center);
    camera.updateProjectionMatrix();
  };

  // once we have controls in place, center part
  useEffect(() => {
    if (controls) { centerPart(); }
  }, [controls]);

  return (
    <>
      <Center>
        <ambientLight />
        <group ref={groupRef}>
          {meshes.map((m, i) => {
            const { name, attributes, index } = m;
            return (
              <Component
                key={name + i}
                attributes={attributes}
                material={materials[i]}
                index={index}
              />
            );
          })}
        </group>
      </Center>
      <ArcballControls makeDefault />
    </>
  );
}

Viewer.propTypes = {
  filename: PropTypes.string.isRequired,
  meshes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    attributes: PropTypes.shape({
      position: PropTypes.shape({
        array: PropTypes.arrayOf(PropTypes.number),
      }),
      normal: PropTypes.shape({
        array: PropTypes.arrayOf(PropTypes.number),
      }),
    }),
    index: PropTypes.shape({
      array: PropTypes.arrayOf(PropTypes.number),
    }),
  })).isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    opacity: PropTypes.number,
  })).isRequired,
  setDownloadFunc: PropTypes.func.isRequired,
};

function ModelViewer({ fileURL, filename, setDownloadFunc }) {
  const [meshes, setMeshes] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      if (fileURL) {
        const occt = await loadOcctImport();
        if (!occt) {
          setError('Could not load occt-import-js');
          return;
        }
        const response = await fetch(fileURL);
        if (!response.ok) {
          setError(JSON.stringify(response));
          return;
        }
        const buffer = await response.arrayBuffer();
        const fileBuffer = new Uint8Array(buffer);
        const result = occt.ReadStepFile(fileBuffer, null);
        if (!result.success) {
          setError(JSON.stringify(result));
          return;
        }
        setMeshes(result.meshes);
        setMaterials(result.meshes.map(() => ({ opacity: 1.0, color: '#4FAFC3' })));
      }
    })();
  }, [fileURL]);

  if (error) {
    return (
      <p>
        Error loading CAD file:
        {' '}
        {error}
      </p>
    );
  }

  if (meshes.length === 0 || materials.length === 0 || meshes.length !== materials.length) {
    return <p>Loading... Your screen may freeze. Please be patient while the model loads.</p>;
  }

  return (
    <div className="bx--row">
      <div className="bx--col-lg-6">
        <Canvas style={{ minHeight: 400, height: 400 }} orthographic>
          <Viewer
            meshes={meshes}
            materials={materials}
            filename={filename}
            setDownloadFunc={setDownloadFunc}
          />
        </Canvas>
      </div>
      <div className="bx--col-lg-10">
        <DataTable
          rows={materials.map((m, i) => ({ ...m, name: meshes[i].name, id: meshes[i].name + i }))}
          headers={[
            { key: 'name', header: 'Mesh Name' },
            { key: 'color', header: 'Color' },
            { key: 'opacity', header: 'Opacity' },
          ]}
        >
          {({
            rows, headers, getHeaderProps, getTableProps,
          }) => (
            <TableContainer>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => (
                    <TableRow key={row.id}>
                      {row.cells.map((cell) => {
                        switch (cell.info.header) {
                          case 'color': {
                            return (
                              <TableCell key={cell.id}>
                                <input
                                  type="color"
                                  value={cell.value}
                                  onChange={(e) => {
                                    const newMaterials = [...materials];
                                    newMaterials[i].color = e.target.value;
                                    setMaterials(newMaterials);
                                  }}
                                />
                              </TableCell>
                            );
                          }
                          case 'opacity': {
                            return (
                              <TableCell key={cell.id}>
                                <Slider
                                  max={1.0}
                                  min={0.0}
                                  step={0.01}
                                  stepMultiplier={0.05}
                                  value={cell.value}
                                  onChange={({ value }) => {
                                    const newMaterials = [...materials];
                                    newMaterials[i].opacity = parseFloat(value);
                                    setMaterials(newMaterials);
                                  }}
                                  onBlur={({ value }) => {
                                    if (value !== undefined) {
                                      const newMaterials = [...materials];
                                      newMaterials[i].opacity = parseFloat(value);
                                      setMaterials(newMaterials);
                                    }
                                  }}
                                />
                              </TableCell>
                            );
                          }
                          default: {
                            return <TableCell key={cell.id}>{cell.value}</TableCell>;
                          }
                        }
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DataTable>
      </div>
    </div>
  );
}

ModelViewer.propTypes = {
  fileURL: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  setDownloadFunc: PropTypes.func.isRequired,
};

function CADStudio({ partID }) {
  const { uid, fileID, filename } = useSelector(selectPart(partID));

  const LOADING = '<LOADING>';
  const MISSING = '<MISSING>';
  const INVALID = '<INVALID>';

  const [originalFileURL, setOriginalFileURL] = useState(LOADING);
  const [fusionFileURL, setFusionFileURL] = useState(LOADING);
  const [selectedFileType, setSelectedFileType] = useState('');
  const [selectedFileURL, setSelectedFileURL] = useState('');
  useEffect(() => {
    switch (selectedFileType) {
      case 'original': {
        setSelectedFileURL(originalFileURL);
        break;
      }
      case 'fusion': {
        setSelectedFileURL(fusionFileURL);
        break;
      }
      default: {
        setSelectedFileURL('');
        break;
      }
    }
  }, [selectedFileType, originalFileURL, fusionFileURL]);

  useEffect(() => {
    (async () => {
      if (!uid || !fileID || !filename) {
        return;
      }
      const extension = filename.split('.').pop();
      const storage = getStorage();
      if (['stp', 'step'].includes(extension.toLowerCase())) {
        try {
          await getMetadata(ref(storage, `/user-uploads/cad/${uid}/${fileID}.${extension}`));
          const newURL = await getDownloadURL(ref(storage, `/user-uploads/cad/${uid}/${fileID}.${extension}`));
          setOriginalFileURL(newURL);
        } catch (_) {
          setOriginalFileURL(MISSING);
        }
      } else {
        setOriginalFileURL(INVALID);
      }
      try {
        await getMetadata(ref(storage, `/${uid}/cad/${fileID}.stp`));
        const newURL = await getDownloadURL(ref(storage, `/${uid}/cad/${fileID}.stp`));
        setFusionFileURL(newURL);
      } catch (_) {
        setFusionFileURL(MISSING);
      }
    })();
  }, [uid, filename, fileID]);

  const [download, setDownloadFunc] = useState(null);
  useEffect(() => {
    setDownloadFunc(null);
  }, [selectedFileType]);

  return (
    <>
      <div className="bx--row">
        <div className="bx--col">
          <Select
            id="select-file"
            onChange={(e) => { setSelectedFileType(e.target.value); }}
            onBlur={(e) => { setSelectedFileType(e.target.value); }}
            value={selectedFileType}
            hideLabel
          >
            <SelectItem
              text="Select a file to edit"
              value=""
              disabled
            />
            <SelectItem
              text={`Original File${{
                [LOADING]: ' (looking up...)',
                [MISSING]: ' (does not exist)',
                [INVALID]: ' (invalid file type - must be STEP or STP)',
              }[originalFileURL] || ''}`}
              value="original"
              disabled={[LOADING, MISSING, INVALID].includes(originalFileURL)}
            />
            <SelectItem
              text={`Fusion Converted File${{
                [LOADING]: ' (looking up...)',
                [MISSING]: ' (does not exist)',
              }[fusionFileURL] || ''}`}
              value="fusion"
              disabled={[LOADING, MISSING, INVALID].includes(fusionFileURL)}
            />
            <SelectItem
              text="Upload"
              value="upload"
            />
          </Select>
        </div>
        {selectedFileType === 'upload' ? (
          <div className="bx--col">
            <FileUploader
              buttonKind="primary"
              buttonLabel="Upload"
              filenameStatus="edit"
              labelDescription="only .stp and .step files are supported"
              labelTitle="Upload a new file"
              onChange={(e) => {
                const file = e.target.files[0];
                if (!file) {
                  return;
                }
                const fileURL = URL.createObjectURL(file);
                setSelectedFileURL(fileURL);
              }}
              onDelete={() => { setSelectedFileURL(''); }}
            />
          </div>
        ) : null}
        {download ? (
          <div className="bx--col">
            <Button
              kind="primary"
              size="md"
              onClick={() => { download(); }}
            >
              Download
            </Button>
          </div>
        ) : null}
      </div>
      {selectedFileURL && selectedFileURL !== 'upload' ? (
        <div className="bx--row">
          <div className="bx--col">
            <ModelViewer
              fileURL={selectedFileURL}
              filename={filename}
              setDownloadFunc={setDownloadFunc}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}

CADStudio.propTypes = {
  partID: PropTypes.string.isRequired,
};

export default CADStudio;
