import {
  Button, ListItem, TextArea, UnorderedList,
} from 'carbon-components-react';
import {
  doc,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  selectCurrentSession,
  selectCurrentSessionInstronData,
} from '../../../features/processData/processDataSlice';
import BondingLine2Config from '../../HMI/archive/bondingLineV2config';
import FormingLine2Config from '../../HMI/archive/formingLineV2config';
import parseTime from '../../HMI/components/parseTime';

function Overview({ sessionID }) {
  const session = useSelector(selectCurrentSession);
  const instronData = useSelector(selectCurrentSessionInstronData);
  const metadata = instronData?.metadata;

  const initialValues = {
    hypothesis: session.hypothesis,
    observations: session.observations,
  };

  const metadataHeaders = useMemo(() => {
    if (!metadata) {
      return [];
    }
    return Object.keys(metadata).sort();
  }, [metadata]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={false}
      onSubmit={async (values) => {
        await updateDoc(doc(getFirestore(), 'process_logs', sessionID), {
          hypothesis: values.hypothesis,
          observations: values.observations,
        });
      }}
    >
      {({
        values, handleBlur, handleChange, handleSubmit, errors, isSubmitting,
      }) => (
        <>
          <div className="bx--row">
            <div className="bx--col">
              <h1>{`${session.project} - ${session.number}`}</h1>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <TextArea
                labelText="Hypothesis"
                id="hypothesis"
                key="hypothesis"
                value={values.hypothesis}
                rows={3}
                onBlur={handleBlur}
                onChange={handleChange}
                invalid={!!errors.hypothesis}
                invalidText={errors.hypothesis}
              />
            </div>
            <div className="bx--col">
              <TextArea
                labelText="Observations"
                id="observations"
                key="observations"
                value={values.observations}
                rows={3}
                onBlur={handleBlur}
                onChange={handleChange}
                invalid={!!errors.observations}
                invalidText={errors.observations}
              />
            </div>
          </div>
          {(initialValues.observations !== values.observations
          || initialValues.hypothesis !== values.hypothesis ? (
            <div className="bx--row">
              <div className="bx--col">
                <Button
                  kind="primary"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Save changes
                </Button>
              </div>
            </div>
            ) : null)}
          <div className="bx--row">
            {session.station === 'formingLineV1' && session.plcConfig ? (
              <div className="bx--col">
                <h4>Forming Parameters</h4>
                <UnorderedList>
                  <ListItem>{`Top Heater Setpoint: ${session.plcConfig?.topHeaterSetpoint}`}</ListItem>
                  <ListItem>{`Top Heater Standby: ${session.plcConfig?.topHeaterStandby}`}</ListItem>
                  <ListItem>{`Bottom Heater Setpoint: ${session.plcConfig?.bottomHeaterSetpoint}`}</ListItem>
                  <ListItem>{`Bottom Heater Standby: ${session.plcConfig?.bottomHeaterStandby}`}</ListItem>
                </UnorderedList>
              </div>
            ) : null}
            {session.station === 'bondingLineV0' && session.plcConfig ? (
              <div className="bx--col">
                <h4>Bonding Parameters</h4>
                <UnorderedList>
                  <ListItem>{`Loading Temp: ${session.plcConfig?.loadingTemp}`}</ListItem>
                  <ListItem>{`Bonding Temp: ${session.plcConfig?.bondingTemp}`}</ListItem>
                  <ListItem>{`Cold Heater Temp: ${session.plcConfig?.coldHeaterTemp}`}</ListItem>
                  <ListItem>{`Top Annealing Temp: ${session.plcConfig?.topAnnealingTemp}`}</ListItem>
                  <ListItem>{`Bottom Annealing Temp: ${session.plcConfig?.bottomAnnealingTemp}`}</ListItem>
                  <ListItem>{`Presoak Time: ${parseTime(session.plcConfig?.presoakTime || 0)}`}</ListItem>
                  <ListItem>{`Bond Time: ${parseTime(session.plcConfig?.bondTime || 0)}`}</ListItem>
                </UnorderedList>
              </div>
            ) : null}
            {session.station === 'formingLineV2' && session.plcConfig && session.plcConfig.version === FormingLine2Config.version ? (
              <div className="bx--col">
                <h4>Forming Parameters</h4>
                <UnorderedList>
                  {FormingLine2Config.rows.map((row) => (
                    <React.Fragment key={row.header}>
                      {row.values.map((item) => {
                        const { handle, type, label } = item;
                        const value = session.plcConfig[handle];
                        if (value !== undefined) {
                          return (
                            <ListItem key={handle}>{`${label}: ${type === 'TIME' ? parseTime(value) : value}`}</ListItem>
                          );
                        }
                        return null;
                      })}
                    </React.Fragment>
                  ))}
                </UnorderedList>
              </div>
            ) : null}
            {session.station === 'bondingLineV2' && session.plcConfig && session.plcConfig.version === BondingLine2Config.version ? (
              <div className="bx--col">
                <h4>Bonding Parameters</h4>
                <UnorderedList>
                  {BondingLine2Config.rows.map((row) => (
                    <React.Fragment key={row.header}>
                      {row.values.map((item) => {
                        const { handle, type, label } = item;
                        const value = session.plcConfig[handle];
                        if (value !== undefined) {
                          return (
                            <ListItem key={handle}>{`${label}: ${type === 'TIME' ? parseTime(value) : value}`}</ListItem>
                          );
                        }
                        return null;
                      })}
                    </React.Fragment>
                  ))}
                </UnorderedList>
              </div>
            ) : null}
            {metadataHeaders && metadataHeaders.length > 0 ? (
              <div className="bx--col">
                <h4>Instron Metadata</h4>
                <UnorderedList>
                  {metadataHeaders.map((key) => (
                    <ListItem key={key}>{`${key}: ${metadata[key]}`}</ListItem>
                  ))}
                </UnorderedList>
              </div>
            ) : null}
          </div>
        </>
      )}
    </Formik>
  );
}

Overview.propTypes = {
  sessionID: PropTypes.string.isRequired,
};

export default Overview;
