import '@carbon/charts/styles.css';

import { LineChart } from '@carbon/charts-react';
import { InlineNotification } from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { queryProcessData, selectProcessData } from '../../../features/processData/processDataSlice';

function ProcessGraph({ processType, chipName }) {
  const { data, status, error } = useSelector(selectProcessData({ processType, chipName }));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(queryProcessData({ processType, chipName }));
  }, [dispatch, chipName]);
  const chartData = useMemo(() => {
    if (!data) {
      return [];
    }

    const tempData = [];
    for (let i = 0; i < data.length; i += 1) {
      const {
        heater_1_setpoint: heater1Setpoint,
        heater_1_temp: heater1Temp,
        heater_2_setpoint: heater2Setpoint,
        heater_2_temp: heater2Temp,
        heater_3_setpoint: heater3Setpoint,
        heater_3_temp: heater3Temp,
        heater_4_setpoint: heater4Setpoint,
        heater_4_temp: heater4Temp,
        forming_force: formingForce,
        timestamp,
      } = data[i];

      switch (processType) {
        case 'forming': {
          tempData.push({ group: 'Heater 1 Temp', timestamp: timestamp.value, temperature: heater1Temp });
          tempData.push({ group: 'Heater 1 Setpoint', timestamp: timestamp.value, temperature: heater1Setpoint });
          tempData.push({ group: 'Heater 2 Temp', timestamp: timestamp.value, temperature: heater2Temp });
          tempData.push({ group: 'Heater 2 Setpoint', timestamp: timestamp.value, temperature: heater2Setpoint });
          tempData.push({ group: 'Force', timestamp: timestamp.value, force: formingForce });
          break;
        }
        case 'bonding': {
          tempData.push({ group: 'Heater 3 Temp', timestamp: timestamp.value, temperature: heater3Temp });
          tempData.push({ group: 'Heater 3 Setpoint', timestamp: timestamp.value, temperature: heater3Setpoint });
          tempData.push({ group: 'Heater 4 Temp', timestamp: timestamp.value, temperature: heater4Temp });
          tempData.push({ group: 'Heater 4 Setpoint', timestamp: timestamp.value, temperature: heater4Setpoint });
          break;
        }
        default:
          break;
      }
    }
    return tempData;
  }, [data]);

  if (error) {
    return (
      <InlineNotification
        hideCloseButton
        kind="error"
        title={error}
        lowContrast
      />
    );
  }

  const options = {
    title: `${{ forming: 'Forming', bonding: 'Bonding' }[processType]} data for chip ${chipName}`,
    axes: {
      bottom: {
        title: 'Time',
        mapsTo: 'timestamp',
        scaleType: 'time',
      },
      left: {
        mapsTo: 'temperature',
        title: 'Temperature (F)',
        scaleType: 'linear',
        includeZero: false,
      },
      right: {
        mapsTo: 'force',
        title: 'Force (kN)',
        scaleType: 'linear',
        correspondingDatasets: [
          'Force',
        ],
      },
    },
    curve: 'curveLinear',
    data: { loading: status === 'loading' },
    points: { enabled: false },
    height: '600px',
    tooltip: {
      showTotal: false,
      valueFormatter: (value) => {
        if (value instanceof Date) {
          return value.toLocaleDateString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric' }).split(' ')[1];
        }
        return value;
      },
    },
    legend: {
      truncation: { type: 'none' },
    },
  };

  return (
    <LineChart
      data={chartData}
      options={options}
    />
  );
}

ProcessGraph.propTypes = {
  processType: PropTypes.oneOf(['forming', 'bonding']).isRequired,
  chipName: PropTypes.string.isRequired,
};

export default ProcessGraph;
