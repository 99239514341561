import React from 'react';
import { useParams } from 'react-router-dom';

import { ProcessGraph } from '../../../components/ProcessData/v0';

export default function FormingData() {
  const { chipName } = useParams();

  return (
    <ProcessGraph processType="forming" chipName={chipName} />
  );
}
