import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useProgramHandle } from '../hooks/context';
import PLCNumberInput from './plcNumberInput';
import PLCTimeInput from './plcTimeInput';

function PIDValues({
  sideHandle,
  heaterHandle,
  engaged,
}) {
  const programHandle = useProgramHandle();
  const handleBase = useMemo(() => `${programHandle}.st${sideHandle}${heaterHandle}Heater${engaged ? 'Engaged' : 'Disengaged'}ParaControllerExternal`, [programHandle, sideHandle, heaterHandle, engaged]);

  return (
    <div className="bx--col">
      <div className="bx--row">
        <div className="bx--col">
          <h4>
            {`${sideHandle} ${heaterHandle} PID (when ${engaged ? 'engaged' : 'disengaged'})`}
          </h4>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <PLCNumberInput
            label="KpHeat"
            handle={`${handleBase}.KpHeat`}
            type="float"
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <PLCTimeInput
            label="TnHeat"
            handle={`${handleBase}.TnHeat`}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <PLCTimeInput
            label="TvHeat"
            handle={`${handleBase}.TvHeat`}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <PLCTimeInput
            label="TdHeat"
            handle={`${handleBase}.TdHeat`}
          />
        </div>
      </div>
    </div>
  );
}

PIDValues.propTypes = {
  sideHandle: PropTypes.string.isRequired,
  heaterHandle: PropTypes.string.isRequired,
  engaged: PropTypes.bool,
};

PIDValues.defaultProps = {
  engaged: false,
};

export default PIDValues;
