import { SkeletonText, Tag } from 'carbon-components-react';
import PropTypes from 'prop-types';
import React from 'react';

import { usePLCReady, usePLCValue } from '../../../features/plc/context';
import { useProgramHandle } from '../hooks/context';

function HeaterStatus({ sideHandle, heaterHandle }) {
  const programHandle = useProgramHandle();

  const ready = usePLCReady();
  const heaterTemp = usePLCValue(`${programHandle}.${sideHandle}${heaterHandle}RTD`);
  const heaterOn = usePLCValue(`${programHandle}.${sideHandle}${heaterHandle}Heater`);
  if (!ready) {
    return (
      <>
        <div className="bx--row">
          <div className="bx--col">
            <SkeletonText width="100%" />
          </div>
        </div>
        <div className="bx--row" style={{ paddingBottom: 112 }}>
          <div className="bx--col">
            <Tag style={{ width: '100%' }} type="gray">Loading...</Tag>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="bx--row">
        <div className="bx--col">
          <p style={{ textAlign: 'center', fontSize: 16 }}>
            {`${heaterHandle} Temp: `}
            <span style={{ fontWeight: 800 }}>
              {heaterTemp / 10 }
              &deg;C
            </span>
          </p>
        </div>
      </div>
      <div className="bx--row" style={{ paddingBottom: 112 }}>
        <div className="bx--col">
          {heaterOn ? (
            <Tag style={{ width: '100%' }} type="red">On</Tag>
          ) : (
            <Tag style={{ width: '100%' }} type="gray">Off</Tag>
          )}
        </div>
      </div>
    </>
  );
}

HeaterStatus.propTypes = {
  sideHandle: PropTypes.string.isRequired,
  heaterHandle: PropTypes.string.isRequired,
};

export default HeaterStatus;
