import { InlineNotification, Loading } from 'carbon-components-react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

function ForgeOAuthCallbackPage() {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const code = searchParams.get('code');

  if (!code) {
    return (
      <InlineNotification
        title="Error"
        subtitle="We didn't receive the authorization code from Autodesk Forge"
        kind="error"
        hideCloseButton
        lowContrast
        style={{ minWidth: '100%', width: '100%' }}
      />
    );
  }

  useEffect(() => {
    if (code) {
      const functions = getFunctions();
      const forgeOauthCallback = httpsCallable(functions, 'forgeOauthCallback');
      forgeOauthCallback({ code })
        .then(() => {
          navigate('/forge', { replace: true });
        })
        .catch((err) => {
          setError(err.message);
        });
    }
  }, [code]);

  if (error) {
    return (
      <InlineNotification
        title="Error"
        subtitle={error}
        kind="error"
        hideCloseButton
        lowContrast
        style={{ minWidth: '100%', width: '100%' }}
      />
    );
  }

  return (
    <Loading withOverlay />
  );
}

export default ForgeOAuthCallbackPage;
