import createPlcSlice from './plcSlice';

export const {
  reducer,
  connect,
  disconnect,
  write,
  selectValues,
  selectStatus,
  selectWriteStatus,
  selectError,
} = createPlcSlice({
  sliceName: 'line2PlcData',
  url: 'https://cx-729f70.live.parallelfluidics.com/TcAdsWebService/TcAdsWebService.dll',
});

export default reducer;
