const config = {
  version: '2023-10-31',
  rows: [
    {
      header: 'Times',
      values: [
        {
          handle: 'PrgBond2.tPresoakTime',
          label: 'Presoak Time',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.tBondTime',
          label: 'Bond Time',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.tSuperheatWaitTime',
          label: 'Superheat Wait Time',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.tAnnealingWaitTime',
          label: 'Annealing Wait Time',
          type: 'TIME',
        },
      ],
    },
    {
      header: 'Loading Temperatures',
      values: [
        {
          handle: 'PrgBond2.fUpperLoadingTemp',
          label: 'Upper Loading Temperature',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.fUpperLoadingTempDisengaged',
          label: 'Upper Loading Temp (Disengaged)',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.fLowerLoadingTemp',
          label: 'Lower Loading Temperature',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.fLowerLoadingTempDisengaged',
          label: 'Lower Loading Temp (Disengaged)',
          type: 'LREAL',
        },
      ],
    },
    {
      header: 'Bonding Temperatures',
      values: [
        {
          handle: 'PrgBond2.fBondingTemp',
          label: 'Bonding Temperature',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.fBondingTempDisengaged',
          label: 'Bonding Temp (Disengaged)',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.fColdHeaterTemp',
          label: 'Cold Heater Temperature',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.fColdHeaterTempDisengaged',
          label: 'Cold Heater Temp (Disengaged)',
          type: 'LREAL',
        },
      ],
    },
    {
      header: 'Bonding Tolerance Bands',
      values: [
        {
          handle: 'PrgBond2.fBondingSetpoingTolerance',
          label: 'Bonding Setpoint Tolerance Band',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.fColdHeaterSetpoingTolerance',
          label: 'Cold Heater Setpoint Tolerance Band',
          type: 'LREAL',
        },
      ],
    },
    {
      header: 'Superheat Temperatures',
      values: [
        {
          handle: 'PrgBond2.fTopSuperheatTemp',
          label: 'Top Superheat Temperature',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.fTopSuperheatTempDisengaged',
          label: 'Top Superheat Temp (Disengaged)',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.fBottomSuperheatTemp',
          label: 'Bottom Superheat Temperature',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.fBottomSuperheatTempDisengaged',
          label: 'Bottom Superheat Temp (Disengaged)',
          type: 'LREAL',
        },
      ],
    },
    {
      header: 'Superheat Tolerance Bands',
      values: [
        {
          handle: 'PrgBond2.fTopSuperheatTolerance',
          label: 'Top Superheat Tolerance Band',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.fBottomSuperheatTolerance',
          label: 'Bottom Superheat Tolerance Band',
          type: 'LREAL',
        },
      ],
    },
    {
      header: 'Annealing Temperatures',
      values: [
        {
          handle: 'PrgBond2.fTopAnnealingTemp',
          label: 'Top Annealing Temperature',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.fTopAnnealingTempDisengaged',
          label: 'Top Annealing Temp (Disengaged)',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.fBottomAnnealingTemp',
          label: 'Bottom Annealing Temperature',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.fBottomAnnealingTempDisengaged',
          label: 'Bottom Annealing Temp (Disengaged)',
          type: 'LREAL',
        },
      ],
    },
    {
      header: 'Front heater PIDs',
      values: [
        {
          handle: 'PrgBond2.stLowerFrontHeaterEngagedParaControllerExternal.KpHeat',
          label: 'Lower Front Heater PID - Kp',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.stLowerFrontHeaterEngagedParaControllerExternal.TnHeat',
          label: 'Lower Front Heater PID - Tn',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stLowerFrontHeaterEngagedParaControllerExternal.TvHeat',
          label: 'Lower Front Heater PID - Tv',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stLowerFrontHeaterEngagedParaControllerExternal.TdHeat',
          label: 'Lower Front Heater PID - Td',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stUpperFrontHeaterEngagedParaControllerExternal.KpHeat',
          label: 'Upper Front Heater PID - Kp',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.stUpperFrontHeaterEngagedParaControllerExternal.TnHeat',
          label: 'Upper Front Heater PID - Tn',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stUpperFrontHeaterEngagedParaControllerExternal.TvHeat',
          label: 'Upper Front Heater PID - Tv',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stUpperFrontHeaterEngagedParaControllerExternal.TdHeat',
          label: 'Upper Front Heater PID - Td',
          type: 'TIME',
        },
      ],
    },
    {
      header: 'Rear heater PIDs (engaged)',
      values: [
        {
          handle: 'PrgBond2.stLowerRearHeaterEngagedParaControllerExternal.KpHeat',
          label: 'Lower Rear Heater PID - Kp',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.stLowerRearHeaterEngagedParaControllerExternal.TnHeat',
          label: 'Lower Rear Heater PID - Tn',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stLowerRearHeaterEngagedParaControllerExternal.TvHeat',
          label: 'Lower Rear Heater PID - Tv',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stLowerRearHeaterEngagedParaControllerExternal.TdHeat',
          label: 'Lower Rear Heater PID - Td',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stUpperRearHeaterEngagedParaControllerExternal.KpHeat',
          label: 'Upper Rear Heater PID - Kp',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.stUpperRearHeaterEngagedParaControllerExternal.TnHeat',
          label: 'Upper Rear Heater PID - Tn',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stUpperRearHeaterEngagedParaControllerExternal.TvHeat',
          label: 'Upper Rear Heater PID - Tv',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stUpperRearHeaterEngagedParaControllerExternal.TdHeat',
          label: 'Upper Rear Heater PID - Td',
          type: 'TIME',
        },
      ],
    },
    {
      header: 'Front heater PIDs (disengaged)',
      values: [
        {
          handle: 'PrgBond2.stLowerFrontHeaterDisengagedParaControllerExternal.KpHeat',
          label: 'Lower Front Heater PID - Kp',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.stLowerFrontHeaterDisengagedParaControllerExternal.TnHeat',
          label: 'Lower Front Heater PID - Tn',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stLowerFrontHeaterDisengagedParaControllerExternal.TvHeat',
          label: 'Lower Front Heater PID - Tv',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stLowerFrontHeaterDisengagedParaControllerExternal.TdHeat',
          label: 'Lower Front Heater PID - Td',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stUpperFrontHeaterDisengagedParaControllerExternal.KpHeat',
          label: 'Upper Front Heater PID - Kp',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.stUpperFrontHeaterDisengagedParaControllerExternal.TnHeat',
          label: 'Upper Front Heater PID - Tn',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stUpperFrontHeaterDisengagedParaControllerExternal.TvHeat',
          label: 'Upper Front Heater PID - Tv',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stUpperFrontHeaterDisengagedParaControllerExternal.TdHeat',
          label: 'Upper Front Heater PID - Td',
          type: 'TIME',
        },
      ],
    },
    {
      header: 'Rear heater PIDs (disengaged)',
      values: [
        {
          handle: 'PrgBond2.stLowerRearHeaterDisengagedParaControllerExternal.KpHeat',
          label: 'Lower Rear Heater PID - Kp',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.stLowerRearHeaterDisengagedParaControllerExternal.TnHeat',
          label: 'Lower Rear Heater PID - Tn',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stLowerRearHeaterDisengagedParaControllerExternal.TvHeat',
          label: 'Lower Rear Heater PID - Tv',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stLowerRearHeaterDisengagedParaControllerExternal.TdHeat',
          label: 'Lower Rear Heater PID - Td',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stUpperRearHeaterDisengagedParaControllerExternal.KpHeat',
          label: 'Upper Rear Heater PID - Kp',
          type: 'LREAL',
        },
        {
          handle: 'PrgBond2.stUpperRearHeaterDisengagedParaControllerExternal.TnHeat',
          label: 'Upper Rear Heater PID - Tn',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stUpperRearHeaterDisengagedParaControllerExternal.TvHeat',
          label: 'Upper Rear Heater PID - Tv',
          type: 'TIME',
        },
        {
          handle: 'PrgBond2.stUpperRearHeaterDisengagedParaControllerExternal.TdHeat',
          label: 'Upper Rear Heater PID - Td',
          type: 'TIME',
        },
      ],
    },
  ],
};

export default config;
