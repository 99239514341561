import { Button, TextInput } from 'carbon-components-react';
import {
  getAuth,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import React, { useCallback, useContext, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import FirebaseContext from '../../app/firebase';

// https://github.com/remix-run/react-router/blob/5dc07e20e38ecda14b6f6918998a62aa42416fe2/examples/auth/src/App.tsx#L145-L181
function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tokenErr, setTokenErr] = useState(null);

  const { user, isReady: isFirebaseReady } = useContext(FirebaseContext);

  const location = useLocation();
  let from = location.state?.from?.pathname || '/';
  if (location.state?.from?.search) {
    from += location.state.from.search;
  }

  // set up reCAPTCHA verifier
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const recaptchaRef = useCallback((node) => {
    if (node !== null) {
      setRecaptchaVerifier(new RecaptchaVerifier(node, { size: 'invisible' }, getAuth()));
    }
  }, []);
  const [verificationID, setVerificationID] = useState(null);
  const [resolverState, setResolver] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const username = formData.get('username');
    const password = formData.get('password');
    const verificationCode = formData.get('verificationCode');

    setIsLoading(true);
    setError(null);

    if (!verificationID) {
      try {
        await signInWithEmailAndPassword(getAuth(), username, password);
      } catch (err) {
        if (err.code === 'auth/multi-factor-auth-required') {
          try {
            const resolver = getMultiFactorResolver(getAuth(), err);
            setResolver(resolver);
            // Ask user which second factor to use.
            if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
              const phoneInfoOptions = {
                multiFactorHint: resolver.hints[0],
                session: resolver.session,
              };
              const phoneAuthProvider = new PhoneAuthProvider(getAuth());
              // Send SMS verification code
              const returnedVerificationID = await phoneAuthProvider.verifyPhoneNumber(
                phoneInfoOptions,
                recaptchaVerifier,
              );

              // Save verification ID to state.
              setVerificationID(returnedVerificationID);
            }
          } catch (mfaErr) {
            setError(mfaErr.message);
          }
        } else {
          setError(err.message);
        }
        setIsLoading(false);
      }
    } else {
      try {
      // Ask user for the SMS verification code.
        const cred = PhoneAuthProvider.credential(verificationID, verificationCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        await resolverState.resolveSignIn(multiFactorAssertion);
      } catch (err) {
        setTokenErr(err.message);
      }
      setIsLoading(false);
    }
  };

  if (user) {
    return <Navigate to={from} replace />;
  }

  return (
    <>
      {isFirebaseReady && <div ref={recaptchaRef} />}
      <div className="bx--row">
        <div className="bx--col">
          <p>
            You must log in to view
            {' '}
            {from}
          </p>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="bx--row">
          <div className="bx--col">
            <TextInput
              id="login-username"
              type="text"
              name="username"
              labelText="Username"
              disabled={isLoading || !!verificationID}
              invalid={!!error}
              invalidText={error}
            />
          </div>
          <div className="bx--col">
            <TextInput
              id="login-password"
              type="password"
              name="password"
              labelText="Password"
              disabled={isLoading || !!verificationID}
            />
          </div>
        </div>
        <div className="bx--row" style={!verificationID ? { display: 'none' } : {}}>
          <div className="bx--col">
            <TextInput
              id="login-verificationCode"
              type="text"
              name="verificationCode"
              labelText="MFA token"
              disabled={isLoading}
              invalid={!!tokenErr}
              invalidText={tokenErr}
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <Button
              type="submit"
              disabled={isLoading}
            >
              Login
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}

export default Login;
