import { InlineNotification } from 'carbon-components-react';
import PropTypes from 'prop-types';
import React from 'react';

import SummaryTable from '../../components/ProcessData';

export default function SummaryPage({ processType }) {
  if (!['forming', 'bonding'].includes(processType)) {
    return (
      <InlineNotification
        title="Invalid process"
        subtitle={`"${processType}" is not a valid process`}
        kind="error"
        hideCloseButton
        lowContrast
        style={{ minWidth: '100%', width: '100%' }}
      />
    );
  }

  return <SummaryTable processType={processType} pageSize={25} />;
}

SummaryPage.propTypes = {
  processType: PropTypes.string.isRequired,
};
