import { where } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Link } from 'react-router-dom';

import DataTable from '../../DataTable';
import parseTime from '../../HMI/components/parseTime';
import UserEmail from '../../UserEmail';

function SummaryTable({ processType, pageSize, version }) {
  const station = useMemo(() => ({
    forming: { v1: 'formingLineV1', v2: 'formingLineV2' }[version],
    bonding: { v0: 'bondingLineV0', v2: 'bondingLineV2' }[version],
  }[processType]), [processType, version]);

  const linkCell = useCallback(
    (e) => <Link to={`/process-data/${version}/${processType}/${e.id}`}>{e.number}</Link>,
    [processType, version],
  );
  const operatorCell = useCallback(
    (e) => <UserEmail userID={e.user} />,
    [],
  );

  const columnDefinitions = useMemo(() => {
    const baseHeaders = [
      {
        id: 'number',
        header: 'Chip Number',
        cell: (e) => linkCell(e),
      },
      {
        id: 'project',
        header: 'Project',
        cell: (e) => e.project,
      },
      {
        id: 'start',
        header: 'Start',
        cell: (e) => (e.start ? e.start.toDate().toLocaleString() : ''),
      },
      {
        id: 'user',
        header: 'Operator',
        cell: (e) => operatorCell(e),
      },
      {
        id: 'production',
        header: 'Production Run',
        cell: (e) => (e.production ? '✅' : ''),
      },
      {
        id: 'hypothesis',
        header: 'Hypothesis',
        cell: (e) => e.hypothesis,
      },
      {
        id: 'observations',
        header: 'Observations',
        cell: (e) => e.observations,
      },
    ];

    const formingLineV1Headers = [
      ...(baseHeaders.slice(0, 4)),
      {
        id: 'topHeaterSetpoint',
        header: 'Top heater setpoint',
        cell: (e) => e.plcConfig?.topHeaterSetpoint || (e.plcConfig || {})['PrgForm2.fbUpperFormingHFTS.fFrontHeaterEngagedSetpoint'],
      },
      {
        id: 'bottomHeaterSetpoint',
        header: 'Bottom heater setpoint',
        cell: (e) => e.plcConfig?.bottomHeaterSetpoint || (e.plcConfig || {})['PrgForm2.fbLowerFormingHFTS.fFrontHeaterEngagedSetpoint'],
      },
      {
        id: 'material',
        header: 'Material',
        cell: (e) => ({
          cop: 'COP',
          pc: 'PC',
          pmma: 'PMMA',
        }[e.material] || e.material),
      },
      {
        id: 'mass',
        header: 'Mass (g)',
        cell: (e) => e.mass,
      },
      ...(baseHeaders.slice(4, baseHeaders.length)),
    ];

    const bondingLineV0Headers = [
      ...(baseHeaders.slice(0, 4)),
      {
        id: 'bondingTemp',
        header: 'Bonding Temp',
        cell: (e) => e.plcConfig?.bondingTemp || (e.plcConfig || {})['BondingLine0.fBondingTemp'],
      },
      {
        id: 'coldHeaterTemp',
        header: 'Cold Heater Temp',
        cell: (e) => e.plcConfig?.coldHeaterTemp || (e.plcConfig || {})['BondingLine0.fColdHeaterTemp'],
      },
      {
        id: 'bondTime',
        header: 'Bond Time',
        cell: (e) => {
          const bondTime = e.plcConfig?.bondTime || (e.plcConfig || {})['BondingLine0.tBondingV0BondTime'];
          return bondTime ? parseTime(bondTime) : '';
        },
      },
      ...(baseHeaders.slice(4, baseHeaders.length)),
    ];

    const bondingLineV2Headers = [...baseHeaders];

    return {
      formingLineV1: formingLineV1Headers,
      formingLineV2: formingLineV1Headers,
      bondingLineV0: bondingLineV0Headers,
      bondingLineV2: bondingLineV2Headers,
    }[station];
  }, [station]);

  const [projectNumbers, setProjectNumbers] = useState([]);
  useEffect(() => {
    (async () => {
      const getPartNumbers = httpsCallable(getFunctions(), 'getPartNumbers');

      const { data } = await getPartNumbers();
      setProjectNumbers([
        ...(data.customerPartNumbers || []),
        ...(data.internalPartNumbers || []),
      ].map((partNumber) => (partNumber.value)));
    })();
  }, []);

  return (
    <DataTable
      id={station}
      header={{
        formingLineV1: 'Forming Line V1/V1.5 Data',
        formingLineV2: 'Forming Line V2 Data',
        bondingLineV2: 'Bonding Line V2 Data',
        bondingLineV0: 'Bonding Line V0 Data',
      }[station]}
      type="process_logs"
      orderField="start"
      filters={[where('station', '==', station)]}
      pageSize={pageSize}
      columnDefinitions={columnDefinitions}
      filteringOptions={projectNumbers.map((projectNumber) => ({
        propertyKey: 'project',
        value: projectNumber,
      }))}
      filteringProperties={[
        {
          key: 'project',
          operators: ['='],
          propertyLabel: 'Project',
          groupValuesLabel: 'Project IDs',
        },
      ]}
      stickyColumns={{ first: 2, last: 0 }}
      resizableColumns
    />
  );
}

SummaryTable.propTypes = {
  processType: PropTypes.oneOf(['forming', 'bonding']).isRequired,
  pageSize: PropTypes.number.isRequired,
  version: PropTypes.string.isRequired,
};

export default SummaryTable;
