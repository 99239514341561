import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFunctions, httpsCallable } from 'firebase/functions';

const initialState = {
  items: [],
  metadata: [],
  status: 'uninitialized',
  error: null,
  needsReauth: false,
};

export const fetchForgeItems = createAsyncThunk(
  'forge/fetchItems',
  async (_, { rejectWithValue }) => {
    try {
      const functions = getFunctions();
      const retrieveParts = httpsCallable(functions, 'retrieveParts');
      const { data } = await retrieveParts();
      return { items: data.data, metadata: data.included };
    } catch (err) {
      return rejectWithValue({ message: err.message, code: err.code });
    }
  },
  {
    condition: (payload, { getState }) => {
      if (payload && payload.forceReload) {
        return true;
      }
      const { forge } = getState();
      return !(forge.status === 'loading' || forge.status === 'fulfilled');
    },
  },
);

export const forgeSlice = createSlice({
  name: 'forge',
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // fetchForgeItems
      .addCase(fetchForgeItems.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.needsReauth = false;
      })
      .addCase(fetchForgeItems.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload.message;
        if (action.payload.code === 'functions/permission-denied') {
          state.needsReauth = true;
        }
      })
      .addCase(fetchForgeItems.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.error = null;
        state.items = action.payload.items;
        state.metadata = action.payload.metadata;
        state.needsReauth = false;
      });
  },
});

export const selectForgeItems = (state) => state.forge.items;
export const selectForgeMetadata = (state) => state.forge.metadata;
export const selectForgeStatus = (state) => state.forge.status;
export const selectForgeError = (state) => state.forge.error;
export const selectForgeNeedsReauth = (state) => state.forge.needsReauth;

export default forgeSlice.reducer;
