/* eslint-disable react/jsx-props-no-spreading */
import {
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from 'carbon-components-react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const formingHeaders = [
  { header: 'Chip Name', key: 'chipName' },
  { header: 'Start Time', key: 'timestamp' },
  { header: 'Target Force', key: 'targetForce' },
  { header: 'Time', key: 'time' },
  { header: 'Forming Material', key: 'formingMaterial' },
  { header: 'Tool Name', key: 'toolName' },
  { header: 'Operator', key: 'operator' },
];

const bondingHeaders = [
  { header: 'Chip Name', key: 'chipName' },
  { header: 'Start Time', key: 'timestamp' },
  { header: 'Target Force', key: 'targetForce' },
  { header: 'Time', key: 'time' },
  { header: 'Capping Material', key: 'capMaterial' },
  { header: 'Formed Chip Name', key: 'formedChipName' },
  { header: 'Operator', key: 'operator' },
];

function SummaryTable({ processType, page, pageSize }) {
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    setIsLoading(true);
    const functions = getFunctions();
    const { data } = await httpsCallable(functions, 'queryProcessSessions')({ processType, page, pageSize });
    setRowData(data.map((row) => ({ ...row, id: row.timestamp })));
    setIsLoading(false);
  }, [processType, page, pageSize]);

  const headerData = { forming: formingHeaders, bonding: bondingHeaders }[processType];

  if (isLoading) {
    return (
      <DataTableSkeleton
        showHeader
        columnCount={headerData.length}
        rowCount={pageSize}
        headers={headerData}
      />
    );
  }

  return (
    <DataTable headers={headerData} rows={rowData}>
      {({
        headers,
        rows,
        getHeaderProps,
        getRowProps,
        getTableProps,
      }) => (
        <TableContainer title={{ forming: 'Forming data', bonding: 'Bonding data' }[processType]}>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader key={header.key} {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id} {...getRowProps({ row })}>
                  {row.cells.map((cell) => {
                    switch (cell.info.header) {
                      case 'chipName':
                        return (
                          <TableCell key={cell.id}>
                            <Link to={`/process-data/v0/${processType}/${cell.value}`}>{cell.value}</Link>
                          </TableCell>
                        );
                      case 'timestamp':
                        return (
                          <TableCell key={cell.id}>
                            {new Date(cell.value).toLocaleString('en-US')}
                          </TableCell>
                        );
                      case 'time':
                      {
                        try {
                          const regex = /t#(\d+h)?(\d+m)?(\d+s)?(\d+ms)?/gmi;
                          const m = regex.exec(cell.value);
                          const seconds = (
                            (m[1] ? parseInt(m[1].slice(0, -1), 10) * 3600 : 0)
                            + (m[2] ? parseInt(m[2].slice(0, -1), 10) * 60 : 0)
                            + (m[3] ? parseInt(m[3].slice(0, -1), 10) : 0)
                          );
                          const date = new Date(0);
                          date.setSeconds(seconds);
                          const timeString = date.toISOString().substr(11, 8);
                          return (
                            <TableCell key={cell.id}>
                              {timeString}
                            </TableCell>
                          );
                        } catch (e) {
                          return (
                            <TableCell key={cell.id} />
                          );
                        }
                      }
                      default:
                        return (
                          <TableCell key={cell.id}>
                            {cell.header}
                            {cell.value}
                          </TableCell>
                        );
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  );
}

SummaryTable.propTypes = {
  processType: PropTypes.oneOf(['forming', 'bonding']).isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default SummaryTable;
