import { ProgressIndicator, ProgressStep } from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectPart } from '../../features/parts/partsSlice';
import PartLink from './partLink';

function ConversionStatus({ partID }) {
  const { cad } = useSelector(selectPart(partID));
  const [originalFileRef, status, toolFileRef] = useMemo(() => {
    if (!cad) {
      return [null, null, null];
    }
    const { original_file_ref: ofr, status: s, tool_file_ref: tfr } = cad;
    return [ofr, s, tfr];
  }, [cad]);

  let currentIndex = 1;
  if (status === 'uploaded') {
    currentIndex = 2;
  }
  if (status === 'tool_converted') {
    currentIndex = 3;
  }

  return (
    <>
      <div className="bx--row">
        <div className="bx--col">
          <ProgressIndicator currentIndex={currentIndex}>
            <ProgressStep
              label="Part Created"
            />
            <ProgressStep
              label="CAD Uploaded"
            />
            <ProgressStep
              label="Tool Created"
            />
          </ProgressIndicator>
        </div>
      </div>

      <div className="bx--row">
        <div className="bx--col">
          {originalFileRef && <PartLink uri={originalFileRef} label="Original file" />}
          {toolFileRef && <PartLink uri={toolFileRef} label="Tool file" />}
        </div>
      </div>
    </>
  );
}

ConversionStatus.propTypes = {
  partID: PropTypes.string.isRequired,
};

export default ConversionStatus;
