/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  DataTable,
  Modal,
  OverflowMenu,
  OverflowMenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarAction,
  TableToolbarContent,
  TableToolbarMenu,
  TextInput,
} from 'carbon-components-react';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import slugify from 'slugify';
import * as Yup from 'yup';

import { selectQuote } from '../../../features/parts/partsSlice';

const schema = Yup.object().shape({
  id: Yup.string().required(),
  label: Yup.string().required(),
});

function EditCustomMaterial({
  partID, customMaterial, setCustomMaterial, isOpen, close,
}) {
  const { hasPendingWrites } = useSelector(selectQuote(partID));

  return (
    <Formik
      initialValues={customMaterial}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        setCustomMaterial({
          id: slugify(values.label, { lower: true, strict: true }),
          label: values.label,
        });
        close();
        setSubmitting(false);
      }}
    >
      {({
        values, errors, handleBlur, handleChange, handleSubmit,
      }) => (
        <Modal
          size="lg"
          open={isOpen}
          onRequestClose={() => { close(); }}
          modalHeading="Edit Custom Materials"
          primaryButtonText="Update"
          secondaryButtonText="Cancel"
          onRequestSubmit={handleSubmit}
        >
          <div className="bx--row">
            <div className="bx--col">
              <TextInput
                labelText="Label"
                id="label"
                key="label"
                value={values.label}
                disabled={hasPendingWrites}
                onBlur={handleBlur}
                onChange={handleChange}
                invalidText={errors.label}
                invalid={!!errors.label}
              />
            </div>
          </div>
        </Modal>
      )}
    </Formik>
  );
}

EditCustomMaterial.propTypes = {
  partID: PropTypes.string.isRequired,
  customMaterial: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  setCustomMaterial: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

function CustomMaterials({ partID }) {
  const {
    id: quoteID,
    customMaterials: currentCustomMaterials,
    hasPendingWrites,
  } = useSelector(selectQuote(partID));

  const customMaterials = useMemo(() => currentCustomMaterials || []);

  const [focusedCustomMaterialItem, setFocusedCustomMaterialItem] = useState({
    id: '',
    label: '',
  });
  const [updateCustomMaterialFunc, setUpdateCustomMaterialFunc] = useState(() => () => {});
  const [isCustomMaterialEditorOpen, setCustomMaterialEditorOpen] = useState(false);

  return (
    <Formik
      initialValues={{ customMaterials }}
      enableReinitialize
    >
      {({
        values, setFieldValue,
      }) => {
        // check for unsaved changes
        let unsavedChanges = false;
        if (values.customMaterials.length !== customMaterials.length) {
          unsavedChanges = true;
        } else {
          values.customMaterials.forEach((item, index) => {
            if (item.id !== customMaterials[index].id
              || item.label !== customMaterials[index].label) {
              unsavedChanges = true;
            }
          });
        }

        const headerData = [
          {
            header: 'Label',
            key: 'label',
          },
        ];
        return (
          <>
            {typeof document === 'undefined'
              ? null
              : ReactDOM.createPortal(
                <EditCustomMaterial
                  partID={partID}
                  customMaterial={focusedCustomMaterialItem}
                  isOpen={isCustomMaterialEditorOpen}
                  close={() => {
                    setCustomMaterialEditorOpen(false);
                    setFocusedCustomMaterialItem({ id: '', label: '' });
                  }}
                  setCustomMaterial={updateCustomMaterialFunc}
                />,
                document.body,
              )}
            <h4 style={{ marginBottom: '1rem' }}>Custom Materials</h4>
            <DataTable
              rows={values.customMaterials.map((row) => ({
                id: row.id,
                label: row.label,
              }))}
              headers={headerData}
            >
              {({
                rows, headers, getToolbarProps, getHeaderProps, getTableProps,
              }) => {
                // edit a customMaterial
                const handleEditCustomMaterial = (index) => {
                  setFocusedCustomMaterialItem({
                    id: values.customMaterials[index].id,
                    label: values.customMaterials[index].label,
                  });
                  setUpdateCustomMaterialFunc(() => (newCustomMaterial) => {
                    setFieldValue(`customMaterials[${index}].id`, newCustomMaterial.id);
                    setFieldValue(`customMaterials[${index}].label`, newCustomMaterial.label);
                  });
                  setCustomMaterialEditorOpen(true);
                };

                // save changes
                const handleSave = () => {
                  updateDoc(doc(getFirestore(), 'quotes', quoteID), {
                    customMaterials: values.customMaterials,
                  });
                };

                // add a new customMaterial
                const handleAddCustomMaterial = () => {
                  const newCustomMaterial = {
                    id: `custom-material-${Date.now()}`,
                    label: 'Custom Material',
                  };
                  setFieldValue('customMaterials', [...values.customMaterials, newCustomMaterial]);
                };

                // delete a customMaterial
                const handleDeleteCustomMaterial = (index) => {
                  setFieldValue('customMaterials', values.customMaterials.filter((item, i) => i !== index));
                };

                return (
                  <TableContainer>
                    <TableToolbar {...getToolbarProps()}>
                      <TableToolbarContent>
                        <TableToolbarMenu light>
                          <TableToolbarAction
                            onClick={handleAddCustomMaterial}
                            disabled={hasPendingWrites}
                          >
                            Add new customMaterial
                          </TableToolbarAction>
                        </TableToolbarMenu>
                        <Button
                          onClick={handleSave}
                          disabled={!unsavedChanges}
                        >
                          {hasPendingWrites ? 'Upadating...' : 'Save'}
                        </Button>
                      </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          {headers.map((header) => (
                            <TableHeader {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                          <TableHeader key="overflow-menu" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, rowIndex) => (
                          <TableRow key={row.id}>
                            {row.cells.map((cell) => (
                              <TableCell key={cell.id}>{cell.value}</TableCell>)) }
                            <TableCell key={`${row.id}:overflow`} width={32} className="bx--table-column-menu">
                              <OverflowMenu size="sm" light flipped>
                                <OverflowMenuItem
                                  key={`${row.id}:overflow:edit`}
                                  itemText="Edit"
                                  onClick={() => { handleEditCustomMaterial(rowIndex); }}
                                />
                                <OverflowMenuItem
                                  key={`${row.id}:overflow:delete`}
                                  isDelete
                                  itemText="Delete"
                                  onClick={() => { handleDeleteCustomMaterial(rowIndex); }}
                                />
                              </OverflowMenu>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              }}
            </DataTable>
          </>
        );
      }}
    </Formik>
  );
}

CustomMaterials.propTypes = {
  partID: PropTypes.string.isRequired,
};

export default CustomMaterials;
