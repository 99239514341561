import {
  InlineNotification,
  Loading,
} from 'carbon-components-react';
import React, {
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';

import PLCButton from '../../components/HMI/components/plcButton';
import HMIContextProvider, { handlify, usePLCAddress } from '../../components/HMI/hooks/context';
import PLCContextProvider, {
  usePLCError,
  usePLCReady,
  usePLCValue,
} from '../../features/plc/context';
import BondingHMI from './bonding';
import FormingHMI from './forming';

const PressMode = Object.freeze({
  None: 0,
  Forming: 1,
  Bonding: 2,
});

function HMIRouter() {
  const { pressName } = useParams();
  const pressTitle = useMemo(() => ({
    press1: 'Press 1',
    press2: 'Press 2',
    press3: 'Press 3',
    press4: 'Press 4',
  }[pressName]), [pressName]);

  const pressHandle = useMemo(() => handlify(pressName), [pressName]);

  const pressModeHandle = useMemo(() => `MAIN.${pressHandle}_Mode`, [pressHandle]);
  const mode = usePLCValue(pressModeHandle);
  const error = usePLCError();
  const ready = usePLCReady();

  if (!ready) {
    return <Loading />;
  }
  if (error) {
    return (
      <InlineNotification
        kind="error"
        lowContrast
        title="Error connecting to PLC"
        subtitle={error}
        style={{ minWidth: '100%', width: '100%' }}
      />
    );
  }
  switch (mode) {
    case undefined: {
      // Sometimes, `ready` is available before other PLC values
      // I think this is because of the way useSyncExternalStore updates values
      return <Loading />;
    }
    case PressMode.Forming: {
      return (
        <HMIContextProvider press={pressName} process="forming">
          <FormingHMI />
        </HMIContextProvider>
      );
    }
    case PressMode.Bonding: {
      return (
        <HMIContextProvider press={pressName} process="bonding">
          <BondingHMI />
        </HMIContextProvider>
      );
    }
    default: {
      return (
        <>
          <div className="bx--row">
            <div className="bx--col">
              <h3>{pressTitle}</h3>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <h4>Select the mode of operation for this press</h4>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <PLCButton
                onClick={(write) => write({ handle: pressModeHandle, value: 1 })}
                style={{ marginRight: '1rem' }}
              >
                Forming Mode
              </PLCButton>
              <PLCButton
                onClick={(write) => write({ handle: pressModeHandle, value: 2 })}
              >
                Bonding Mode
              </PLCButton>
            </div>
          </div>
        </>
      );
    }
  }
}

function HMI() {
  const { pressName } = useParams();
  const plcAddress = usePLCAddress(pressName);

  const handles = useMemo(() => {
    const pressHandle = handlify(pressName);
    return [
      { name: `MAIN.${pressHandle}_Mode`, type: 'INT' },
      { name: `MAIN.${pressHandle}_Bonding_Orientation`, type: 'INT' },
    ];
  }, [pressName]);

  return (
    <PLCContextProvider url={plcAddress} handles={handles}>
      <HMIRouter />
    </PLCContextProvider>
  );
}

export default HMI;
