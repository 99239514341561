import {
  InlineNotification,
  NotificationActionButton,
} from 'carbon-components-react';
import React from 'react';

import {
  usePLCValue,
  usePLCWrite,
} from '../../../features/plc/context';
import { useHFTSHandle, useProgramHandle } from '../hooks/context';
import parseTime from './parseTime';

function ShutdownWarning() {
  const programHandle = useProgramHandle();
  const shutdownTimerElapsed = usePLCValue(`${programHandle}.tShutdownTimer.ET`);
  const shutdownTimerSetpoint = usePLCValue(`${programHandle}.tShutdownTimer.PT`);

  if (shutdownTimerElapsed > shutdownTimerSetpoint - 5 * 60 * 1000) {
    return (
      <InlineNotification
        title="Heaters shutting off soon"
        subtitle={`No automation cycles have been run for ${parseTime(shutdownTimerElapsed)}. Please run a cycle or the heaters will shut off in ${parseTime(shutdownTimerSetpoint - shutdownTimerElapsed)}.`}
        kind="error"
        hideCloseButton
        lowContrast
        style={{ minWidth: '100%', width: '100%' }}
      />
    );
  }
  return null;
}

function ThermocoupleWarning() {
  const programHandle = useProgramHandle();
  const checkThermocouple = usePLCValue(`${programHandle}.bCheckThermocouple`);
  const write = usePLCWrite();
  if (checkThermocouple) {
    return (
      <InlineNotification
        title="Check thermocouple"
        subtitle="The tool should be heating, but is still reading below 40°. Check that the thermocouple is installed. The heaters have been shut off."
        kind="error"
        hideCloseButton
        lowContrast
        style={{ minWidth: '100%', width: '100%' }}
        actions={(
          <NotificationActionButton
            onClick={() => { write({ handle: `${programHandle}.bCheckThermocouple`, value: false }); }}
          >
            Reset
          </NotificationActionButton>
          )}
      />
    );
  }
  return null;
}

function TooHotWarning() {
  const upperHFTSHandle = useHFTSHandle('Upper');
  const lowerHFTSHandle = useHFTSHandle('Lower');

  const lowerTooHot = usePLCValue(`${lowerHFTSHandle}.bTooHot`);
  const upperTooHot = usePLCValue(`${upperHFTSHandle}.bTooHot`);

  const lowerHP1TooHot = usePLCValue(`${lowerHFTSHandle}.bHP1TooHot`);
  const lowerHP2TooHot = usePLCValue(`${lowerHFTSHandle}.bHP2TooHot`);
  const lowerHP3TooHot = usePLCValue(`${lowerHFTSHandle}.bHP3TooHot`);
  const lowerHP4TooHot = usePLCValue(`${lowerHFTSHandle}.bHP4TooHot`);
  const lowerHP5TooHot = usePLCValue(`${lowerHFTSHandle}.bHP5TooHot`);
  const lowerHP6TooHot = usePLCValue(`${lowerHFTSHandle}.bHP6TooHot`);
  const lowerToolTooHot = usePLCValue(`${lowerHFTSHandle}.bToolTooHot`);
  const lowerHotBlockTooHot = usePLCValue(`${lowerHFTSHandle}.bHotBlockTooHot`);
  const lowerFrontBlockTooHot = usePLCValue(`${lowerHFTSHandle}.bFrontBlockTooHot`);
  const lowerRearBlockTooHot = usePLCValue(`${lowerHFTSHandle}.bRearBlockTooHot`);
  const upperHP1TooHot = usePLCValue(`${upperHFTSHandle}.bHP1TooHot`);
  const upperHP2TooHot = usePLCValue(`${upperHFTSHandle}.bHP2TooHot`);
  const upperHP3TooHot = usePLCValue(`${upperHFTSHandle}.bHP3TooHot`);
  const upperHP4TooHot = usePLCValue(`${upperHFTSHandle}.bHP4TooHot`);
  const upperHP5TooHot = usePLCValue(`${upperHFTSHandle}.bHP5TooHot`);
  const upperHP6TooHot = usePLCValue(`${upperHFTSHandle}.bHP6TooHot`);
  const upperToolTooHot = usePLCValue(`${upperHFTSHandle}.bToolTooHot`);
  const upperHotBlockTooHot = usePLCValue(`${upperHFTSHandle}.bHotBlockTooHot`);
  const upperFrontBlockTooHot = usePLCValue(`${upperHFTSHandle}.bFrontBlockTooHot`);
  const upperRearBlockTooHot = usePLCValue(`${upperHFTSHandle}.bRearBlockTooHot`);

  if (lowerTooHot || upperTooHot) {
    return (
      <InlineNotification
        title="The following got too hot! The system shut itself down to prevent damage."
        subtitle={[
          { name: 'Lower Heatpipe 1', value: lowerHP1TooHot },
          { name: 'Lower Heatpipe 2', value: lowerHP2TooHot },
          { name: 'Lower Heatpipe 3', value: lowerHP3TooHot },
          { name: 'Lower Heatpipe 4', value: lowerHP4TooHot },
          { name: 'Lower Heatpipe 5', value: lowerHP5TooHot },
          { name: 'Lower Heatpipe 6', value: lowerHP6TooHot },
          { name: 'Lower Tool', value: lowerToolTooHot },
          { name: 'Lower Hot Block', value: lowerHotBlockTooHot },
          { name: 'Lower Front Block', value: lowerFrontBlockTooHot },
          { name: 'Lower Rear Block', value: lowerRearBlockTooHot },

          { name: 'Upper Heatpipe 1', value: upperHP1TooHot },
          { name: 'Upper Heatpipe 2', value: upperHP2TooHot },
          { name: 'Upper Heatpipe 3', value: upperHP3TooHot },
          { name: 'Upper Heatpipe 4', value: upperHP4TooHot },
          { name: 'Upper Heatpipe 5', value: upperHP5TooHot },
          { name: 'Upper Heatpipe 6', value: upperHP6TooHot },
          { name: 'Upper Tool', value: upperToolTooHot },
          { name: 'Upper Hot Block', value: upperHotBlockTooHot },
          { name: 'Upper Front Block', value: upperFrontBlockTooHot },
          { name: 'Upper Rear Block', value: upperRearBlockTooHot },
        ].filter((handle) => handle.value).map((handle) => handle.name).join(', ')}
        kind="error"
        hideCloseButton
        lowContrast
        style={{ minWidth: '100%', width: '100%' }}
      />
    );
  }
  return null;
}

export { ShutdownWarning, ThermocoupleWarning, TooHotWarning };
