/* eslint-disable react/jsx-props-no-spreading */
import { Launch16 } from '@carbon/icons-react';
import {
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Tag,
} from 'carbon-components-react';
import {
  collection,
  getFirestore,
  limit,
  orderBy,
  query,
} from 'firebase/firestore';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useFirestoreCollectionData } from 'reactfire';

import OrderDetail from './detail';

function OrderList() {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const ordersQuery = query(
    collection(getFirestore(), 'orders'),
    orderBy('orderDate', 'desc'),
    limit(20),
  );
  const { status: orderQueryStatus, data: orders } = useFirestoreCollectionData(ordersQuery, { idField: 'id' });
  const shipmentEvents = useMemo(() => {
    if (orderQueryStatus !== 'success' || !orders) {
      return [];
    }

    const events = [];
    for (let i = 0; i < orders.length; i += 1) {
      const order = orders[i];
      for (let j = 0; j < (order.shipments || []).length; j += 1) {
        const shipment = order.shipments[j];
        events.push({
          id: `${order.id}-${j}`,
          orderId: order.id,
          start: new Date(`${shipment.targetShipDate} 00:00:00`),
          end: new Date(`${shipment.targetShipDate} 00:00:00`),
          shipmentId: shipment.name,
          customer: order.customer,
          order: order.name,
          title: `${order.customer} - ${shipment.name}`,
          allDay: true,
        });
      }
    }
    return events;
  }, [orderQueryStatus, orders]);

  const headerData = [
    {
      header: 'Order ID',
      key: 'name',
      isSortable: false,
    },
    {
      header: 'Order Date',
      key: 'orderDate',
      isSortable: false,
    },
    {
      header: 'Customer',
      key: 'customer',
      isSortable: false,
    },
    {
      header: 'Status',
      key: 'status',
      isSortable: false,
    },
  ];

  const localizer = momentLocalizer(moment);

  if (orderQueryStatus === 'loading') {
    return <div>Loading...</div>;
  }

  return (
    <>
      {selectedOrder ? (
        <OrderDetail
          id={selectedOrder}
          isOpen={!!selectedOrder}
          close={() => setSelectedOrder(null)}
        />
      ) : null}
      <div className="bx--row">
        <div className="bx--col">
          <h4>Target ship dates</h4>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Calendar
            events={shipmentEvents}
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            views={{ month: true }}
            onSelectEvent={(event) => { setSelectedOrder(event.orderId); }}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <h4>Recent orders</h4>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <DataTable rows={orders} headers={headerData} isSortable>
            {({
              rows, headers, getHeaderProps, getTableProps,
            }) => (
              <TableContainer>
                <Table {...getTableProps()}>
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <TableHeader {...getHeaderProps({ header })} isSortable={header.isSortable}>
                          {header.header}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.id}>
                        {row.cells.map((cell) => {
                          switch (cell.info.header) {
                            case 'name': {
                              return (
                                <TableCell key={cell.id}>
                                  <Button
                                    renderIcon={Launch16}
                                    align=""
                                    onClick={() => setSelectedOrder(row.id)}
                                    iconDescription="Launch"
                                    kind="ghost"
                                    size="small"
                                  >
                                    {cell.value}
                                  </Button>
                                </TableCell>
                              );
                            }
                            case 'status': {
                              return (
                                <TableCell key={cell.id}>
                                  <Tag type={
                                  {
                                    'Not started': 'purple',
                                    'In progress': 'teal',
                                    Shipped: 'green',
                                    Archived: 'gray',
                                  }[cell.value] || 'cool gray'
                                }
                                  >
                                    {cell.value}
                                  </Tag>
                                </TableCell>
                              );
                            }
                            default:
                              return <TableCell key={cell.id}>{cell.value}</TableCell>;
                          }
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </DataTable>
        </div>
      </div>
    </>
  );
}

export default OrderList;
