import { CloudDownload32 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import {
  getDownloadURL, getStorage, listAll, ref,
} from 'firebase/storage';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectPart } from '../../features/parts/partsSlice';

function OriginalFile({ partID }) {
  const part = useSelector(selectPart(partID));
  const [error, setError] = useState(null);
  const [downloadURL, setDownloadURL] = useState(null);

  useEffect(() => {
    if (!part || !part.uid || !part.fileID) {
      return;
    }
    const storage = getStorage();
    const uploadsRef = ref(storage, `/user-uploads/cad/${part.uid}`);

    // search through all file uploads for a match
    listAll(uploadsRef)
      .then((res) => {
        const fileMatches = [];
        res.items.forEach((itemRef) => {
          if (itemRef.fullPath.includes(part.fileID)) {
            fileMatches.push({ path: itemRef.fullPath });
          }
        });

        // no matches
        if (fileMatches.length === 0) {
          setError('Could not find file');
          return;
        // too many matches
        } if (fileMatches.length > 1) {
          setError('Could not find the correct file');
          return;
        }

        // found one match! get the download link
        getDownloadURL(ref(storage, fileMatches[0].path))
          .then((url) => {
            setDownloadURL(url);
          }).catch((err) => setError(err.message));
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [partID, part]);

  if (!part || !part.filename) {
    return <Button disabled>Loading original file...</Button>;
  }

  if (!part.uid || !part.fileID) {
    return <Button disabled>{`Download ${part.filename} Error (Unable to search for original file)`}</Button>;
  }

  if (downloadURL) {
    return <Button kind="secondary" onClick={() => { window.open(downloadURL, '_blank'); }} renderIcon={CloudDownload32}>{`Download ${part.filename}`}</Button>;
  }
  return <Button disabled>{`Download ${part.filename} ${error ? `Error (${error})` : '(Loading link...)'}`}</Button>;
}

OriginalFile.propTypes = {
  partID: PropTypes.string.isRequired,
};

export default OriginalFile;
