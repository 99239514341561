import {
  Button,
  ClickableTile,
  FileUploaderDropContainer,
  InlineNotification,
  Modal,
  TextInput,
} from 'carbon-components-react';
import {
  getStorage, listAll, ref, uploadBytes,
} from 'firebase/storage';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectQuote } from '../../features/parts/partsSlice';
import SecureImage from '../SecureImage';

function EditFeedback({
  userID, partID, feedback, setFeedback, isOpen, close,
}) {
  const { hasPendingWrites } = useSelector(selectQuote(partID));
  const [imagePaths, setImagePaths] = useState([]);
  const [imageUploadError, setImageUploadError] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setImageUploadError(null);
      setImagePaths([]);
      return;
    }
    const storage = getStorage();
    const uploadsRef = ref(storage, `${userID}/feedback-images/${partID}`);

    listAll(uploadsRef)
      .then((res) => {
        setImagePaths(res.items.map((itemRef) => itemRef.fullPath));
      });
  }, [userID, isOpen, setImagePaths]);

  const getFilename = (path) => {
    if (path) {
      const parts = path.split('/');
      return parts[parts.length - 1];
    }
    return null;
  };

  return (
    <Formik
      initialValues={feedback}
      enableReinitialize
    >
      {({
        values, handleBlur, handleChange, setFieldValue,
      }) => (
        <Modal
          size="lg"
          open={isOpen}
          onRequestClose={() => { close(); }}
          modalHeading="Edit feedback"
          primaryButtonText="Update"
          secondaryButtonText="Cancel"
          onRequestSubmit={() => { setFeedback(values); close(); }}
        >
          <TextInput
            labelText="Title"
            id="title"
            key="title"
            value={values.title}
            disabled={hasPendingWrites || (values.category && values.category !== 'Custom')}
            onBlur={handleBlur}
            onChange={handleChange}
            style={{ marginBottom: '1rem' }}
          />
          <TextInput
            labelText="Text"
            id="text"
            key="text"
            value={values.text}
            disabled={hasPendingWrites}
            onBlur={handleBlur}
            onChange={handleChange}
            style={{ marginBottom: '1rem' }}
          />
          <TextInput
            labelText="Checkbox text"
            placeholder="Accept changes"
            id="checkboxText"
            key="checkboxText"
            value={values.checkboxText}
            disabled={hasPendingWrites}
            onBlur={handleBlur}
            onChange={handleChange}
            style={{ marginBottom: '1rem' }}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="bx--label">Image</label>
          {imageUploadError && (
          <InlineNotification
            hideCloseButton
            kind="error"
            title={imageUploadError}
          />
          )}
          <div className="bx--row" style={{ marginBottom: '1rem' }}>
            <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-4">
              <div className="bx--aspect-ratio bx--aspect-ratio--16x9">
                <div className="bx--aspect-ratio--object">
                  <div className="bx--file__container " style={{ padding: '1rem' }}>
                    <FileUploaderDropContainer
                      labelText="Drag and drop a new image here or click to upload"
                      multiple={false}
                      accept={['image/jpeg', 'image/png']}
                      onAddFiles={(_, { addedFiles }) => {
                        setImageUploadError(null);
                        const file = addedFiles[0];
                        const fullPath = `${userID}/feedback-images/${partID}/${file.name}`;
                        if (imagePaths.includes(fullPath)) {
                          setImageUploadError(`Image with filename ${file.name.trim()} already exists`);
                          return;
                        }
                        const storage = getStorage();
                        const uploadsRef = ref(storage, fullPath);
                        uploadBytes(uploadsRef, file).then(() => {
                          setImagePaths([...imagePaths, fullPath]);
                        }).catch((err) => { setImageUploadError(err.message); });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {imagePaths.map((imagePath) => (
              <div key={imagePath} className="bx--col-lg-4 bx--col-md-4 bx--col-sm-4">
                <ClickableTile
                  style={{
                    border: getFilename(imagePath) === getFilename(values.image) ? '1px solid #161616' : '1px solid transparent',
                  }}
                  onClick={() => {
                    if (getFilename(imagePath) === getFilename(values.image)) {
                      setFieldValue('image', null);
                    } else {
                      setFieldValue('image', imagePath);
                    }
                  }}
                >
                  <SecureImage path={imagePath} />
                </ClickableTile>
              </div>
            ))}
          </div>
          <Button
            kind="danger"
            disabled={!values.image}
            onClick={() => {
              setFieldValue('image', null);
            }}
          >
            Clear image
          </Button>
        </Modal>
      )}
    </Formik>
  );
}

EditFeedback.propTypes = {
  userID: PropTypes.string.isRequired,
  partID: PropTypes.string.isRequired,
  feedback: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  setFeedback: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default EditFeedback;
