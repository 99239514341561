import {
  Accordion,
  AccordionItem,
  InlineNotification,
  TextInput,
} from 'carbon-components-react';
import {
  doc,
  getDoc,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { selectPart, selectQuote } from '../../../features/parts/partsSlice';
import Attribute, { isCustom } from './attribute';
import Breakpoints from './breakpoints';
import Leadtimes, { BASE_LEADTIMES } from './leadtimes';
import Materials from './materials';

function Pricing({ partID, disabled }) {
  const { id: quoteID, pricingV3 } = useSelector(selectQuote(partID));
  const {
    material: originalMaterial,
    cappingOptions: originalCappingOption,
  } = useSelector(selectPart(partID));
  const [error, setError] = useState(null);

  const [attributes, setAttributes] = useState([]);
  useEffect(() => {
    if (!pricingV3) {
      return;
    }
    (async () => {
      const { attributesVersion } = pricingV3;
      const docRef = doc(getFirestore(), 'quoteAttributes', attributesVersion);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setAttributes(docSnap.data().attributes);
      }
    })();
  }, [pricingV3]);

  const initialValues = useMemo(() => {
    if (!pricingV3) {
      return {};
    }
    const values = pricingV3.values ? JSON.parse(JSON.stringify(pricingV3.values)) : {};
    if (!values.attributes) {
      values.attributes = {};
    }
    if (!values.breakpoints) {
      values.breakpoints = [
        {
          quantity: 5,
          multiplier: 2.0,
        },
        {
          quantity: 20,
          multiplier: 1,
        },
        {
          quantity: 100,
          multiplier: 0.5,
        },
      ];
    }
    values.calculatedValues = {
      ...(values.calculatedValues || {}),
      ...{
        uncappedSetupPrice: 0.0,
        uncappedUnitPrice: 0.0,
        uncappedUnscaledUnitPrice: 0.0,
        cappedSetupPrice: 0.0,
        cappedUnitPrice: 0.0,
        cappedUnscaledUnitPrice: 0.0,
      },
    };
    if (!values.materials) {
      values.materials = [
        {
          id: 'pmma',
          display: 'PMMA',
          isDefault: originalMaterial === 'pmma',
          cappingStyle: [
            {
              id: '250um-pmma',
              display: '250um PMMA cap (thermally bonded)',
              isDefault: true,
            },
          ],
        },
        {
          id: 'pc',
          display: 'PC',
          isDefault: originalMaterial === 'pc',
          cappingStyle: [
            {
              id: '175um-pc',
              display: '175um PC cap (thermally bonded)',
              isDefault: true,
            },
          ],
        },
        {
          id: 'cop',
          display: 'COP',
          isDefault: originalMaterial === 'cop',
          cappingStyle: [
            {
              id: '175um-coc-x1',
              display: '175um COC-X1 cap (recommended for most applications)',
              isDefault: originalMaterial === 'cop' ? originalCappingOption === '175um-coc-x1' : true,
            },
            {
              id: '175um-coc',
              display: '175um COC cap (recommended for high-temp applications)',
              isDefault: originalMaterial === 'cop' ? originalCappingOption === '175um-coc' : false,
            },
          ],
        },
      ];
    }

    if (!values.leadtimes) {
      values.leadtimes = BASE_LEADTIMES.map((leadtime) => ({
        ...leadtime,
        leadtimeText: null,
      }));
    }

    // prepopulate attributes for any values that aren't set yet
    attributes.forEach((attribute) => {
      if (values?.attributes[attribute.title]) {
        return;
      }
      switch (attribute.type) {
        case 'select':
          values.attributes[attribute.title] = {
            value: 'PLACEHOLDER-ITEM',
            setup: 0,
            unit: 0,
          };
          break;
        case 'number':
          values.attributes[attribute.title] = {
            value: { pricingType: 'default', quantity: 0 },
            setup: 0,
            unit: 0,
          };
          break;
        default:
          break;
      }
    });
    return values;
  }, [attributes, pricingV3]);

  const attributeValidation = useMemo(() => {
    const validation = {};
    attributes.forEach((attribute) => {
      switch (attribute.type) {
        case 'select':
          validation[attribute.title] = Yup.object().shape({
            value: Yup.string().required('Please select a value'),
            setup: Yup.number().required('Please enter a value'),
            unit: Yup.number().required('Please enter a value'),
          });
          break;
        case 'number':
          validation[attribute.title] = Yup.object().shape({
            value: Yup.object().shape({
              pricingType: Yup.string().oneOf(['default', 'custom']),
              value: Yup.number(),
            }),
            setup: Yup.number().required('Please enter a value'),
            unit: Yup.number().required('Please enter a value'),
          });
          break;
        default:
          break;
      }
    });
    return validation;
  }, [attributes]);

  const validation = Yup.object().shape({
    attributes: Yup.object().shape(attributeValidation),
    breakpoints: Yup.array().of(
      Yup.object().shape({
        quantity: Yup.number().required('Please enter a value'),
        multiplier: Yup.number().required('Please enter a value'),
      }),
    ),
  });

  if (!attributes.length || !initialValues) {
    return 'Loading...';
  }

  return (
    <>
      {error ? (
        <InlineNotification
          title="Error"
          subtitle={error}
          kind="error"
          hideCloseButton
          lowContrast
          style={{ minWidth: '100%', width: '100%' }}
        />
      ) : null}
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          setError(null);
          if (attributes.some((attribute) => (
            !values.attributes[attribute.title]?.value
          || values.attributes[attribute.title].value === 'PLACEHOLDER-ITEM'
          ))) {
            setError('Please select a value for all attributes.');
            setSubmitting(false);
            return;
          }
          const quoteRef = doc(getFirestore(), 'quotes', quoteID);
          try {
            await updateDoc(quoteRef, { 'pricingV3.values': values });
          } catch (e) {
            setError(e.message);
          }
          setSubmitting(false);
        }}
      >
        {({
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldTouched,
          setFieldValue,
          touched,
          values,
        }) => {
          // memoize errors
          const attributeErrors = errors.attributes || {};
          const [setupPriceErrors, unitPriceErrors] = useMemo(
            () => [
              attributes.some((category) => !!attributeErrors[category.title]?.setup),
              attributes.some((category) => !!attributeErrors[category.title]?.unit),
            ],
            [attributes, errors],
          );

          // keep track of running totals
          useEffect(
            () => {
              setFieldValue(
                'calculatedValues.uncappedSetupPrice',
                attributes.reduce((acc, category) => (acc
                  + (!category.cappingOnly
                    ? parseFloat(values.attributes[category.title].setup)
                    : 0)
                ), 0),
              );
              setFieldValue(
                'calculatedValues.uncappedUnitPrice',
                attributes.reduce((acc, category) => (acc
                  + (!category.cappingOnly && !category.unscaled
                    ? parseFloat(values.attributes[category.title].unit)
                    : 0)
                ), 0),
              );
              setFieldValue(
                'calculatedValues.uncappedUnscaledUnitPrice',
                attributes.reduce((acc, category) => (acc
                  + (!category.cappingOnly && category.unscaled
                    ? parseFloat(values.attributes[category.title].unit)
                    : 0)
                ), 0),
              );

              setFieldValue(
                'calculatedValues.cappedSetupPrice',
                attributes.reduce((acc, category) => (
                  acc + parseFloat(values.attributes[category.title].setup)
                ), 0),
              );
              setFieldValue(
                'calculatedValues.cappedUnitPrice',
                attributes.reduce((acc, category) => (acc
                  + (!category.unscaled
                    ? parseFloat(values.attributes[category.title].unit)
                    : 0)
                ), 0),
              );
              setFieldValue(
                'calculatedValues.cappedUnscaledUnitPrice',
                attributes.reduce((acc, category) => (acc
                  + (category.unscaled
                    ? parseFloat(values.attributes[category.title].unit)
                    : 0)
                ), 0),
              );
            },
            [attributes, values],
          );

          return (
            <>
              <div className="bx--row">
                <div className="bx--col-lg-10">
                  {attributes.map((attribute) => {
                    const allowCustomValue = isCustom(
                      attribute,
                      values.attributes[attribute.title].value,
                    );
                    return (
                      <div key={attribute.title} className="bx--row">
                        <div className="bx--col">
                          <Attribute
                            attribute={attribute}
                            value={values.attributes[attribute.title]?.value}
                            error={attributeErrors[attribute.title]?.value}
                            disabled={isSubmitting || disabled}
                            setFieldValue={setFieldValue}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="bx--col">
                          <TextInput
                            id={`attributes.${attribute.title}.setup`}
                            labelText="Setup cost"
                            value={values.attributes[attribute.title].value === 'PLACEHOLDER-ITEM' ? '' : values.attributes[attribute.title].setup}
                            onChange={allowCustomValue ? (e) => {
                              let newValue = parseFloat(e.target.value);
                              if (Number.isNaN(newValue)) {
                                newValue = 0;
                              }
                              setFieldValue(`attributes.${attribute.title}.setup`, newValue);
                              setFieldTouched(`attributes.${attribute.title}.setup`, true);
                            } : undefined}
                            onBlur={allowCustomValue ? handleBlur : undefined}
                            invalid={!!attributeErrors[attribute.title]?.setup}
                            invalidText={attributeErrors[attribute.title]?.setup}
                            style={allowCustomValue ? { fontWeight: 800 } : {}}
                            disabled={disabled}
                          />
                        </div>
                        <div className="bx--col">
                          <TextInput
                            id={`attributes.${attribute.title}.unit`}
                            labelText="Unit cost (at quantity 20)"
                            value={values.attributes[attribute.title].value === 'PLACEHOLDER-ITEM' ? '' : values.attributes[attribute.title].unit}
                            onChange={allowCustomValue ? (e) => {
                              let newValue = parseFloat(e.target.value);
                              if (Number.isNaN(newValue)) {
                                newValue = 0;
                              }
                              if (e.target.value && e.target.value[e.target.value.length - 1] === '.') {
                                newValue = `${newValue}.`;
                              } else {
                                newValue = `${newValue}`;
                              }
                              setFieldValue(`attributes.${attribute.title}.unit`, newValue);
                              setFieldTouched(`attributes.${attribute.title}.unit`, true);
                            } : undefined}
                            onBlur={allowCustomValue ? handleBlur : undefined}
                            invalid={!!attributeErrors[attribute.title]?.unit}
                            invalidText={attributeErrors[attribute.title]?.unit}
                            style={allowCustomValue ? { fontWeight: 800 } : {}}
                            disabled={disabled}
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div className="bx--row">
                    <div className="bx--col">
                      <p style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        fontWeight: 'bold',
                        justifyContent: 'flex-end',
                        marginTop: '0.75rem',
                      }}
                      >
                        Uncapped price
                      </p>
                    </div>
                    <div className="bx--col">
                      <TextInput
                        id="total.uncapped.setup"
                        labelText="Total setup cost"
                        value={setupPriceErrors ? 'Please fix errors above' : values.calculatedValues.uncappedSetupPrice}
                        invalid={setupPriceErrors}
                        disabled={isSubmitting || disabled}
                      />
                    </div>
                    <div className="bx--col">
                      <TextInput
                        id="total.uncapped.unit"
                        labelText="Total unit cost (at quantity 20)"
                        value={unitPriceErrors ? 'Please fix errors above' : values.calculatedValues.uncappedUnitPrice}
                        invalid={unitPriceErrors}
                        disabled={isSubmitting || disabled}
                      />
                    </div>
                  </div>
                  <div className="bx--row">
                    <div className="bx--col">
                      <p style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        fontWeight: 'bold',
                        justifyContent: 'flex-end',
                        marginTop: '0.75rem',
                      }}
                      >
                        Capped price
                      </p>
                    </div>
                    <div className="bx--col">
                      <TextInput
                        id="total.capped.setup"
                        labelText="Total setup cost"
                        value={setupPriceErrors ? 'Please fix errors above' : values.calculatedValues.cappedSetupPrice}
                        invalid={setupPriceErrors}
                        disabled={isSubmitting || disabled}
                      />
                    </div>
                    <div className="bx--col">
                      <TextInput
                        id="total.capped.unit"
                        labelText="Total unit cost (at quantity 20)"
                        value={unitPriceErrors ? 'Please fix errors above' : values.calculatedValues.cappedUnitPrice}
                        invalid={unitPriceErrors}
                        disabled={isSubmitting || disabled}
                      />
                    </div>
                  </div>
                </div>
                <div className="bx--col-lg-6">
                  <div style={{ position: 'sticky', top: '5rem' }}>
                    <Breakpoints
                      breakpoints={values.breakpoints}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      uncappedBasePrice={values.calculatedValues.uncappedUnitPrice}
                      uncappedUnscaledPrice={values.calculatedValues.uncappedUnscaledUnitPrice}
                      cappedBasePrice={values.calculatedValues.cappedUnitPrice}
                      cappedUnscaledPrice={values.calculatedValues.cappedUnscaledUnitPrice}
                      touched={touched.attributes
                        || touched.breakpoints
                        || touched.materials
                        || touched.leadtimes}
                      disabled={disabled}
                      isSubmitting={isSubmitting}
                      onSubmit={handleSubmit}
                    />
                  </div>
                </div>
              </div>
              <Accordion>
                <AccordionItem title="Advanced Options">
                  <div className="bx--row">
                    <div className="bx--col">
                      <Materials
                        materials={values.materials}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        touched={touched.attributes
                          || touched.breakpoints
                          || touched.materials
                          || touched.leadtimes}
                        disabled={disabled}
                        isSubmitting={isSubmitting}
                        onSubmit={handleSubmit}
                      />
                    </div>
                    <div className="bx--col">
                      <Leadtimes
                        leadtimes={values.leadtimes}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        touched={touched.attributes
                          || touched.breakpoints
                          || touched.materials
                          || touched.leadtimes}
                        disabled={disabled}
                        isSubmitting={isSubmitting}
                        onSubmit={handleSubmit}
                      />
                    </div>
                  </div>
                </AccordionItem>
              </Accordion>
            </>
          );
        }}
      </Formik>
    </>
  );
}

Pricing.propTypes = {
  partID: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

Pricing.defaultProps = {
  disabled: false,
};

export default Pricing;
