import {
  Button,
  ButtonSkeleton,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import {
  usePLCReady,
  usePLCWrite,
} from '../../../features/plc/context';

function PLCButton({
  kind,
  size,
  disabled,
  style,
  onClick,
  children,
}) {
  const ready = usePLCReady();
  const write = usePLCWrite();
  const handleClick = useCallback(() => { onClick(write); });

  if (!ready) {
    return (
      <ButtonSkeleton
        disabled={disabled}
        kind={kind}
        size={size}
        style={style}
      />
    );
  }

  return (
    <Button
      disabled={disabled}
      onClick={handleClick}
      kind={kind}
      size={size}
      style={style}
    >
      {children}
    </Button>
  );
}

PLCButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  kind: PropTypes.oneOf(['danger', 'danger--ghost', 'danger--primary', 'danger--tertiary', 'ghost', 'primary', 'secondary', 'tertiary']),
  size: PropTypes.oneOf(['default', 'field', 'lg', 'md', 'sm', 'small', 'xl', '2xl']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

PLCButton.defaultProps = {
  kind: 'primary',
  size: 'default',
  disabled: false,
  style: {},
};

export default PLCButton;
