import {
  Button,
} from 'carbon-components-react';
import React, { useMemo, useState } from 'react';

import { usePLCValue, usePLCWrite } from '../../../features/plc/context';
import ButtonWithDisabledReason from '../../ButtonWithDisabledReason';
import DownloadConfigButton from '../components/downloadConfigButton';
import EndAutomationModal from '../components/endModal';
import HeaterStatus from '../components/heaterStatus';
import LoadConfigModal from '../components/loadConfig';
import PLCButton from '../components/plcButton';
import PLCToggleButton from '../components/plcToggleButton';
import StartAutomationModal from '../components/startModal';
import { useHFTSHandle, usePressHandle, useProgramHandle } from '../hooks/context';
import LoadingTempModal from './loadingTemp';
import SetupConfigModal from './setupModal';
import SystemStatus from './systemStatus';

function AutomationControls() {
  const write = usePLCWrite();

  const pressHandle = usePressHandle();
  const programHandle = useProgramHandle();
  const capSideHFTSHandle = useHFTSHandle('CapSide');
  const chipSideHFTSHandle = useHFTSHandle('ChipSide');
  const airStatus = usePLCValue(`${programHandle}.AirStatus`);
  const chillerStatus = usePLCValue(`${programHandle}.ChillerStatus`);
  const strain = usePLCValue(`${programHandle}.Strain`);

  const automationButtonsDisabled = useMemo(
    () => !airStatus || !chillerStatus,
    [airStatus, chillerStatus],
  );

  const capSideHotBlockHeaterSystemOn = usePLCValue(`${programHandle}.bCapSideHotBlockHeaterSystemOn`);
  const capSideRearHeaterSystemOn = usePLCValue(`${programHandle}.bCapSideRearHeaterSystemOn`);
  const capSideFrontHeaterSystemOn = usePLCValue(`${programHandle}.bCapSideFrontHeaterSystemOn`);
  const chipSideHotBlockHeaterSystemOn = usePLCValue(`${programHandle}.bChipSideHotBlockHeaterSystemOn`);
  const chipSideRearHeaterSystemOn = usePLCValue(`${programHandle}.bChipSideRearHeaterSystemOn`);
  const chipSideFrontHeaterSystemOn = usePLCValue(`${programHandle}.bChipSideFrontHeaterSystemOn`);

  const allHeatersOn = useMemo(() => [
    capSideHotBlockHeaterSystemOn,
    capSideRearHeaterSystemOn,
    capSideFrontHeaterSystemOn,
    chipSideHotBlockHeaterSystemOn,
    chipSideRearHeaterSystemOn,
    chipSideFrontHeaterSystemOn,
  ].every((heater) => heater), [
    capSideHotBlockHeaterSystemOn,
    capSideRearHeaterSystemOn,
    capSideFrontHeaterSystemOn,
    chipSideHotBlockHeaterSystemOn,
    chipSideRearHeaterSystemOn,
    chipSideFrontHeaterSystemOn,
  ]);

  const [isStartModalOpen, setIsStartModalOpen] = useState(false);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [isSetupModalOpen, setIsSetupModalOpen] = useState(false);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);

  const automationEnabledHandle = useMemo(() => `${programHandle}.bProcessAutomationEnabled`, [programHandle]);
  const automationEnabled = usePLCValue(automationEnabledHandle);

  return (
    <>
      <StartAutomationModal
        isOpen={isStartModalOpen}
        close={() => { setIsStartModalOpen(false); }}
        process="bonding"
      />
      <LoadingTempModal
        isOpen={isLoadingModalOpen}
        close={() => { setIsLoadingModalOpen(false); }}
      />
      <LoadConfigModal
        isOpen={isConfigModalOpen}
        close={() => { setIsConfigModalOpen(false); }}
        process="bonding"
      />
      <SetupConfigModal
        isOpen={isSetupModalOpen}
        close={() => { setIsSetupModalOpen(false); }}
      />
      <EndAutomationModal>
        <div className="bx--row">
          <div className="bx--col">
            <PLCToggleButton
              handle={`${pressHandle}.Vacuum`}
              label="vacuum"
            />
          </div>
        </div>
      </EndAutomationModal>
      <div className="bx--row">
        <div className="bx--col-lg-6">
          <div className="bx--row">
            <div className="bx--col">
              <h4>Automation</h4>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              {automationEnabled ? (
                <Button
                  onClick={() => { write({ handle: automationEnabledHandle, value: false }); }}
                  kind="danger"
                >
                  Disable automation
                </Button>
              ) : (
                <ButtonWithDisabledReason
                  onClick={() => { setIsStartModalOpen(true); }}
                  disabled={automationButtonsDisabled || !allHeatersOn}
                  disabledReason={automationButtonsDisabled ? 'Air or chiller is off' : 'Not all heaters are on'}
                >
                  Enable automation
                </ButtonWithDisabledReason>
              )}
            </div>
            <div className="bx--col">
              <ButtonWithDisabledReason
                onClick={() => { setIsLoadingModalOpen(true); }}
                disabled={automationButtonsDisabled}
                disabledReason="Air or chiller is off"
              >
                Go to loading temps
              </ButtonWithDisabledReason>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <Button onClick={() => { setIsSetupModalOpen(true); }}>
                Initialize configuration
              </Button>
            </div>
            <div className="bx--col">
              <Button onClick={() => { setIsConfigModalOpen(true); }}>
                Load previous configuration
              </Button>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <Button
                onClick={() => { write({ handle: `${programHandle}.Strain`, value: !strain }); }}
              >
                Trigger strain (force Instron forward)
              </Button>
            </div>
            <div className="bx--col">
              <Button
                onClick={() => { write({ handle: `${programHandle}.bInstronManualOverride`, value: true }); }}
              >
                Trigger Instron audio (force PLC forward)
              </Button>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <PLCButton
                onClick={() => {
                  write({ handle: `${programHandle}.bCapSideHotBlockHeaterSystemOn`, value: false });
                  write({ handle: `${programHandle}.bCapSideFrontHeaterSystemOn`, value: false });
                  write({ handle: `${programHandle}.bCapSideRearHeaterSystemOn`, value: false });
                  write({ handle: `${programHandle}.bChipSideHotBlockHeaterSystemOn`, value: false });
                  write({ handle: `${programHandle}.bChipSideFrontHeaterSystemOn`, value: false });
                  write({ handle: `${programHandle}.bChipSideRearHeaterSystemOn`, value: false });

                  write({ handle: `${capSideHFTSHandle}.eAutomationState`, value: 0 });
                  write({ handle: `${chipSideHFTSHandle}.eAutomationState`, value: 0 });
                }}
                kind="danger"
              >
                Turn all heaters off
              </PLCButton>
            </div>
            <div className="bx--col">
              <DownloadConfigButton process="bonding" />
            </div>
          </div>
        </div>
        <div className="bx--col-lg-2">
          <div className="bx--row">
            <div className="bx--col">
              <div className="bx--row">
                <div className="bx--col">
                  <h4>Hot Block</h4>
                </div>
              </div>
              <h6 style={{ textAlign: 'center' }}>Chip Side</h6>
              <HeaterStatus sideHandle="ChipSide" heaterHandle="Hot" />
              <h6 style={{ textAlign: 'center' }}>Cap Side</h6>
              <HeaterStatus sideHandle="CapSide" heaterHandle="Hot" />
            </div>
          </div>
        </div>
        <div className="bx--col-lg-2">
          <div className="bx--row">
            <div className="bx--col">
              <div className="bx--row">
                <div className="bx--col">
                  <h4>Front Block</h4>
                </div>
              </div>
              <h6 style={{ textAlign: 'center' }}>Chip Side</h6>
              <HeaterStatus sideHandle="ChipSide" heaterHandle="Front" />
              <h6 style={{ textAlign: 'center' }}>Cap Side</h6>
              <HeaterStatus sideHandle="CapSide" heaterHandle="Front" />
            </div>
          </div>
        </div>
        <div className="bx--col-lg-2">
          <div className="bx--row">
            <div className="bx--col">
              <div className="bx--row">
                <div className="bx--col">
                  <h4>Rear Block</h4>
                </div>
              </div>
              <h6 style={{ textAlign: 'center' }}>Chip Side</h6>
              <HeaterStatus sideHandle="ChipSide" heaterHandle="Rear" />
              <h6 style={{ textAlign: 'center' }}>Cap Side</h6>
              <HeaterStatus sideHandle="CapSide" heaterHandle="Rear" />
            </div>
          </div>
        </div>
        <SystemStatus />
      </div>
    </>
  );
}

export default AutomationControls;
