import {
  Tag,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import React from 'react';

import { usePLCValue } from '../../../features/plc/context';

function PLCTag({
  handle,
  onText,
  offText,
  onColor,
  offColor,
  style,
}) {
  const value = usePLCValue(handle);
  if (value === undefined) {
    return <Tag style={style} type="gray">Connecting...</Tag>;
  }

  return (
    <Tag
      style={style}
      type={value ? onColor : offColor}
    >
      {value ? onText : offText}
    </Tag>
  );
}

PLCTag.propTypes = {
  handle: PropTypes.string.isRequired,
  onText: PropTypes.string,
  offText: PropTypes.string,
  onColor: PropTypes.string,
  offColor: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

PLCTag.defaultProps = {
  onText: 'On',
  offText: 'Off',
  onColor: 'green',
  offColor: 'red',
  style: {},
};

export default PLCTag;
