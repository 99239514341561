import {
  Button,
  InlineNotification,
  ListItem,
  TextArea,
  UnorderedList,
} from 'carbon-components-react';
import {
  collection,
  doc,
  getFirestore,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreCollectionData } from 'reactfire';

import {
  selectCurrentSession,
  selectCurrentSessionInstronData,
} from '../../features/processData/processDataSlice';
import parseTime from '../HMI/components/parseTime';

function HMIParameters({ session }) {
  const { status, data: configSnapshot } = useFirestoreCollectionData(
    query(
      collection(getFirestore(), 'process_configs'),
      where('version', '==', session.plcConfig.version),
      where('processType', '==', session.process),
    ),
  );

  const { config, configErr } = useMemo(() => {
    if (!configSnapshot || configSnapshot.length === 0) {
      return { configErr: `No ${session.process} config found with a matching version ${session?.plcConfig?.version}. Showing raw data.` };
    }
    if (configSnapshot.length > 1) {
      return { configErr: `More than one ${session.process} config found with a matching version ${session.plcConfig.version}. Showing raw data.` };
    }

    const configData = { ...configSnapshot[0] };
    const sections = [];
    const sectionIndices = new Map();
    configData.handles.forEach((handle) => {
      if (!sectionIndices.has(handle.section)) {
        sectionIndices.set(handle.section, sections.length);
        sections.push({ header: handle.section, values: [] });
      }
    });
    configData.handles.forEach((handle) => {
      const sectionIndex = sectionIndices.get(handle.section);
      sections[sectionIndex].values.push(handle);
    });
    return { config: { ...configData, rows: sections } };
  }, [session, configSnapshot]);

  if (status === 'loading') {
    return (
      <div className="bx--col">
        <h4>Parameters</h4>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="bx--col">
      <h4>Parameters</h4>
      {configErr ? (
        <InlineNotification
          title="Error"
          subtitle={configErr}
          kind="error"
          hideCloseButton
          lowContrast
          style={{ width: '100%' }}
        />
      ) : null}
      {config?.rows ? (
        <UnorderedList>
          {config?.rows?.map((row) => (
            <React.Fragment key={row.header}>
              <h5>{row.header}</h5>
              {row.values.map((item) => {
                const { handle, type, label } = item;
                const value = session.plcConfig[handle];
                if (value !== undefined) {
                  return (
                    <ListItem key={handle}>{`${label}: ${type === 'TIME' ? parseTime(value) : value}`}</ListItem>
                  );
                }
                return null;
              })}
            </React.Fragment>
          ))}
        </UnorderedList>
      ) : (
        <UnorderedList>
          {Object.keys(session.plcConfig || {}).map((key) => (
            <ListItem key={key}>{`${key}: ${session.plcConfig[key]}`}</ListItem>
          ))}
        </UnorderedList>
      ) }
    </div>
  );
}

HMIParameters.propTypes = {
  session: PropTypes.shape({
    plcConfig: PropTypes.shape({
      version: PropTypes.string,
    }),
    process: PropTypes.string,
  }).isRequired,
};

function Overview({ sessionID }) {
  const session = useSelector(selectCurrentSession);
  const instronData = useSelector(selectCurrentSessionInstronData);
  const metadata = instronData?.metadata;

  const initialValues = {
    hypothesis: session.hypothesis,
    observations: session.observations,
  };

  const metadataHeaders = useMemo(() => {
    if (!metadata) {
      return [];
    }
    return Object.keys(metadata).sort();
  }, [metadata]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={false}
      onSubmit={async (values) => {
        await updateDoc(doc(getFirestore(), 'process_logs', sessionID), {
          hypothesis: values.hypothesis,
          observations: values.observations,
        });
      }}
    >
      {({
        values, handleBlur, handleChange, handleSubmit, errors, isSubmitting,
      }) => (
        <>
          <div className="bx--row">
            <div className="bx--col">
              <h1>{`${session.project} - ${session.number}`}</h1>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <TextArea
                labelText="Hypothesis"
                id="hypothesis"
                key="hypothesis"
                value={values.hypothesis}
                rows={3}
                onBlur={handleBlur}
                onChange={handleChange}
                invalid={!!errors.hypothesis}
                invalidText={errors.hypothesis}
              />
            </div>
            <div className="bx--col">
              <TextArea
                labelText="Observations"
                id="observations"
                key="observations"
                value={values.observations}
                rows={3}
                onBlur={handleBlur}
                onChange={handleChange}
                invalid={!!errors.observations}
                invalidText={errors.observations}
              />
            </div>
          </div>
          {(initialValues.observations !== values.observations
          || initialValues.hypothesis !== values.hypothesis ? (
            <div className="bx--row">
              <div className="bx--col">
                <Button
                  kind="primary"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Save changes
                </Button>
              </div>
            </div>
            ) : null)}
          <div className="bx--row">
            {!!session?.plcConfig?.version && !!session?.process ? (
              <HMIParameters session={session} />
            ) : null}
            {metadataHeaders && metadataHeaders.length > 0 ? (
              <div className="bx--col">
                <h4>Instron Metadata</h4>
                <UnorderedList>
                  {metadataHeaders.map((key) => (
                    <ListItem key={key}>{`${key}: ${metadata[key]}`}</ListItem>
                  ))}
                </UnorderedList>
              </div>
            ) : null}
          </div>
        </>
      )}
    </Formik>
  );
}

Overview.propTypes = {
  sessionID: PropTypes.string.isRequired,
};

export default Overview;
