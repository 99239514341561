import {
  Box,
  ColumnLayout,
  Container,
  ExpandableSection,
  SpaceBetween,
} from '@cloudscape-design/components';
import {
  collection,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useFirestoreCollectionData } from 'reactfire';

function Travers({
  customerName,
  projectNumber,
  shipDate,
  moldedGoal,
  postmachinedGoal,
  bondedGoal,
  connectorizedGoal,
}) {
  const firestore = getFirestore();
  const partsRef = collection(firestore, 'travelers');
  const travelersQuery = query(partsRef, where('project', '==', projectNumber));
  const { status, data: travelers } = useFirestoreCollectionData(travelersQuery, { idField: 'id' });
  const parts = useMemo(() => {
    if (status === 'success') {
      const retParts = {
        molded: {
          inspected: 0,
          toBeInspected: 0,
          rejectCosmetic: 0,
          rejectMajor: 0,
          tuning: 0,
          unknown: 0,
        },
        postmachined: {
          done: 0,
          todo: 0,
        },
        bonded: {
          inspected: 0,
          toBeInspected: 0,
          rejectCosmetic: 0,
          rejectMajor: 0,
          tuning: 0,
          unknown: 0,
        },
        connectorized: {
          done: 0,
          todo: 0,
        },
      };
      travelers.forEach((traveler) => {
        if (!traveler.moldRunId || traveler.moldRunId.length === 0) {
          return;
        }
        switch (traveler.moldedPartInspected) {
          case 'Not inspected': { retParts.molded.toBeInspected += 1; return; }
          case 'Passed': { retParts.molded.inspected += 1; break; }
          case 'Reject: Cosmetic': { retParts.molded.rejectCosmetic += 1; return; }
          case 'Reject: Major': { retParts.molded.rejectMajor += 1; return; }
          case 'Tuning': { retParts.molded.tuning += 1; return; }
          default: { retParts.molded.unknown += 1; return; }
        }
        if (traveler.secondariesMachined) {
          retParts.postmachined.done += 1;
        }
        if (!traveler.bondRunId || traveler.bondRunId.length === 0) {
          return;
        }
        switch (traveler.bondedPartInspected) {
          case 'Not inspected': { retParts.bonded.toBeInspected += 1; return; }
          case 'Passed': { retParts.bonded.inspected += 1; break; }
          case 'Reject: Cosmetic': { retParts.bonded.rejectCosmetic += 1; return; }
          case 'Reject: Major': { retParts.bonded.rejectMajor += 1; return; }
          case 'Tuning': { retParts.molded.tuning += 1; return; }
          default: { retParts.bonded.unknown += 1; return; }
        }
        if (traveler.componentsWelded) {
          retParts.connectorized.done += 1;
        }
      });
      retParts.postmachined.todo = retParts.molded.inspected - retParts.postmachined.done;
      retParts.connectorized.todo = retParts.bonded.inspected - retParts.connectorized.done;
      return retParts;
    }
    return undefined;
  }, [travelers]);

  const shipDateText = useMemo(() => {
    const daysUnitlShip = Math.ceil((new Date(`${shipDate} 00:00:00`) - new Date()) / (1000 * 60 * 60 * 24));
    if (daysUnitlShip === 1) {
      return 'Ships Tomorrow';
    }
    if (daysUnitlShip === 0) {
      return 'Ships Today';
    }
    return `Ships in ${daysUnitlShip} Days`;
  }, [shipDate]);

  if (status !== 'success' || !parts) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bx--row">
      <div className="bx--col">
        <Container>
          <ExpandableSection
            headerText={`[${projectNumber}] ${customerName} Order Status - ${shipDateText}`}
          >
            <ColumnLayout
              columns={(
                [moldedGoal, postmachinedGoal, bondedGoal, connectorizedGoal]
                  .filter((goal) => goal > 0).length
            )}
              variant="text-grid"
              minColumnWidth={170}
            >
              {moldedGoal > 0 ? (
                <SpaceBetween size="m">
                  <div>
                    <Box variant="awsui-key-label">Molded Parts</Box>
                    <Box variant="awsui-value-large">
                      {`${parts.molded.inspected}/${moldedGoal} Inspected`}
                    </Box>
                  </div>
                  <div>
                    <Box variant="awsui-key-label">To-be inspected</Box>
                    <div>{parts.molded.toBeInspected}</div>
                  </div>
                  <div>
                    <Box variant="awsui-key-label">Reject (Cosmetic)</Box>
                    <div>{parts.molded.rejectCosmetic}</div>
                  </div>
                  <div>
                    <Box variant="awsui-key-label">Reject (Major)</Box>
                    <div>{parts.molded.rejectMajor}</div>
                  </div>
                  <div>
                    <Box variant="awsui-key-label">Tuning</Box>
                    <div>{parts.molded.tuning}</div>
                  </div>
                </SpaceBetween>
              ) : null}
              {postmachinedGoal > 0 ? (
                <SpaceBetween size="m">
                  <div>
                    <Box variant="awsui-key-label">Postmachined Parts</Box>
                    <Box variant="awsui-value-large">
                      {`${parts.postmachined.done}/${postmachinedGoal} Postmachined`}
                    </Box>
                  </div>
                  <div>
                    <Box variant="awsui-key-label">To be postmachined</Box>
                    <div>{parts.postmachined.todo}</div>
                  </div>
                </SpaceBetween>
              ) : null}
              {bondedGoal > 0 ? (
                <SpaceBetween size="m">
                  <div>
                    <Box variant="awsui-key-label">Bonded Parts</Box>
                    <Box variant="awsui-value-large">
                      {`${parts.bonded.inspected}/${bondedGoal} Inspected`}
                    </Box>
                  </div>
                  <div>
                    <Box variant="awsui-key-label">To-be inspected</Box>
                    <div>{parts.bonded.toBeInspected}</div>
                  </div>
                  <div>
                    <Box variant="awsui-key-label">Reject (Cosmetic)</Box>
                    <div>{parts.bonded.rejectCosmetic}</div>
                  </div>
                  <div>
                    <Box variant="awsui-key-label">Reject (Major)</Box>
                    <div>{parts.bonded.rejectMajor}</div>
                  </div>
                  <div>
                    <Box variant="awsui-key-label">Tuning</Box>
                    <div>{parts.bonded.tuning}</div>
                  </div>
                </SpaceBetween>
              ) : null}
              {connectorizedGoal > 0 ? (
                <SpaceBetween size="m">
                  <div>
                    <Box variant="awsui-key-label">Connectorized Parts</Box>
                    <Box variant="awsui-value-large">
                      {`${parts.connectorized.done}/${connectorizedGoal} Connectorized`}
                    </Box>
                  </div>
                  <div>
                    <Box variant="awsui-key-label">To be connectorized</Box>
                    <div>{parts.connectorized.todo}</div>
                  </div>
                </SpaceBetween>
              ) : null}
            </ColumnLayout>
          </ExpandableSection>
        </Container>
      </div>
    </div>
  );
}

Travers.propTypes = {
  customerName: PropTypes.string.isRequired,
  projectNumber: PropTypes.string.isRequired,
  shipDate: PropTypes.string.isRequired,
  moldedGoal: PropTypes.number,
  postmachinedGoal: PropTypes.number,
  bondedGoal: PropTypes.number,
  connectorizedGoal: PropTypes.number,
};

Travers.defaultProps = {
  moldedGoal: 0,
  postmachinedGoal: 0,
  bondedGoal: 0,
  connectorizedGoal: 0,
};

export default Travers;
