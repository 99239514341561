import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFunctions, httpsCallable } from 'firebase/functions';

const initialState = {
  currentTask: {},
  projects: {
    status: 'not_loaded',
    data: [],
  },
  tags: {
    status: 'not_loaded',
    data: [],
  },
  error: null,
};

export const getMyCurrentClockifyTask = createAsyncThunk(
  'clockify/getMyCurrentTask',
  async (_, { rejectWithValue }) => {
    try {
      const functions = getFunctions();
      const getMyCurrentTask = httpsCallable(functions, 'getMyCurrentClockifyTask');
      const { data } = await getMyCurrentTask();
      if (data.length > 0) {
        return data[0];
      }
      return {};
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const startMyClockifyTimer = createAsyncThunk(
  'clockify/startMyTimer',
  async ({ projectID, tagID }, { rejectWithValue, dispatch }) => {
    try {
      const functions = getFunctions();
      const startMyTimer = httpsCallable(functions, 'startMyClockifyTimer');
      await startMyTimer({ projectID, tagID });
      dispatch(getMyCurrentClockifyTask());
      return null;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const stopMyClockifyTimer = createAsyncThunk(
  'clockify/stopMyTimer',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const functions = getFunctions();
      const stopMyTimer = httpsCallable(functions, 'stopMyClockifyTimer');
      await stopMyTimer();
      dispatch(getMyCurrentClockifyTask());
      return null;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const listClockifyProjects = createAsyncThunk(
  'clockify/listProjects',
  async (_, { rejectWithValue }) => {
    try {
      const functions = getFunctions();
      const listProjects = httpsCallable(functions, 'listClockifyProjects');
      const { data } = await listProjects();
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { clockify } = getState();

      // check if projects are already loading
      if (clockify.projects.status === 'loading' || clockify.projects.status === 'fulfilled') {
        return false;
      }

      return true;
    },
  },
);

export const listClockifyTags = createAsyncThunk(
  'clockify/listTags',
  async (_, { rejectWithValue }) => {
    try {
      const functions = getFunctions();
      const listTags = httpsCallable(functions, 'listClockifyTags');
      const { data } = await listTags();
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { clockify } = getState();

      // check if tags are already loading
      if (clockify.tags.status === 'loading' || clockify.tags.status === 'fulfilled') {
        return false;
      }

      return true;
    },
  },
);

export const clockifySlice = createSlice({
  name: 'clockify',
  initialState,
  extraReducers: (builder) => {
    builder
      // getMyCurrentClockifyTask
      .addCase(getMyCurrentClockifyTask.fulfilled, (state, action) => {
        state.currentTask = action.payload;
      })
      .addCase(getMyCurrentClockifyTask.rejected, (state, action) => {
        state.currentTask = {};
        state.error = action.payload;
      })

      // listClockifyProjects
      .addCase(listClockifyProjects.pending, (state) => {
        state.projects = {
          status: 'loading',
          data: [],
        };
      })
      .addCase(listClockifyProjects.rejected, (state, action) => {
        state.projects = {
          status: 'fulfilled',
          data: [],
        };
        state.error = action.payload;
      })
      .addCase(listClockifyProjects.fulfilled, (state, action) => {
        state.projects = {
          status: 'fulfilled',
          data: action.payload,
        };
      })

      // listClockifyTags
      .addCase(listClockifyTags.pending, (state) => {
        state.tags = {
          status: 'loading',
          data: [],
        };
      })
      .addCase(listClockifyTags.rejected, (state, action) => {
        state.tags = {
          status: 'fulfilled',
          data: [],
        };
        state.error = action.payload;
      })
      .addCase(listClockifyTags.fulfilled, (state, action) => {
        state.tags = {
          status: 'fulfilled',
          data: action.payload,
        };
      })

      // startMyClockifyTimer
      .addCase(startMyClockifyTimer.rejected, (state, action) => {
        state.error = action.payload;
      })

      // stopMyClockifyTimer
      .addCase(stopMyClockifyTimer.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const selectCurrentTask = (state) => state.clockify.currentTask;

export const selectClockifyProjects = (state) => state.clockify.projects.data;
export const selectClockifyProjectsError = (state) => state.clockify.projects.error;

export const selectClockifyTags = (state) => state.clockify.tags.data;
export const selectClockifyTagsError = (state) => state.clockify.tags.error;

export default clockifySlice.reducer;
