import { Button, TooltipDefinition } from 'carbon-components-react';
import PropTypes from 'prop-types';
import React from 'react';

function ButtonWithDisabledReason({
  children, disabled, disabledReason, ...rest
}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <Button
          disabled={disabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        >
          {children}
        </Button>
      </div>
      {disabled ? (
        <TooltipDefinition direction="bottom" tooltipText={disabledReason}>
          Why is this disabled?
        </TooltipDefinition>
      ) : null}
    </div>
  );
}

ButtonWithDisabledReason.propTypes = {
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ButtonWithDisabledReason.defaultProps = {
  disabled: false,
  disabledReason: '',
};

export default ButtonWithDisabledReason;
