import { configureStore } from '@reduxjs/toolkit';

import clockifyReducer from '../features/clockify/clockifySlice';
import feedbackChecklistReducer from '../features/feedbackChecklist';
import forgeReducer from '../features/forge/forgeSlice';
import ordersReducer from '../features/orders/ordersSlice';
import partsReducer from '../features/parts/partsSlice';
import line2PlcReducer from '../features/plc/line2PlcSlice';
import mainPlcReducer from '../features/plc/mainPlcSlice';
import welderPlcReducer from '../features/plc/welderPlcSlice';
import processDataReducer from '../features/processData/processDataSlice';
import userReducer from '../features/user/userSlice';

const store = configureStore({
  reducer: {
    clockify: clockifyReducer,
    feedbackChecklist: feedbackChecklistReducer,
    forge: forgeReducer,
    orders: ordersReducer,
    parts: partsReducer,
    mainPlcData: mainPlcReducer,
    welderPlcData: welderPlcReducer,
    line2PlcData: line2PlcReducer,
    processData: processDataReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default store;
