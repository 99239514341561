import {
  InlineNotification,
  NotificationActionButton,
} from 'carbon-components-react';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FirebaseContext from '../../app/firebase';
import {
  getMyCurrentClockifyTask,
  listClockifyProjects,
  listClockifyTags,
  selectClockifyProjects,
  selectClockifyTags,
  selectCurrentTask,
  stopMyClockifyTimer,
} from '../../features/clockify/clockifySlice';
import TaskPicker from './taskPicker';

function CurrentTask() {
  const { user } = useContext(FirebaseContext);

  const currentTask = useSelector(selectCurrentTask);
  const [showTaskPicker, setShowTaskPicker] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (dispatch) {
      dispatch(getMyCurrentClockifyTask());
      dispatch(listClockifyProjects());
      dispatch(listClockifyTags());
    }
  }, [dispatch]);
  const clockifyProjects = useSelector(selectClockifyProjects);
  const clockifyProjectsDict = useMemo(() => {
    const dict = {};
    clockifyProjects.forEach((project) => {
      dict[project.id] = project;
    });
    return dict;
  }, [clockifyProjects]);

  const clockifyTags = useSelector(selectClockifyTags);
  const clockifyTagsDict = useMemo(() => {
    const dict = {};
    clockifyTags.forEach((tag) => {
      dict[tag.id] = tag;
    });
    return dict;
  }, [clockifyTags]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      dispatch(getMyCurrentClockifyTask());
    }, 5000);
    return () => { clearInterval(intervalID); };
  }, []);

  if (!currentTask || !currentTask.projectId) {
    return (
      <>
        <TaskPicker
          isOpen={showTaskPicker}
          close={() => {
            dispatch(getMyCurrentClockifyTask());
            setShowTaskPicker(false);
          }}
        />
        <InlineNotification
          kind="info"
          lowContrast
          hideCloseButton
          title="No current task"
          subtitle={`Signed in as ${user.email}`}
          style={{ minWidth: '100%', width: '100%' }}
          actions={(
            <NotificationActionButton
              onClick={() => { setShowTaskPicker(true); }}
            >
              Start timer
            </NotificationActionButton>
        )}
        />
      </>
    );
  }

  return (
    <InlineNotification
      kind="success"
      lowContrast
      hideCloseButton
      title={(
        `${clockifyProjectsDict[currentTask.projectId]?.name || 'Unknown project'
        } - ${currentTask.tagIds?.length > 0 && clockifyTagsDict[currentTask.tagIds[0]]?.name
          ? clockifyTagsDict[currentTask.tagIds[0]].name : 'Unknown station'}`)}
      subtitle={`Signed in as ${user.email}`}
      style={{ minWidth: '100%', width: '100%' }}
      actions={(
        <NotificationActionButton onClick={async () => { dispatch(stopMyClockifyTimer()); }}>
          Stop timer
        </NotificationActionButton>
        )}
    />
  );
}

export default CurrentTask;
