import './index.scss';
import '@cloudscape-design/global-styles/index.css';

import { I18nProvider, importMessages } from '@cloudscape-design/components/i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';

import App from './App';
import { firebaseConfig, FirebaseContextProvider } from './app/firebase';
import store from './app/store';
import RequireAuth from './components/RequireAuth';
import ForgeOAuthPage, { ForgeOAuthCallbackPage } from './pages/Forge';
import HMI from './pages/HMI';
import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import OldOrders from './pages/OldOrders';
import OrdersPage from './pages/Orders';
import PartPage, {
  CADInfo,
  CADStudioSection,
  FeedbackSection,
  OverviewSection,
  PricingSection,
  V1PricingSection,
} from './pages/Part';
import SummaryPage, { ProcessDataPage } from './pages/ProcessData';
import SummaryPageV0, { ProcessDataPage as ProcessDataPageV0 } from './pages/ProcessData/v0';
import SummaryPageV1, { ProcessDataPage as ProcessDataPageV1 } from './pages/ProcessData/v1';
import TravelerPage from './pages/Travelers';
import UserPage from './pages/User';
import * as serviceWorker from './serviceWorker';

(async () => {
  const locale = document.documentElement.lang;
  const messages = await importMessages(locale);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <App />,
      children: [
        {
          index: true,
          element: <RequireAuth><HomePage /></RequireAuth>,
        },
        {
          path: 'login',
          element: <LoginPage />,
        },
        {
          path: 'user/:userID',
          element: <RequireAuth><UserPage /></RequireAuth>,
        },
        {
          path: 'part/:partID',
          element: <RequireAuth><PartPage /></RequireAuth>,
          children: [
            {
              path: 'overview',
              element: <RequireAuth><OverviewSection /></RequireAuth>,
            },
            {
              path: 'cad',
              element: <RequireAuth><CADInfo /></RequireAuth>,
            },
            {
              path: 'cad-studio',
              element: <RequireAuth><CADStudioSection /></RequireAuth>,
            },
            {
              path: 'feedback',
              element: <RequireAuth><FeedbackSection /></RequireAuth>,
            },
            {
              path: 'pricing',
              element: <RequireAuth><PricingSection /></RequireAuth>,
              exact: true,
            },
            {
              path: 'pricing-secret',
              element: <RequireAuth><V1PricingSection /></RequireAuth>,
            },
          ],
        },
        {
          path: 'forge',
          exact: true,
          element: <RequireAuth><ForgeOAuthPage /></RequireAuth>,
        },
        {
          path: 'orders',
          exact: true,
          element: <RequireAuth><OrdersPage /></RequireAuth>,
        },
        {
          path: 'forge/callback',
          element: <RequireAuth><ForgeOAuthCallbackPage /></RequireAuth>,
          exact: true,
        },
        {
          path: 'process-data/forming',
          element: <RequireAuth><SummaryPage processType="forming" /></RequireAuth>,
          exact: true,
        },
        {
          path: 'process-data/forming/:sessionID',
          element: <RequireAuth><ProcessDataPage processType="forming" /></RequireAuth>,
          exact: true,
        },
        {
          path: 'process-data/bonding',
          element: <RequireAuth><SummaryPage processType="bonding" /></RequireAuth>,
          exact: true,
        },
        {
          path: 'process-data/bonding/:sessionID',
          element: <RequireAuth><ProcessDataPage processType="bonding" /></RequireAuth>,
          exact: true,
        },
        {
          path: 'process-data/v0/forming',
          element: <RequireAuth><SummaryPageV0 /></RequireAuth>,
          exact: true,
        },
        {
          path: 'process-data/v0/forming/:chipName',
          element: <RequireAuth><ProcessDataPageV0 /></RequireAuth>,
          exact: true,
        },
        {
          path: 'process-data/:version/:processType',
          element: <RequireAuth><SummaryPageV1 /></RequireAuth>,
          exact: true,
        },
        {
          path: 'process-data/:version/:processType/:sessionID',
          element: <RequireAuth><ProcessDataPageV1 /></RequireAuth>,
          exact: true,
        },
        {
          path: '/live/:pressName',
          element: <RequireAuth><HMI /></RequireAuth>,
          exact: true,
        },
        {
          path: '/travelers/:travelerId',
          element: <RequireAuth><TravelerPage /></RequireAuth>,
          exact: true,
        },
        {
          path: '/old-orders',
          element: <RequireAuth><OldOrders /></RequireAuth>,
          exact: true,
        },
      ],
    },
  ]);

  ReactDOM.render(
    <React.StrictMode>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <I18nProvider locale={locale} messages={messages}>
          <FirebaseContextProvider>
            <Provider store={store}>
              <RouterProvider
                router={router}
              />
            </Provider>
          </FirebaseContextProvider>
        </I18nProvider>
      </FirebaseAppProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
})();
