import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserByID, selectUserEmailByID } from '../../features/user/userSlice';

function UserEmail({ userID, backupEmail }) {
  const user = useSelector(selectUserEmailByID(userID));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserByID(userID));
  }, [dispatch, userID]);

  if (user.isAnonymous) {
    return (
      <span>
        Anonymous User
        {backupEmail ? ` (${backupEmail})` : ''}
      </span>
    );
  }

  let text = userID;
  if (user.email) {
    text = user.email;
  } else if (user.status === 'loading') {
    text += ' (loading email...)';
  } else if (user.error) {
    text += ` (Error: ${user.error})`;
  }

  return <span>{text}</span>;
}

UserEmail.defaultProps = {
  backupEmail: null,
};

UserEmail.propTypes = {
  userID: PropTypes.string.isRequired,
  backupEmail: PropTypes.string,
};

export default UserEmail;
