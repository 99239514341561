/* eslint-disable jsx-a11y/alt-text */
import { SkeletonPlaceholder } from 'carbon-components-react';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

function SecureImage({ path }) {
  const [url, setURL] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const storage = getStorage();
    getDownloadURL(ref(storage, path))
      .then((downloadURL) => { setURL(downloadURL); })
      .catch((err) => { setError(err.message); });
  }, [path]);

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="bx--aspect-ratio bx--aspect-ratio--16x9">
      <div
        className="bx--aspect-ratio--object"
        style={url ? {
          backgroundImage: `url(${url})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        } : {}}
      >
        {url ? null : <SkeletonPlaceholder style={{ width: '100%', height: '100%' }} />}
      </div>
    </div>
  );
}

SecureImage.propTypes = {
  path: PropTypes.string.isRequired,
};

export default SecureImage;
