import { Content, InlineNotification } from 'carbon-components-react';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import {
  AuthProvider,
  FirestoreProvider,
  useFirebaseApp,
} from 'reactfire';

import FirebaseContext from './app/firebase';
import CurrentTask from './components/Clockify';
import Navigation from './components/Navigation';

function App() {
  const { user } = useContext(FirebaseContext);

  const app = useFirebaseApp();
  const firestoreInstance = getFirestore(app);
  const auth = getAuth(app);

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <Navigation />
        <Content style={{ padding: '2rem 0' }}>
          <div className="bx--grid">
            <div className="bx--row">
              <div className="bx--col" style={{ marginTop: '-1rem', width: '100%' }}>
                <InlineNotification
                  kind="error"
                  lowContrast
                  hideCloseButton
                  title="This is an old version of Parascope!"
                  subtitle={(
                    <>
                      Please use the new version at &quot;
                      <a href="https://admin.parallelfluidics.com">https://admin.parallelfluidics.com</a>
                      .&quot; If the the new version is missing features you need,
                      please add them to the Parascope wishlist!
                    </>
)}
                  style={{ minWidth: '100%', width: '100%' }}
                />
              </div>
            </div>
            {user && (
            <div className="bx--row">
              <div className="bx--col" style={{ marginTop: '-1rem', width: '100%' }}>
                <CurrentTask />
              </div>
            </div>
            )}
            <Outlet />
          </div>
        </Content>
      </FirestoreProvider>
    </AuthProvider>
  );
}

export default App;
