import { Tag } from 'carbon-components-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectPart, selectQuote } from '../../features/parts/partsSlice';

function QuoteStatus({ partID }) {
  const { hasBeenOrdered } = useSelector(selectPart(partID));
  const { status } = useSelector(selectQuote(partID));

  let tagText = 'Unknown';
  if (hasBeenOrdered) {
    tagText = 'Ordered';
  } else {
    tagText = {
      quoted: 'Quoted',
      pending: 'Pending',
      no_quote: 'No quote',
    }[status] || 'Unknown';
  }

  return (
    <Tag
      type={{
        Quoted: 'teal',
        Pending: 'blue',
        Ordered: 'purple',
        'No quote': 'red',
        Unknown: 'gray',
      }[tagText]}
      style={{ marginLeft: 0 }}
    >
      {tagText}
    </Tag>
  );
}

QuoteStatus.propTypes = {
  partID: PropTypes.string.isRequired,
};

export default QuoteStatus;
