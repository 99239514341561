/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  DataTable,
  InlineNotification,
  Modal,
  OverflowMenu,
  OverflowMenuItem,
  Select,
  SelectItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarAction,
  TableToolbarContent,
  TableToolbarMenu,
  TextInput,
} from 'carbon-components-react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import * as Yup from 'yup';

export const BASE_LEADTIMES = [
  {
    id: 'leadtime-standard',
    multiplier: 1,
    leadtimeDays: 10,
    leadtimeText: '10 business days from receipt of PO',
    minQuantity: 5,
    maxQuantity: 100,
  },
  {
    id: 'leadtime-expedited',
    multiplier: 1.25,
    leadtimeDays: 5,
    leadtimeText: '5 business days from receipt of PO',
    minQuantity: 5,
    maxQuantity: 100,
  },
  {
    id: 'leadtime-rush',
    multiplier: 2,
    leadtimeDays: 3,
    leadtimeText: '3 business days from receipt of PO',
    minQuantity: 5,
    maxQuantity: 100,
  },
];

function EditLeadtime({
  leadtime, setLeadtime, isOpen, close, leadtimeType, existingLeadtimes,
}) {
  const schema = useMemo(() => {
    const shape = {
      id: Yup.string().oneOf(['leadtime-standard', 'leadtime-expedited', 'leadtime-rush']).required(),
      multiplier: Yup.number().required('Multiplier is required'),
      minQuantity: Yup.number().required('Minimum Quantity is required').integer(),
      maxQuantity: Yup.number().required('Maximum Quantity is required').integer(),
    };
    if (leadtimeType === 'days') {
      shape.leadtimeDays = Yup.number().required().min(1).integer();
    } else {
      shape.leadtimeText = Yup.string().required();
    }
    return Yup.object().shape(shape);
  }, [leadtimeType]);

  return (
    <Formik
      initialValues={leadtime}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        setLeadtime({
          ...values,
          leadtimeDays: values.leadtimeDays ? parseInt(values.leadtimeDays, 10) : null,
          multiplier: parseFloat(values.multiplier),
          minQuantity: parseInt(values.minQuantity, 10),
          maxQuantity: parseInt(values.maxQuantity, 10),
        });
        close();
        setSubmitting(false);
      }}
    >
      {({
        values, errors, handleBlur, handleChange, handleSubmit,
      }) => (
        <Modal
          size="lg"
          open={isOpen}
          onRequestClose={() => { close(); }}
          modalHeading="Edit leadtime"
          primaryButtonText="Update"
          secondaryButtonText="Cancel"
          onRequestSubmit={handleSubmit}
        >
          <div className="bx--row">
            <div className="bx--col">
              <Select
                id="id"
                key="id"
                labelText="Delivery Type"
                value={values.id}
                onChange={handleChange}
                onBlur={handleBlur}
                invalidText={errors.id}
                invalid={!!errors.id}
              >
                {BASE_LEADTIMES.map((baseLeadtime) => (
                  <SelectItem
                    value={baseLeadtime.id}
                    text={baseLeadtime.id}
                    key={baseLeadtime.id}
                    disabled={(
                      existingLeadtimes.includes(baseLeadtime.id)
                      && baseLeadtime.id !== values.id
                    )}
                  />
                ))}
              </Select>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <TextInput
                labelText="Multiplier"
                id="multiplier"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.multiplier}
                invalidText={errors.multiplier}
                invalid={!!errors.multiplier}
              />
            </div>
          </div>
          {leadtimeType === 'days' ? (
            <div className="bx--row">
              <div className="bx--col">
                <TextInput
                  labelText="Leadtime Days"
                  id="leadtimeDays"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.leadtimeDays}
                  invalidText={errors.leadtimeDays}
                  invalid={!!errors.leadtimeDays}
                />
              </div>
            </div>
          ) : (
            <div className="bx--row">
              <div className="bx--col">
                <TextInput
                  labelText="Leadtime Text"
                  id="leadtimeText"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.leadtimeText}
                  invalidText={errors.leadtimeText}
                  invalid={!!errors.leadtimeText}
                />
              </div>
            </div>
          )}
          <div className="bx--row">
            <div className="bx--col">
              <TextInput
                labelText="Min Quantity"
                id="minQuantity"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.minQuantity}
                invalidText={errors.minQuantity}
                invalid={!!errors.minQuantity}
              />
            </div>
            <div className="bx--col">
              <TextInput
                labelText="Max Quantity"
                id="maxQuantity"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.maxQuantity}
                invalidText={errors.maxQuantity}
                invalid={!!errors.maxQuantity}
              />
            </div>
          </div>
        </Modal>
      )}
    </Formik>
  );
}

EditLeadtime.propTypes = {
  leadtime: PropTypes.shape({
    id: PropTypes.string,
    multiplier: PropTypes.number,
    leadtimeDays: PropTypes.number,
    leadtimeText: PropTypes.string,
    minQuantity: PropTypes.number,
    maxQuantity: PropTypes.number,
  }).isRequired,
  leadtimeType: PropTypes.oneOf(['days', 'text']).isRequired,
  setLeadtime: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  existingLeadtimes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function Leadtimes({
  leadtimes: leadtimesIn,
  setFieldValue,
  setFieldTouched,
  disabled,
  touched,
  isSubmitting,
  onSubmit: handleSubmit,
}) {
  const leadtimes = useMemo(() => [...leadtimesIn].sort(
    (a, b) => (
      BASE_LEADTIMES.findIndex((baseLeadtime) => baseLeadtime.id === a.id)
      - BASE_LEADTIMES.findIndex((baseLeadtime) => baseLeadtime.id === b.id)),
  ));
  const [focusedLeadtimeItem, setFocusedLeadtimeItem] = useState({
    id: '',
    multiplier: 1,
    leadtimeDays: 0,
    leadTimeText: '',
    minQuantity: 5,
    maxQuantity: 100,
  });
  const [updateLeadtimeFunc, setUpdateLeadtimeFunc] = useState(() => () => {});
  const [isLeadtimeEditorOpen, setLeadtimeEditorOpen] = useState(false);
  const [leadtimeTypeError, setLeadtimeTypeError] = useState(null);
  const leadtimeType = useMemo(() => {
    const isDays = leadtimes.some((leadtime) => leadtime.leadtimeDays);
    const isText = leadtimes.some((leadtime) => leadtime.leadtimeText);
    if (isDays && isText) {
      setLeadtimeTypeError('Leadtime type cannot be mixed between "days" and "text"');
      return null;
    }
    if (!isDays && !isText) {
      setLeadtimeTypeError('Leadtime type cannot be empty');
      return null;
    }
    if (isDays) return 'days';
    if (isText) return 'text';
    return 'days';
  }, [leadtimesIn]);
  const toggleLeadtimeType = () => {
    if (leadtimeType === 'days') {
      // If we're currently in 'days' mode, switch to 'text' mode
      for (let i = 0; i < leadtimes.length; i += 1) {
        setFieldValue(`leadtimes[${i}].leadtimeText`, BASE_LEADTIMES.find((baseLeadtime) => baseLeadtime.id === leadtimes[i].id).leadtimeText);
        setFieldValue(`leadtimes[${i}].leadtimeDays`, null);
      }
    } else {
      // If we're currently in 'text' mode, switch to 'days' mode
      for (let i = 0; i < leadtimes.length; i += 1) {
        setFieldValue(`leadtimes[${i}].leadtimeDays`, BASE_LEADTIMES.find((baseLeadtime) => baseLeadtime.id === leadtimes[i].id).leadtimeDays);
        setFieldValue(`leadtimes[${i}].leadtimeText`, null);
      }
    }
    setFieldTouched('leadtimes');
  };
  const existingLeadtimes = useMemo(() => leadtimes.map((leadtime) => leadtime.id), [leadtimes]);

  const headerData = useMemo(() => {
    const headers = [
      {
        header: 'Delivery Type',
        key: 'id',
      },
      {
        header: 'Multiplier',
        key: 'multiplier',
      },
    ];
    if (leadtimeType === 'days') {
      headers.push(
        {
          header: 'Leadtime Days',
          key: 'leadtimeDays',
        },
      );
    } else {
      headers.push(
        {
          header: 'Leadtime Text',
          key: 'leadtimeText',
        },
      );
    }

    headers.push(
      {
        header: 'Min Quantity',
        key: 'minQuantity',
      },
    );
    headers.push(
      {
        header: 'Max Quantity',
        key: 'maxQuantity',
      },
    );
    return headers;
  }, [leadtimeType]);

  return (
    <>
      {typeof document === 'undefined'
        ? null
        : ReactDOM.createPortal(
          <EditLeadtime
            leadtime={focusedLeadtimeItem}
            leadtimeType={leadtimeType}
            isOpen={isLeadtimeEditorOpen}
            close={() => {
              setLeadtimeEditorOpen(false);
              setFocusedLeadtimeItem({
                id: '',
                multiplier: 1,
                leadtimeDays: 0,
                leadTimeText: '',
                minQuantity: 5,
                maxQuantity: 100,
              });
            }}
            setLeadtime={updateLeadtimeFunc}
            existingLeadtimes={existingLeadtimes}
          />,
          document.body,
        )}
      { leadtimeTypeError ? (
        <InlineNotification
          kind="error"
          title="Leadtime type error"
          subtitle={leadtimeTypeError}
          hideCloseButton
          lowContrast
          style={{ minWidth: '100%', width: '100%' }}
        />
      ) : null }
      <DataTable
        rows={leadtimes}
        headers={headerData}
      >
        {({
          rows, headers, getToolbarProps, getHeaderProps, getTableProps,
        }) => {
          const handleEditLeadtime = (index) => {
            setFocusedLeadtimeItem({
              id: leadtimes[index].id,
              multiplier: leadtimes[index].multiplier,
              leadtimeDays: leadtimes[index].leadtimeDays,
              leadtimeText: leadtimes[index].leadtimeText,
              minQuantity: leadtimes[index].minQuantity,
              maxQuantity: leadtimes[index].maxQuantity,
            });

            setUpdateLeadtimeFunc(() => (newLeadtime) => {
              setFieldValue(`leadtimes[${index}]`, newLeadtime);
              setFieldTouched(`leadtimes[${index}]`);
            });

            setLeadtimeEditorOpen(true);
          };

          // add a new leadtime
          const handleAddLeadtime = () => {
            for (let i = 0; i < BASE_LEADTIMES.length; i += 1) {
              if (!existingLeadtimes.includes(BASE_LEADTIMES[i].id)) {
                const newLeadtime = { ...BASE_LEADTIMES[i] };
                if (leadtimeType === 'days') {
                  newLeadtime.leadtimeText = null;
                } else {
                  newLeadtime.leadtimeDays = null;
                }
                setFieldValue('leadtimes', [...leadtimes, newLeadtime]);
                setFieldTouched('leadtimes');
                return;
              }
            }
          };

          // delete a leadtime
          const handleDeleteLeadtime = (index) => {
            setFieldValue('leadtimes', leadtimes.filter((item, i) => i !== index));
            setFieldTouched('leadtimes');
          };

          return (
            <TableContainer title="Leadtimes">
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <TableToolbarMenu>
                    <TableToolbarAction
                      onClick={toggleLeadtimeType}
                      disabled={disabled || isSubmitting}
                    >
                      {leadtimeType === 'days'
                        ? 'Switch to text'
                        : 'Switch to days'}
                    </TableToolbarAction>
                    <TableToolbarAction
                      onClick={handleAddLeadtime}
                      disabled={leadtimes.length >= 3 || disabled || isSubmitting}
                    >
                      Add new leadtime
                    </TableToolbarAction>
                  </TableToolbarMenu>
                  <Button
                    disabled={!touched || disabled || isSubmitting}
                    onClick={handleSubmit}
                  >
                    {isSubmitting ? 'Please wait...' : 'Save'}
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader
                        {...getHeaderProps({ header })}
                        key={header.key}
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                    <TableHeader />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, rowIndex) => (
                    <TableRow key={row.id}>
                      {row.cells.map((cell) => {
                        switch (cell.info.header) {
                          case 'id': {
                            return (
                              <TableCell key={cell.id}>
                                {{
                                  'leadtime-standard': 'Standard',
                                  'leadtime-expedited': 'Expedited',
                                  'leadtime-rush': 'Rush',
                                }[cell.value]}
                              </TableCell>
                            );
                          }
                          default: {
                            return (
                              <TableCell key={cell.id}>{cell.value}</TableCell>

                            );
                          }
                        }
                      })}
                      <TableCell key={`${row.id}:overflow`} width={32} className="bx--table-column-menu">
                        <OverflowMenu size="sm">
                          <OverflowMenuItem
                            key={`${row.id}:overflow:edit`}
                            itemText="Edit"
                            onClick={() => { handleEditLeadtime(rowIndex); }}
                            disabled={disabled || isSubmitting}
                          />
                          <OverflowMenuItem
                            key={`${row.id}:overflow:delete`}
                            isDelete
                            itemText="Delete"
                            disabled={leadtimes.length === 1 || disabled || isSubmitting}
                            onClick={() => { handleDeleteLeadtime(rowIndex); }}
                          />
                        </OverflowMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </DataTable>
    </>
  );
}

Leadtimes.propTypes = {
  leadtimes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    multiplier: PropTypes.number,
    leadtimeDays: PropTypes.number,
    leadtimeText: PropTypes.string,
    minQuantity: PropTypes.number,
    maxQuantity: PropTypes.number,
  })).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

Leadtimes.defaultProps = {
  disabled: false,
  touched: false,
};

export default Leadtimes;
