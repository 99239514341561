import {
  Accordion,
  AccordionItem,
  Dropdown,
} from 'carbon-components-react';
import React, { useCallback, useMemo } from 'react';

import AutomationControls from '../../../components/HMI/Bonding/automation';
import AutomationStage from '../../../components/HMI/Bonding/automationStage';
import HFTS from '../../../components/HMI/components/hfts';
import OverrideControls from '../../../components/HMI/components/overrideControls';
import PIDValues from '../../../components/HMI/components/pidControls';
import PLCButton from '../../../components/HMI/components/plcButton';
import PLCError from '../../../components/HMI/components/plcError';
import PLCNumberInput from '../../../components/HMI/components/plcNumberInput';
import PLCTimeInput from '../../../components/HMI/components/plcTimeInput';
import { ShutdownWarning, ThermocoupleWarning, TooHotWarning } from '../../../components/HMI/components/warnings';
import { usePLCAddress, usePressHandle, useProgramHandle } from '../../../components/HMI/hooks/context';
import { PressOrientationItems } from '../../../constants/pressOrientation';
import PLCContextProvider, { usePLCValue, usePLCWrite } from '../../../features/plc/context';

const getHandles = (pressHandle, programHandle) => [
  { name: `MAIN.${pressHandle}_Mode`, type: 'INT' },
  { name: `MAIN.${pressHandle}_Bonding_Orientation`, type: 'INT' },

  { name: `${programHandle}.InstronAudio`, type: 'BOOL' },
  { name: `${programHandle}.AirStatus`, type: 'BOOL' },
  { name: `${programHandle}.ChillerStatus`, type: 'BOOL' },

  { name: `${programHandle}.ChipSideHotHeater`, type: 'BOOL' },
  { name: `${programHandle}.ChipSideFrontHeater`, type: 'BOOL' },
  { name: `${programHandle}.ChipSideRearHeater`, type: 'BOOL' },
  { name: `${programHandle}.CapSideHotHeater`, type: 'BOOL' },
  { name: `${programHandle}.CapSideFrontHeater`, type: 'BOOL' },
  { name: `${programHandle}.CapSideRearHeater`, type: 'BOOL' },
  { name: `${programHandle}.Strain`, type: 'BOOL' },

  { name: `${programHandle}.fbCapSideBondingHFTS.bTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bHP1TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bHP2TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bHP3TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bHP4TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bHP5TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bHP6TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bToolTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bHotBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bFrontBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.bRearBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bHP1TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bHP2TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bHP3TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bHP4TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bHP5TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bHP6TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bToolTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bHotBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bFrontBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.bRearBlockTooHot`, type: 'BOOL' },

  { name: `${programHandle}.ChipSideHotCylinder`, type: 'BOOL' },
  { name: `${programHandle}.ChipSideColdCylinder`, type: 'BOOL' },
  { name: `${programHandle}.ChipSideFrontCylinder`, type: 'BOOL' },
  { name: `${programHandle}.ChipSideRearCylinder`, type: 'BOOL' },
  { name: `${programHandle}.CapSideHotCylinder`, type: 'BOOL' },
  { name: `${programHandle}.CapSideColdCylinder`, type: 'BOOL' },
  { name: `${programHandle}.CapSideFrontCylinder`, type: 'BOOL' },
  { name: `${programHandle}.CapSideRearCylinder`, type: 'BOOL' },

  { name: `${programHandle}.ChipSideToolRTD`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideHotRTD`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideFrontRTD`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideRearRTD`, type: 'LREAL' },
  { name: `${programHandle}.CapSideToolRTD`, type: 'LREAL' },
  { name: `${programHandle}.CapSideHotRTD`, type: 'LREAL' },
  { name: `${programHandle}.CapSideFrontRTD`, type: 'LREAL' },
  { name: `${programHandle}.CapSideRearRTD`, type: 'LREAL' },

  { name: `${programHandle}.CapSideHP1`, type: 'LREAL' },
  { name: `${programHandle}.CapSideHP2`, type: 'LREAL' },
  { name: `${programHandle}.CapSideHP3`, type: 'LREAL' },
  { name: `${programHandle}.CapSideHP4`, type: 'LREAL' },
  { name: `${programHandle}.CapSideHP5`, type: 'LREAL' },
  { name: `${programHandle}.CapSideHP6`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideHP1`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideHP2`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideHP3`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideHP4`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideHP5`, type: 'LREAL' },
  { name: `${programHandle}.ChipSideHP6`, type: 'LREAL' },

  { name: `${programHandle}.bProcessAutomationEnabled`, type: 'BOOL' },
  { name: `${programHandle}.bShowObservationModal`, type: 'BOOL' },
  { name: `${programHandle}.iProcessState`, type: 'INT' },

  { name: `${programHandle}.bCapSideHotBlockHeaterSystemOn`, type: 'BOOL' },
  { name: `${programHandle}.bCapSideRearHeaterSystemOn`, type: 'BOOL' },
  { name: `${programHandle}.bCapSideFrontHeaterSystemOn`, type: 'BOOL' },

  { name: `${programHandle}.fbCapSideBondingHFTS.fHotBlockSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fRearHeaterEngagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fRearHeaterDisengagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fFrontHeaterDisengagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fFrontHeaterEngagedSetpoint`, type: 'LREAL' },

  { name: `${programHandle}.bChipSideHotBlockHeaterSystemOn`, type: 'BOOL' },
  { name: `${programHandle}.bChipSideRearHeaterSystemOn`, type: 'BOOL' },
  { name: `${programHandle}.bChipSideFrontHeaterSystemOn`, type: 'BOOL' },

  { name: `${programHandle}.fbChipSideBondingHFTS.fHotBlockSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fRearHeaterEngagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fRearHeaterDisengagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fFrontHeaterDisengagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fFrontHeaterEngagedSetpoint`, type: 'LREAL' },

  { name: `${programHandle}.InstronAudio`, type: 'BOOL' },

  { name: `${programHandle}.fbCapSideBondingHFTS.fSetpointLowerBand`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fSetpointUpperBand`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fStandbyLowerBand`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fStandbyUpperBand`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fSetpointLowerBand`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fSetpointUpperBand`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fStandbyLowerBand`, type: 'LREAL' },
  { name: `${programHandle}.fbChipSideBondingHFTS.fStandbyUpperBand`, type: 'LREAL' },

  { name: `${programHandle}.fCapSideFrontLowerBandBonding`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideFrontLowerBandSuperheat`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideFrontLowerBandAnnealing`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideFrontUpperBandBonding`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideFrontUpperBandSuperheat`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideFrontUpperBandAnnealing`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideRearLowerBandBonding`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideRearLowerBandSuperheat`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideRearLowerBandAnnealing`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideRearUpperBandBonding`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideRearUpperBandSuperheat`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideRearUpperBandAnnealing`, type: 'LREAL' },

  { name: `${programHandle}.fChipSideFrontLowerBandBonding`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideFrontLowerBandSuperheat`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideFrontLowerBandAnnealing`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideFrontUpperBandBonding`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideFrontUpperBandSuperheat`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideFrontUpperBandAnnealing`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideRearLowerBandBonding`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideRearLowerBandSuperheat`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideRearLowerBandAnnealing`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideRearUpperBandBonding`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideRearUpperBandSuperheat`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideRearUpperBandAnnealing`, type: 'LREAL' },

  { name: `${programHandle}.fbCapSideBondingHFTS.eAutomationState`, type: 'INT' },
  { name: `${programHandle}.fbChipSideBondingHFTS.eAutomationState`, type: 'INT' },
  { name: `${programHandle}.sFirebaseSessionID`, type: 'STRING', size: 20 },
  { name: `${programHandle}.bOverrideCylinders`, type: 'BOOL' },
  { name: `${programHandle}.bAutoCool`, type: 'BOOL' },

  // PID configurations
  { name: `${programHandle}.stCapSideFrontHeaterEngagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stCapSideFrontHeaterEngagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stCapSideFrontHeaterEngagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stCapSideFrontHeaterEngagedParaControllerExternal.TdHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideFrontHeaterEngagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stChipSideFrontHeaterEngagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideFrontHeaterEngagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideFrontHeaterEngagedParaControllerExternal.TdHeat`, type: 'DWORD' },

  { name: `${programHandle}.stCapSideRearHeaterEngagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stCapSideRearHeaterEngagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stCapSideRearHeaterEngagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stCapSideRearHeaterEngagedParaControllerExternal.TdHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideRearHeaterEngagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stChipSideRearHeaterEngagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideRearHeaterEngagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideRearHeaterEngagedParaControllerExternal.TdHeat`, type: 'DWORD' },

  { name: `${programHandle}.stCapSideFrontHeaterDisengagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stCapSideFrontHeaterDisengagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stCapSideFrontHeaterDisengagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stCapSideFrontHeaterDisengagedParaControllerExternal.TdHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideFrontHeaterDisengagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stChipSideFrontHeaterDisengagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideFrontHeaterDisengagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideFrontHeaterDisengagedParaControllerExternal.TdHeat`, type: 'DWORD' },

  { name: `${programHandle}.stCapSideRearHeaterDisengagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stCapSideRearHeaterDisengagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stCapSideRearHeaterDisengagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stCapSideRearHeaterDisengagedParaControllerExternal.TdHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideRearHeaterDisengagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stChipSideRearHeaterDisengagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideRearHeaterDisengagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stChipSideRearHeaterDisengagedParaControllerExternal.TdHeat`, type: 'DWORD' },

  // // Shutoff timer
  { name: `${programHandle}.tShutdownTimer.ET`, type: 'DWORD' },
  { name: `${programHandle}.tShutdownTimer.PT`, type: 'DWORD' },
  { name: `${programHandle}.bShutdownOverride`, type: 'BOOL' },

  // Heatpipe maxes
  { name: `${programHandle}.fbChipSideBondingHFTS.fHPMax`, type: 'LREAL' },
  { name: `${programHandle}.fbCapSideBondingHFTS.fHPMax`, type: 'LREAL' },

  // Automation
  { name: `${programHandle}.tPresoakTime`, type: 'DWORD' },
  { name: `${programHandle}.fbPresoakTimer.ET`, type: 'DWORD' },
  { name: `${programHandle}.tBondTime`, type: 'DWORD' },
  { name: `${programHandle}.fbBondTimer.ET`, type: 'DWORD' },
  { name: `${programHandle}.tSuperheatWaitTime`, type: 'DWORD' },
  { name: `${programHandle}.fbSuperheatWaitTimer.ET`, type: 'DWORD' },
  { name: `${programHandle}.tAnnealingWaitTime`, type: 'DWORD' },
  { name: `${programHandle}.fbAnnealingWaitTimer.ET`, type: 'DWORD' },

  { name: `${programHandle}.fCapSideLoadingTemp`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideLoadingTempDisengaged`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideLoadingTemp`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideLoadingTempDisengaged`, type: 'LREAL' },

  { name: `${programHandle}.fCapSideSetpointTolerance`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideSetpointTolerance`, type: 'LREAL' },

  { name: `${programHandle}.fCapSideBondingTemp`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideBondingTempDisengaged`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideBondingTemp`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideBondingTempDisengaged`, type: 'LREAL' },

  { name: `${programHandle}.fChipSideAnnealingTemp`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideAnnealingTempDisengaged`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideAnnealingTemp`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideAnnealingTempDisengaged`, type: 'LREAL' },

  { name: `${programHandle}.fChipSideSuperheatTemp`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideSuperheatTempDisengaged`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideSuperheatTolerance`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideSuperheatTemp`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideSuperheatTempDisengaged`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideSuperheatTolerance`, type: 'LREAL' },

  { name: `${programHandle}.fChipSideFinalTemp`, type: 'LREAL' },
  { name: `${programHandle}.fChipSideFinalTempDisengaged`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideFinalTemp`, type: 'LREAL' },
  { name: `${programHandle}.fCapSideFinalTempDisengaged`, type: 'LREAL' },

  { name: `${programHandle}.bInstronManualOverride`, type: 'BOOL' },

  { name: `${pressHandle}.Vacuum`, type: 'BOOL' },
];

function BondingHMI() {
  const pressHandle = usePressHandle();
  const programHandle = useProgramHandle();
  const plcAddress = usePLCAddress(pressHandle);
  const plcWrite = usePLCWrite();

  const orientation = usePLCValue(`MAIN.${pressHandle}_Bonding_Orientation`);
  const orientationItem = useMemo(() => {
    const item = PressOrientationItems.find((i) => i.id === orientation);
    return item || PressOrientationItems[0];
  }, [orientation]);
  const changeOrientation = useCallback(({ selectedItem }) => {
    plcWrite({ handle: `MAIN.${pressHandle}_Bonding_Orientation`, value: selectedItem.id });
  }, [orientation, pressHandle, plcWrite]);

  const handles = useMemo(
    () => getHandles(pressHandle, programHandle),
    [pressHandle, programHandle],
  );

  return (
    <PLCContextProvider url={plcAddress} handles={handles}>
      <div className="bx--row">
        <div className="bx--col">
          <AutomationStage />
        </div>
        <div className="bx--col">
          <div style={{ float: 'right', display: 'flex' }}>
            <Dropdown
              id="orientation"
              label="orientation"
              selectedItem={orientationItem}
              items={PressOrientationItems}
              itemToString={(item) => (item ? item.text : '')}
              onChange={changeOrientation}
              style={{ marginRight: '1rem', minWidth: 160 }}
            />
            <PLCButton
              kind="danger"
              size="sm"
              onClick={(write) => { write({ handle: `MAIN.${pressHandle}_Mode`, value: 0 }); }}
            >
              Disable bonding mode
            </PLCButton>
          </div>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <PLCError />
          <ShutdownWarning />
          <ThermocoupleWarning />
          <TooHotWarning />
        </div>
      </div>
      <AutomationControls />
      <Accordion>
        <style>
          {`.bx--accordion__content {
          padding-right: 0;
        }`}
        </style>
        <AccordionItem title="Automation Config">
          <div className="bx--row">
            <div className="bx--col">
              <PLCTimeInput label="Presoak Time" handle={`${programHandle}.tPresoakTime`} secondsOnly />
              <PLCTimeInput label="Bond Time" handle={`${programHandle}.tBondTime`} secondsOnly />
              <PLCTimeInput label="Superheat Wait Time" handle={`${programHandle}.tSuperheatWaitTime`} secondsOnly />
              <PLCTimeInput label="Annealing Wait Time" handle={`${programHandle}.tAnnealingWaitTime`} secondsOnly />
            </div>
            <div className="bx--col">
              <div className="bx--row">
                <div className="bx--col">
                  <PLCNumberInput
                    label="Chip Side Loading Temperature"
                    handle={`${programHandle}.fChipSideLoadingTemp`}
                    type="float"
                  />
                </div>
                <div className="bx--col">
                  <PLCNumberInput
                    label="(Disengaged Temp)"
                    handle={`${programHandle}.fChipSideLoadingTempDisengaged`}
                    type="float"
                  />
                </div>
              </div>
              <div className="bx--row">
                <div className="bx--col">
                  <PLCNumberInput
                    label="Cap Side Loading Temperature"
                    handle={`${programHandle}.fCapSideLoadingTemp`}
                    type="float"
                  />
                </div>
                <div className="bx--col">
                  <PLCNumberInput
                    label="(Disengaged Temp)"
                    handle={`${programHandle}.fCapSideLoadingTempDisengaged`}
                    type="float"
                  />
                </div>
              </div>
              <div className="bx--row">
                <div className="bx--col">
                  <PLCNumberInput
                    label="Cap Side Bonding Temp"
                    handle={`${programHandle}.fCapSideBondingTemp`}
                    type="float"
                  />
                </div>
                <div className="bx--col">
                  <PLCNumberInput
                    label="(Disengaged Temp)"
                    handle={`${programHandle}.fCapSideBondingTempDisengaged`}
                    type="float"
                  />
                </div>
              </div>
              <div className="bx--row">
                <div className="bx--col">
                  <PLCNumberInput
                    label="Cap Side Setpoint Tolerance Band"
                    handle={`${programHandle}.fCapSideSetpointTolerance`}
                    type="float"
                  />
                </div>
              </div>
              <div className="bx--row">
                <div className="bx--col">
                  <PLCNumberInput
                    label="Chip Side Bonding Temp"
                    handle={`${programHandle}.fChipSideBondingTemp`}
                    type="float"
                  />
                </div>
                <div className="bx--col">
                  <PLCNumberInput
                    label="(Disengaged Temp)"
                    handle={`${programHandle}.fChipSideBondingTempDisengaged`}
                    type="float"
                  />
                </div>
              </div>
              <div className="bx--row">
                <div className="bx--col">
                  <PLCNumberInput
                    label="Chip Side Setpoint Tolerance Band"
                    handle={`${programHandle}.fChipSideSetpointTolerance`}
                    type="float"
                  />
                </div>
              </div>
            </div>
            <div className="bx--col">
              <div className="bx--row">
                <div className="bx--col">
                  <PLCNumberInput
                    label="Chip Side Top Superheat Temperature"
                    handle={`${programHandle}.fChipSideSuperheatTemp`}
                    type="float"
                  />
                </div>
                <div className="bx--col">
                  <PLCNumberInput
                    label="(Disengaged Temp)"
                    handle={`${programHandle}.fChipSideSuperheatTempDisengaged`}
                    type="float"
                  />
                </div>
              </div>
              <div className="bx--row">
                <div className="bx--col">
                  <PLCNumberInput
                    label="Chip Side Superheat Tolerance Band"
                    handle={`${programHandle}.fChipSideSuperheatTolerance`}
                    type="float"
                  />
                </div>
              </div>
              <div className="bx--row">
                <div className="bx--col">
                  <PLCNumberInput
                    label="Cap Side Superheat Temperature"
                    handle={`${programHandle}.fCapSideSuperheatTemp`}
                    type="float"
                  />
                </div>
                <div className="bx--col">
                  <PLCNumberInput
                    label="(Disengaged Temp)"
                    handle={`${programHandle}.fCapSideSuperheatTempDisengaged`}
                    type="float"
                  />
                </div>
              </div>
              <div className="bx--row">
                <div className="bx--col">
                  <PLCNumberInput
                    label="Cap Side Superheat Tolerance Band"
                    handle={`${programHandle}.fCapSideSuperheatTolerance`}
                    type="float"
                  />
                </div>
              </div>
              <div className="bx--row">
                <div className="bx--col">
                  <PLCNumberInput
                    label="Chip Side Annealing Temperature"
                    handle={`${programHandle}.fChipSideAnnealingTemp`}
                    type="float"
                  />
                </div>
                <div className="bx--col">
                  <PLCNumberInput
                    label="(Disengaged Temp)"
                    handle={`${programHandle}.fChipSideAnnealingTempDisengaged`}
                    type="float"
                  />
                </div>
              </div>
              <div className="bx--row">
                <div className="bx--col">
                  <PLCNumberInput
                    label="Cap Side Annealing Temperature"
                    handle={`${programHandle}.fCapSideAnnealingTemp`}
                    type="float"
                  />
                </div>
                <div className="bx--col">
                  <PLCNumberInput
                    label="(Disengaged Temp)"
                    handle={`${programHandle}.fCapSideAnnealingTempDisengaged`}
                    type="float"
                  />
                </div>
              </div>
              <div className="bx--row">
                <div className="bx--col">
                  <PLCNumberInput
                    label="Chip Side Final Temperature"
                    handle={`${programHandle}.fChipSideFinalTemp`}
                    type="float"
                  />
                </div>
                <div className="bx--col">
                  <PLCNumberInput
                    label="(Disengaged Temp)"
                    handle={`${programHandle}.fChipSideFinalTempDisengaged`}
                    type="float"
                  />
                </div>
              </div>
              <div className="bx--row">
                <div className="bx--col">
                  <PLCNumberInput
                    label="Cap Side Final Temperature"
                    handle={`${programHandle}.fCapSideFinalTemp`}
                    type="float"
                  />
                </div>
                <div className="bx--col">
                  <PLCNumberInput
                    label="(Disengaged Temp)"
                    handle={`${programHandle}.fCapSideFinalTempDisengaged`}
                    type="float"
                  />
                </div>
              </div>
            </div>
          </div>
        </AccordionItem>
        <AccordionItem title="Cylinder Swap Config">
          <div className="bx--row">
            <div className="bx--col">
              <h4>Bonding</h4>
              <PLCNumberInput
                label="Cap Side Front Lower Band"
                handle={`${programHandle}.fCapSideFrontLowerBandBonding`}
                type="float"
              />
              <PLCNumberInput
                label="Cap Side Front Upper Band"
                handle={`${programHandle}.fCapSideFrontUpperBandBonding`}
                type="float"
              />
              <PLCNumberInput
                label="Cap Side Rear Lower Band"
                handle={`${programHandle}.fCapSideRearLowerBandBonding`}
                type="float"
              />
              <PLCNumberInput
                label="Cap Side Rear Upper Band"
                handle={`${programHandle}.fCapSideRearUpperBandBonding`}
                type="float"
              />
              <PLCNumberInput
                label="Chip Side Front Lower Band"
                handle={`${programHandle}.fChipSideFrontLowerBandBonding`}
                type="float"
              />
              <PLCNumberInput
                label="Chip Side Front Upper Band"
                handle={`${programHandle}.fChipSideFrontUpperBandBonding`}
                type="float"
              />
              <PLCNumberInput
                label="Chip Side Rear Lower Band"
                handle={`${programHandle}.fChipSideRearLowerBandBonding`}
                type="float"
              />
              <PLCNumberInput
                label="Chip Side Rear Upper Band"
                handle={`${programHandle}.fChipSideRearUpperBandBonding`}
                type="float"
              />
            </div>
            <div className="bx--col">
              <h4>Superheat</h4>
              <PLCNumberInput
                label="Cap Side Front Lower Band"
                handle={`${programHandle}.fCapSideFrontLowerBandSuperheat`}
                type="float"
              />
              <PLCNumberInput
                label="Cap Side Front Upper Band"
                handle={`${programHandle}.fCapSideFrontUpperBandSuperheat`}
                type="float"
              />
              <PLCNumberInput
                label="Cap Side Rear Lower Band"
                handle={`${programHandle}.fCapSideRearLowerBandSuperheat`}
                type="float"
              />
              <PLCNumberInput
                label="Cap Side Rear Upper Band"
                handle={`${programHandle}.fCapSideRearUpperBandSuperheat`}
                type="float"
              />
              <PLCNumberInput
                label="Chip Side Front Lower Band"
                handle={`${programHandle}.fChipSideFrontLowerBandSuperheat`}
                type="float"
              />
              <PLCNumberInput
                label="Chip Side Front Upper Band"
                handle={`${programHandle}.fChipSideFrontUpperBandSuperheat`}
                type="float"
              />
              <PLCNumberInput
                label="Chip Side Rear Lower Band"
                handle={`${programHandle}.fChipSideRearLowerBandSuperheat`}
                type="float"
              />
              <PLCNumberInput
                label="Chip Side Rear Upper Band"
                handle={`${programHandle}.fChipSideRearUpperBandSuperheat`}
                type="float"
              />
            </div>
            <div className="bx--col">
              <h4>Annealing</h4>
              <PLCNumberInput
                label="Cap Side Front Lower Band"
                handle={`${programHandle}.fCapSideFrontLowerBandAnnealing`}
                type="float"
              />
              <PLCNumberInput
                label="Cap Side Front Upper Band"
                handle={`${programHandle}.fCapSideFrontUpperBandAnnealing`}
                type="float"
              />
              <PLCNumberInput
                label="Cap Side Rear Lower Band"
                handle={`${programHandle}.fCapSideRearLowerBandAnnealing`}
                type="float"
              />
              <PLCNumberInput
                label="Cap Side Rear Upper Band"
                handle={`${programHandle}.fCapSideRearUpperBandAnnealing`}
                type="float"
              />
              <PLCNumberInput
                label="Chip Side Front Lower Band"
                handle={`${programHandle}.fChipSideFrontLowerBandAnnealing`}
                type="float"
              />
              <PLCNumberInput
                label="Chip Side Front Upper Band"
                handle={`${programHandle}.fChipSideFrontUpperBandAnnealing`}
                type="float"
              />
              <PLCNumberInput
                label="Chip Side Rear Lower Band"
                handle={`${programHandle}.fChipSideRearLowerBandAnnealing`}
                type="float"
              />
              <PLCNumberInput
                label="Chip Side Rear Upper Band"
                handle={`${programHandle}.fChipSideRearUpperBandAnnealing`}
                type="float"
              />
            </div>
          </div>
        </AccordionItem>
        <AccordionItem title="Override controls">
          <OverrideControls side1="CapSide" side2="ChipSide" />
        </AccordionItem>
        <AccordionItem title="PID controls">
          <div className="bx--row">
            <PIDValues sideHandle="CapSide" heaterHandle="Front" engaged />
            <PIDValues sideHandle="ChipSide" heaterHandle="Front" engaged />
          </div>
          <div className="bx--row">
            <PIDValues sideHandle="CapSide" heaterHandle="Rear" engaged />
            <PIDValues sideHandle="ChipSide" heaterHandle="Rear" engaged />
          </div>
          <div className="bx--row">
            <PIDValues sideHandle="CapSide" heaterHandle="Front" engaged={false} />
            <PIDValues sideHandle="ChipSide" heaterHandle="Front" engaged={false} />
          </div>
          <div className="bx--row">
            <PIDValues sideHandle="CapSide" heaterHandle="Rear" engaged={false} />
            <PIDValues sideHandle="ChipSide" heaterHandle="Rear" engaged={false} />
          </div>
        </AccordionItem>
        <AccordionItem title="Chip Side Controls">
          <div className="bx--row">
            <div className="bx--col">
              <HFTS side="ChipSide" />
            </div>
          </div>
        </AccordionItem>
        <AccordionItem title="Cap Side Controls">
          <div className="bx--row">
            <div className="bx--col">
              <HFTS side="CapSide" />
            </div>
          </div>
        </AccordionItem>
      </Accordion>
    </PLCContextProvider>
  );
}

export default BondingHMI;
