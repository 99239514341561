import {
  FormField,
  Input,
} from '@cloudscape-design/components';
import { PropTypes } from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  usePLCReady,
  usePLCValue,
  usePLCWrite,
} from '../../../features/plc/context';

function PLCNumberInput({
  label,
  description,
  handle,
  disabled,
  type,
  writeSideEffect,
}) {
  const ready = usePLCReady();

  const initialValue = usePLCValue(handle);
  const [currentValue, setCurrentValue] = useState(0);
  useEffect(() => {
    if (initialValue !== undefined) {
      setCurrentValue(initialValue);
    }
  }, [initialValue]);

  const plcWrite = usePLCWrite();
  const write = useCallback((value) => {
    plcWrite({ handle, value });
    writeSideEffect({ handle, value, write: plcWrite });
  }, [plcWrite, writeSideEffect, handle]);

  return (
    <FormField
      label={label}
      description={description}
      errorText={currentValue !== initialValue ? 'Unsaved changes (click away to save)' : null}
      stretch
    >
      <Input
        step={1}
        inputMode="numeric"
        type="number"
        value={currentValue}
        disabled={!ready || disabled}
        onChange={({ detail }) => { setCurrentValue(detail.value); }}
        onBlur={() => { write(type === 'int' ? parseInt(currentValue, 10) : parseFloat(currentValue)); }}
      />
    </FormField>
  );
}

PLCNumberInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handle: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['int', 'float']).isRequired,
  writeSideEffect: PropTypes.func,
};

PLCNumberInput.defaultProps = {
  writeSideEffect: () => {},
  description: '',
  disabled: false,
};

export default PLCNumberInput;
