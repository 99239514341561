/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  TextInput,
} from 'carbon-components-react';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { selectQuote } from '../../../features/parts/partsSlice';

const schema = Yup.object().shape({
  uncappedSetupPrice: Yup.number().required().min(0),
  cappedSetupPrice: Yup.number().required().min(0),
});

function SetupFees({
  partID,
}) {
  const { id: quoteID, hasPendingWrites, pricingV2 } = useSelector(selectQuote(partID));
  const initialValues = {
    cappedSetupPrice: pricingV2.setupPrice.capped,
    uncappedSetupPrice: pricingV2.setupPrice.uncapped,
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await updateDoc(doc(getFirestore(), 'quotes', quoteID), {
            pricingV2: {
              ...pricingV2,
              setupPrice: {
                capped: parseFloat(values.cappedSetupPrice),
                uncapped: parseFloat(values.uncappedSetupPrice),
              },
            },
          });
        } catch (error) {
          console.error(error);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        values, errors, handleBlur, handleChange, handleSubmit,
      }) => {
        const unsavedChanges = (
          parseFloat(values.cappedSetupPrice) !== parseFloat(initialValues.cappedSetupPrice)
          || parseFloat(values.uncappedSetupPrice) !== parseFloat(initialValues.uncappedSetupPrice)
        );
        return (
          <>
            <div className="bx--row">
              <div className="bx--col">
                <TextInput
                  labelText="Uncapped setup price"
                  id="uncappedSetupPrice"
                  key="uncappedSetupPrice"
                  value={values.uncappedSetupPrice}
                  disabled={hasPendingWrites}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  invalidText={errors.uncappedSetupPrice}
                  invalid={!!errors.uncappedSetupPrice}
                />
              </div>
            </div>
            <div className="bx--row">
              <div className="bx--col">
                <TextInput
                  labelText="Capped setup price"
                  id="cappedSetupPrice"
                  key="cappedSetupPrice"
                  value={values.cappedSetupPrice}
                  disabled={hasPendingWrites}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  invalidText={errors.cappedSetupPrice}
                  invalid={!!errors.cappedSetupPrice}
                />
              </div>
            </div>
            <div className="bx--row">
              <div className="bx--col">
                <Button
                  disabled={!unsavedChanges || hasPendingWrites}
                  onClick={handleSubmit}
                >
                  {hasPendingWrites ? 'Upadating...' : 'Save'}
                </Button>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
}

SetupFees.propTypes = {
  partID: PropTypes.string.isRequired,
};

export default SetupFees;
