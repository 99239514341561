import { Launch32 } from '@carbon/icons-react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  CodeSnippet,
  InlineNotification,
} from 'carbon-components-react';
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, Navigate, Outlet, useLocation, useNavigate, useParams,
} from 'react-router-dom';

import FirebaseContext from '../../app/firebase';
import CADPreview from '../../components/CADPreview';
import CADStudio from '../../components/CADStudio';
import Feedback from '../../components/Feedback';
import OriginalFile from '../../components/OriginalFile';
import Pricing, { V1Pricing, V2Pricing } from '../../components/Pricing';
import QuoteRequest from '../../components/QuoteRequest';
import QuoteStatus from '../../components/QuoteStatus';
import QuoteStatusTag from '../../components/QuoteStatusTag';
import TransferPart from '../../components/TransferPart';
import UserEmail from '../../components/UserEmail';
import { setFeedbackChecklistItems } from '../../features/feedbackChecklist';
import {
  removePart,
  removeQuote,
  selectPart,
  selectQuote,
  setPart,
  setQuote,
} from '../../features/parts/partsSlice';

function Part() {
  const { partID } = useParams();
  const { quote: quoteID } = useSelector(selectPart(partID));
  const part = useSelector(selectPart(partID));
  const { feedbackChecklistVersion } = useSelector(selectQuote(partID));

  const dispatch = useDispatch();

  // subscribe to part updates
  useEffect(() => {
    if (!partID) {
      return () => {};
    }
    const unsubscribe = onSnapshot(doc(getFirestore(), 'parts', partID), { includeMetadataChanges: true }, (snapshot) => {
      dispatch(setPart({
        id: partID,
        hasPendingWrites: snapshot.metadata.hasPendingWrites,
        ...snapshot.data(),
      }));
    });
    return () => {
      unsubscribe();
      dispatch(removePart({ id: partID }));
    };
  }, [partID]);

  // subscribe to quote updates
  useEffect(() => {
    if (!quoteID) {
      return () => {};
    }
    const unsubscribe = onSnapshot(doc(getFirestore(), 'quotes', quoteID), { includeMetadataChanges: true }, (snapshot) => {
      dispatch(setQuote({
        id: quoteID,
        hasPendingWrites: snapshot.metadata.hasPendingWrites,
        ...snapshot.data(),
      }));
    });
    return () => {
      unsubscribe();
      dispatch(removeQuote({ id: quoteID }));
    };
  }, [quoteID]);

  useEffect(() => {
    if (!feedbackChecklistVersion) {
      return;
    }
    (async () => {
      const feedbackChecklistRef = collection(getFirestore(), 'feedback_checklist');
      const q = query(feedbackChecklistRef, where('version', '==', feedbackChecklistVersion));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size !== 1) {
        dispatch(setFeedbackChecklistItems({}));
      }
      querySnapshot.docs.forEach((checklistDoc) => {
        dispatch(setFeedbackChecklistItems(checklistDoc.data()));
      });
    })();
  }, [feedbackChecklistVersion]);

  // subpage navigation via tabs
  const { pathname } = useLocation();
  const subpage = pathname.split('/').pop();
  const tabs = [
    { title: 'Overview', key: 'overview' },
    { title: 'CAD', key: 'cad' },
    { title: 'CAD Studio', key: 'cad-studio' },
    { title: 'Feedback', key: 'feedback' },
    { title: 'Pricing', key: 'pricing' },
    { title: '👨🏻‍💻 Special Pricing', key: 'pricing-secret', restrictedTo: ['andy@parallelfluidics.com'] },
  ];
  const currentTabIndex = tabs.map(({ key }) => key).indexOf(subpage);
  const navigate = useNavigate();

  // check user for v1 pricing
  const { user } = useContext(FirebaseContext);
  const userEmail = user.email;

  if (!tabs.map(({ key }) => key).includes(subpage)) {
    return <Navigate to={`/part/${partID}/overview`} replace />;
  }

  return (
    <div id="part-page">
      <div className="bx--row">
        <div className="bx--col">
          <h1>{`Quote #${partID.substring(0, 6).toUpperCase()}`}</h1>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            {part && part.uid ? (
              <BreadcrumbItem>
                <Link to={`/user/${part.uid}`}>
                  <UserEmail userID={part.uid} />
                  {'\'s parts'}
                </Link>
              </BreadcrumbItem>
            ) : (
              <BreadcrumbItem>Unknown user</BreadcrumbItem>
            )}
            <BreadcrumbItem>{`Quote #${partID.substring(0, 6).toUpperCase()}`}</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          Quote status:
          {' '}
          <QuoteStatusTag partID={partID} />
        </div>
      </div>
      {part.email ? (
        <div className="bx--row">
          <div className="bx--col">
            <InlineNotification
              kind="info"
              title="RFQ submitted anonymously."
              subtitle={`The user submitted this RFQ without an account and indicated that their email is ${part.email}. When Parascope sends an email to this user, it will contain an account creation link where they will be prompted to set a password. If they need a new link, you can generate one on their account page (click their email in the breadcrumb menu above).`}
              hideCloseButton
              lowContrast
              style={{ minWidth: '100%', width: '100%' }}

            />
          </div>
        </div>
      ) : null}
      <div className="bx--row action-tray">
        <div className="bx--col">
          <QuoteStatus partID={partID} />
          <Button
            kind="secondary"
            renderIcon={Launch32}
            onClick={() => { window.open(`https://parallelfluidics.com/parts/view?quote=${partID}`, '_blank'); }}
          >
            View live quote
          </Button>
          <TransferPart partID={partID} />
        </div>
      </div>
      <div className="bx--tabs--scrollable">
        <ul role="tablist" tabIndex={-1} className="bx--tabs--scrollable__nav">
          {tabs.map(({ title, key, restrictedTo }, index) => {
            if (restrictedTo && !restrictedTo.includes(userEmail)) {
              return null;
            }
            return (
              <li
                key={key}
                className={currentTabIndex === index
                  ? 'bx--tabs--scrollable__nav-item bx--tabs__nav-item--selected bx--tabs--scrollable__nav-item--selected'
                  : 'bx--tabs--scrollable__nav-item'}
                role="presentation"
              >
                <button
                  type="button"
                  role="tab"
                  aria-selected={currentTabIndex === index}
                  className="bx--tabs--scrollable__nav-link"
                  onClick={() => {
                    navigate(`/part/${partID}/${key}`);
                  }}
                  tabIndex={-1}
                >
                  {title}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
      <div role="tabpanel" className="bx--tab-content">
        <Outlet />
      </div>
    </div>
  );
}

function OverviewSection() {
  const { partID } = useParams();
  return (
    <div className="bx--row action-tray">
      <div className="bx--col">
        <QuoteRequest partID={partID} />
      </div>
    </div>
  );
}

function FeedbackSection() {
  const { partID } = useParams();
  return (
    <div className="bx--row">
      <div className="bx--col" style={{ marginBottom: '1.5rem' }}>
        <h4 style={{ marginBottom: '1rem' }}>Feedback</h4>
        <Feedback partID={partID} />
      </div>
    </div>
  );
}

function CADInfo() {
  const { partID } = useParams();
  return (
    <div>
      <div className="bx--row">
        <div className="bx--col">
          <CADPreview partID={partID} />
        </div>
        <div className="bx--col">
          <OriginalFile partID={partID} />
        </div>
      </div>
    </div>
  );
}

function CADStudioSection() {
  const { partID } = useParams();
  return <CADStudio partID={partID} />;
}

function PricingSection() {
  const { partID } = useParams();
  const {
    pricingVersion, pricingV3, pricingV2, rates,
  } = useSelector(selectQuote(partID));

  switch (pricingVersion) {
    case 'v3': {
      return <Pricing partID={partID} />;
    }
    case 'v2': {
      return <V2Pricing partID={partID} />;
    }
    default: {
      return (
        <>
          <div className="bx--row">
            <div className="bx--col">
              <h4>
                This quote uses an outdated or custom pricing scheme (
                <code>{(pricingVersion === 'v1' ? 'Special Pricing' : pricingVersion) || 'v0'}</code>
                ) and can&#39;t be updated. Ask
                {' '}
                <a href="https://slack.com/app_redirect?channel=UT9HGAKC4" target="_blank" rel="noreferrer">Andy</a>
                {' '}
                if you need help.
              </h4>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <p>
                Below is the original source data.
                Keep in mind that that for parts with custom pricing,
                this data is not used to calculate the quote price.
              </p>
            </div>
          </div>
          {pricingV3 ? (
            <Pricing partID={partID} disabled />
          ) : (
            <div className="bx--row">
              <div className="bx--col">
                <CodeSnippet type="multi">{JSON.stringify(pricingV2 || rates, null, 2)}</CodeSnippet>
              </div>
            </div>
          )}
        </>
      );
    }
  }
}

function V1PricingSection() {
  const { partID } = useParams();
  return (
    <V1Pricing partID={partID} />
  );
}

export {
  CADInfo,
  CADStudioSection,
  FeedbackSection,
  OverviewSection,
  PricingSection,
  V1PricingSection,
};

export default Part;
