import {
  Modal,
  Select,
  SelectItem,
} from 'carbon-components-react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import {
  selectClockifyProjects,
  selectClockifyTags,
  startMyClockifyTimer,
} from '../../features/clockify/clockifySlice';

function TaskPicker({ isOpen, close, tagID }) {
  const dispatch = useDispatch();
  const clockifyProjects = useSelector(selectClockifyProjects);
  const clockifyTags = useSelector(selectClockifyTags);

  if (typeof document === 'undefined') {
    return null;
  }

  const initialValues = {
    projectID: '',
    tagID,
  };

  const validationSchema = Yup.object().shape({
    projectID: Yup.string().required('Project is required'),
    tagID: Yup.string().required('Task is required'),
  });

  return (
    ReactDOM.createPortal(
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        enableReinitialize
        onSubmit={async (values) => {
          dispatch(startMyClockifyTimer(values));
          close();
        }}
      >
        {({
          values, handleChange, handleBlur, handleSubmit, isSubmitting, errors,
        }) => (
          <Modal
            size="lg"
            open={isOpen}
            onRequestClose={close}
            modalHeading="Start timer"
            primaryButtonText="Start"
            secondaryButtonText="Cancel"
            onRequestSubmit={handleSubmit}
            primaryButtonDisabled={isSubmitting}
          >
            <div className="bx--row">
              <div className="bx--col">
                <Select
                  id="projectID"
                  name="projectID"
                  disabled={isSubmitting}
                  labelText="Project"
                  helperText={(
                    <span>
                      {'See an error? Fix it in  '}
                      <a target="_blank" href="https://app.clockify.me/projects" rel="noreferrer">Clockify</a>
                      .
                    </span>
                  )}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.projectID}
                  invalid={!!errors.projectID}
                  invalidText={errors.projectID}
                >
                  <SelectItem
                    text="Select a project"
                    value=""
                    disabled
                  />
                  {clockifyProjects.map((project) => (
                    <SelectItem
                      key={project.id}
                      text={project.name}
                      value={project.id}
                    />
                  ))}
                </Select>
              </div>
            </div>
            <div className="bx--row">
              <div className="bx--col">
                <Select
                  id="tagID"
                  name="tagID"
                  disabled={isSubmitting}
                  labelText="Station"
                  helperText={(
                    <span>
                      {'See an error? Fix it in  '}
                      <a target="_blank" href="https://app.clockify.me/projects" rel="noreferrer">Clockify</a>
                      .
                    </span>
                  )}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tagID}
                  invalid={!!errors.tagID}
                  invalidText={errors.tagID}
                >
                  <SelectItem
                    text="Select a station"
                    value=""
                    disabled
                  />
                  {clockifyTags.map((tag) => (
                    <SelectItem
                      key={tag.id}
                      text={tag.name}
                      value={tag.id}
                    />
                  ))}
                </Select>
              </div>
            </div>
          </Modal>
        )}
      </Formik>,
      document.body,
    )
  );
}

TaskPicker.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  tagID: PropTypes.string,
};

TaskPicker.defaultProps = {
  tagID: '',
};

export default TaskPicker;
