/* eslint-disable react/default-props-match-prop-types */
import { Select, SelectItem, TextInput } from 'carbon-components-react';
import PropTypes from 'prop-types';
import React from 'react';

const baseProps = {
  attribute: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['select', 'number']).isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      isCustom: PropTypes.bool,
      quantity: PropTypes.number,
    }),
  ]).isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

function NumberAttribute({
  attribute,
  value,
  error,
  disabled,
  setFieldValue,
  onChange: handleChange,
  onBlur: handleBlur,
}) {
  return (
    <>
      <div className="bx--row" style={{ marginBottom: 0 }}>
        <div className="bx--col">
          <p className="bx--label">How many components per device?</p>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Select
            id={`attributes.${attribute.title}.value.pricingType`}
            noLabel
            onChange={(e) => {
              if (e.target.value === 'custom') {
                setFieldValue(`attributes.${attribute.title}.value.quantity`, 0);
              }
              setFieldValue(`attributes.${attribute.title}.setup`, 0);
              setFieldValue(`attributes.${attribute.title}.unit`, 0);
              handleChange(e);
            }}
            onBlur={handleBlur}
            value={value.pricingType}
            invalid={!!error?.pricingType}
            invalidText={error?.pricingType}
            disabled={disabled}
          >
            <SelectItem label="Default" value="default" />
            <SelectItem label="Custom" value="custom" />
          </Select>
        </div>
        {value.pricingType === 'custom' ? null : (
          <div className="bx--col">
            <TextInput
              id={`attributes.${attribute.title}.value.quantity`}
              labelText=""
              onChange={(e) => {
                let quantity = 0;
                switch (attribute.kind) {
                  case 'integer':
                    quantity = parseInt(e.target.value, 10);
                    break;
                  case 'float':
                    quantity = parseFloat(e.target.value);
                    break;
                  default:
                    break;
                }
                if (Number.isNaN(quantity)) {
                  quantity = 0;
                }
                if (quantity > 0) {
                  setFieldValue(`attributes.${attribute.title}.setup`, attribute.value.setup);
                } else {
                  setFieldValue(`attributes.${attribute.title}.setup`, 0);
                }
                setFieldValue(`attributes.${attribute.title}.unit`, attribute.value.unit * quantity);
                setFieldValue(`attributes.${attribute.title}.value.quantity`, quantity);
              }}
              onBlur={handleBlur}
              value={value.quantity}
              invalid={!!error?.quantity}
              invalidText={error?.quantity}
              disabled={disabled}
            />
          </div>
        )}
      </div>
    </>
  );
}

NumberAttribute.propTypes = {
  ...baseProps,
  attribute: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['number']).isRequired,
    kind: PropTypes.oneOf(['integer', 'float']).isRequired,
  }).isRequired,
  value: PropTypes.shape({
    pricingType: PropTypes.oneOf(['default', 'custom']).isRequired,
    quantity: PropTypes.number,
  }).isRequired,
};

NumberAttribute.defaultProps = {
  error: null,
  disabled: false,
};

function SelectAttribute({
  attribute,
  value,
  error,
  disabled,
  setFieldValue,
  onChange: handleChange,
  onBlur: handleBlur,
}) {
  return (
    <Select
      id={`attributes.${attribute.title}.value`}
      labelText={attribute.title}
      onChange={(e) => {
        const option = attribute.options.find((o) => o.title === e.target.value);
        if (option) {
          setFieldValue(`attributes.${attribute.title}.setup`, option.setup);
          setFieldValue(`attributes.${attribute.title}.unit`, option.unit);
        }
        handleChange(e);
      }}
      onBlur={handleBlur}
      value={value}
      invalid={!!error}
      invalidText={error}
      disabled={disabled}
    >
      <SelectItem key="PLACEHOLDER-ITEM" label="Please select" value="PLACEHOLDER-ITEM" disabled />
      {attribute.options.map((option) => (
        <SelectItem key={option.title} label={option.title} value={option.title} />
      ))}
      <SelectItem key="custom" label="Custom Price" value="custom" />
    </Select>
  );
}

SelectAttribute.propTypes = {
  ...baseProps,
  attribute: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['select']).isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        setup: PropTypes.number.isRequired,
        unit: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  value: PropTypes.string.isRequired,
};

SelectAttribute.defaultProps = {
  error: null,
  disabled: false,
};

function Attribute({
  attribute,
  value,
  error,
  disabled,
  setFieldValue,
  onChange: handleChange,
  onBlur: handleBlur,
}) {
  switch (attribute.type) {
    case 'select':
      return (
        <SelectAttribute
          attribute={attribute}
          value={value}
          error={error}
          disabled={disabled}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      );
    case 'number':
      return (
        <NumberAttribute
          attribute={attribute}
          value={value}
          error={error}
          disabled={disabled}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      );
    default:
      return null;
  }
}

Attribute.propTypes = baseProps;

Attribute.defaultProps = {
  error: null,
  disabled: false,
};

const isCustom = (attribute, value) => {
  if (attribute.type === 'number') {
    return value.pricingType === 'custom';
  }
  return value === 'custom';
};

export default Attribute;
export { isCustom };
