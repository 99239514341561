import {
  Button,
  ProgressIndicator,
  ProgressStep,
  Tooltip,
} from 'carbon-components-react';
import {
  doc,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectFeedbackChecklist, setIsComplete } from '../../features/feedbackChecklist';
import { selectQuote } from '../../features/parts/partsSlice';

export default function Checklist({
  addFeedback,
  currentFeedbackCategories,
  partID,
}) {
  const [currentStep, setCurrentStep] = useState(0);

  // get the checklist from for this quote
  const dispatch = useDispatch();
  const checklist = useSelector(selectFeedbackChecklist);

  // get maps of the catories for which feedback has been added
  // and which have been passed
  const currentCategoriesMap = useMemo(() => {
    const map = {};
    currentFeedbackCategories.forEach((category) => {
      map[category] = true;
    });
    return map;
  }, [currentFeedbackCategories]);
  const {
    id: quoteID,
    feedbackChecklistPasses,
  } = useSelector(selectQuote(partID));
  const passedCategoriesMap = useMemo(
    () => feedbackChecklistPasses || {},
    [feedbackChecklistPasses],
  );

  // get the next step based on which categories have been passed
  useEffect(() => {
    for (let i = 0; i < checklist.length; i += 1) {
      const item = checklist[i];
      if (!currentCategoriesMap[item.title] && !passedCategoriesMap[item.title]) {
        setCurrentStep(i);
        dispatch(setIsComplete(false));
        return;
      }
      setCurrentStep(checklist.length);
      dispatch(setIsComplete(true));
    }
  }, [passedCategoriesMap, currentCategoriesMap]);

  return (
    <ProgressIndicator vertical>
      {/* There seems to be a bug, where the first item
      always shows as current=true if it's the first child, so
      adding a <div /> to prevent this issue */}
      <div />
      {checklist.map((item, index) => (
        <ProgressStep
          key={item.title}
          complete={index < currentStep
            || passedCategoriesMap[item.title]
            || currentCategoriesMap[item.title]}
          invalid={currentCategoriesMap[item.title]}
          current={index === currentStep}
          label={(
            <>
              {item.title}
              <Tooltip
                direction="right"
              >
                {item.text}
                {item['image-description'] ? (
                  <>
                    <br />
                    <br />
                    <span style={{ fontStyle: 'italic' }}>{item['image-description']}</span>
                  </>
                ) : null}
              </Tooltip>
            </>
          )}
          secondaryLabel={index === currentStep ? (
            <div className="bx--row" style={{ marginTop: '0.5rem' }}>
              <div className="bx--col">
                <Button
                  kind="danger"
                  size="sm"
                  style={{ marginRight: '1rem' }}
                  onClick={() => {
                    addFeedback({
                      title: item.title, text: item.text, checkboxText: item.checkboxText || '', image: null, category: item.title,
                    });
                  }}
                >
                  Add
                </Button>
                <Button
                  size="sm"
                  onClick={() => {
                    const quoteRef = doc(getFirestore(), 'quotes', quoteID);
                    updateDoc(quoteRef, { [`feedbackChecklistPasses.${item.title}`]: true });
                  }}
                >
                  Pass
                </Button>
              </div>
            </div>
          ) : null}
        />
      ))}
    </ProgressIndicator>
  );
}

Checklist.propTypes = {
  addFeedback: PropTypes.func.isRequired,
  currentFeedbackCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  partID: PropTypes.string.isRequired,
};
