import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import TcAdsWebService from './TcAdsWebService';

const typeMap = {
  BYTE: { size: 1, read: 'readBYTE' },
  BOOL: { size: 1, read: 'readBOOL' },
  WORD: { size: 2, read: 'readWORD' },
  DWORD: { size: 4, read: 'readDWORD' },
  SINT: { size: 1, read: 'readSINT' },
  INT: { size: 2, read: 'readINT' },
  DINT: { size: 4, read: 'readDINT' },
  REAL: { size: 4, read: 'readREAL' },
  LREAL: { size: 8, read: 'readLREAL' },
  STRING: { read: 'readString' },
};

const initialState = {
  status: 'disconnected',
  writeStatus: 'idle',
  readLoopID: null,
  error: null,
  handles: {},
  values: {},
};

const createPlcSlice = ({ sliceName, url }) => {
  let client;

  const write = createAsyncThunk(
    `${sliceName}/write`,
    async (payload, { rejectWithValue, getState }) => {
      try {
        await new Promise((resolve, reject) => {
          const NETID = ''; // Empty string for local machine;
          const PORT = '851'; // PLC Runtime

          const generalTimeout = 500;

          // Create TcAdsWebService.DataWriter for write-read-write command.
          const writer = new TcAdsWebService.DataWriter();

          // Write general write-read-write commando information
          // to TcAdsWebService.DataWriter object;
          let { size } = typeMap[payload.type];
          if (payload.type === 'STRING') {
            size = payload.value.length;
          }
          writer.writeDINT(TcAdsWebService.TcAdsReservedIndexGroups.SymbolValueByHandle);
          if (getState()[sliceName].handles[payload.handle] === undefined) {
            reject(new Error(`Unable to write variable: handle ${payload.handle} not found`));
          }
          writer.writeDINT(getState()[sliceName].handles[payload.handle]);
          writer.writeDINT(size);

          // Write values to TcAdsWebService.DataWrite object;
          if (payload.type === 'STRING') {
            writer.writeString(payload.value);
          } else {
            writer[`write${payload.type}`](payload.value);
          }

          client.readwrite(
            NETID,
            PORT,
            0xF081, // 0xF081 = Call Write SumCommando
            1, // IndexOffset = Count of requested variables.
            size + 4, // Length of requested data + 4 byte errorcode per variable.
            writer.getBase64EncodedData(),
            (e) => {
              if (e && e.isBusy) {
                // Exit callback function because request is still busy;
                return;
              }

              if (e && !e.hasError) {
                // Exit callback function because request has no error;
                console.log('write success');
                resolve();
              } else if (e.error.getTypeString() === 'TcAdsWebService.ResquestError') {
                // HANDLE TcAdsWebService.ResquestError HERE;
                reject(new Error(`Error: StatusText = ${e.error.statusText} Status: ${e.error.status}`));
              } else if (e.error.getTypeString() === 'TcAdsWebService.Error') {
                // HANDLE TcAdsWebService.Error HERE;
                reject(new Error(`Error: ErrorMessage = ${e.error.errorMessage} ErrorCode: ${e.error.errorCode}`));
              }
            },
            null,
            generalTimeout,
            ((err) => { console.log({ err }); reject(err.message); }),
            true,
          );
        });
        return null;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    },
  );

  const connect = createAsyncThunk(
    `${sliceName}/connect`,
    async (handlesVarNames, { rejectWithValue, dispatch }) => {
      try {
        const NETID = ''; // Empty string for local machine;
        const PORT = '851'; // PLC Runtime
        const SERVICE_URL = url; // HTTP path to the TcAdsWebService;

        client = new TcAdsWebService.Client(SERVICE_URL, null, null);

        const generalTimeout = 500;
        const readLoopDelay = 500;

        const requestHandles = new Promise((resolve, reject) => {
        // Create sumcommando for reading twincat symbol handles by symbol name;
          const handleswriter = new TcAdsWebService.DataWriter();

          // Write general information for each symbol handle
          // to the TcAdsWebService.DataWriter object
          for (let i = 0; i < handlesVarNames.length; i += 1) {
            handleswriter.writeDINT(TcAdsWebService.TcAdsReservedIndexGroups.SymbolHandleByName);
            handleswriter.writeDINT(0);
            handleswriter.writeDINT(4); // Expected size; A handle has a size of 4 byte;
            // The length of the symbol name string
            handleswriter.writeDINT(handlesVarNames[i].name.length);
          }

          // Write symbol names after the general information
          // to the TcAdsWebService.DataWriter object
          for (let i = 0; i < handlesVarNames.length; i += 1) {
            handleswriter.writeString(handlesVarNames[i].name);
          }

          const readLoop = (readSymbolValuesData) => () => {
          // calculate the length of requested data
            let dataLength = 0;
            for (let i = 0; i < handlesVarNames.length; i += 1) {
              if (handlesVarNames[i].type === 'STRING') {
                dataLength += handlesVarNames[i].size || 255;
              } else {
                dataLength += typeMap[handlesVarNames[i].type].size;
              }
            }

            // Send the read-read-write command to the TcAdsWebService
            // by use of the readwrite function of the TcAdsWebService.Client object;
            client.readwrite(
              NETID,
              PORT,
              0xF080, // 0xF080 = Read command;
              handlesVarNames.length, // IndexOffset = Variables count;
              // Length of requested data + 4 byte errorcode per variable;
              dataLength + (handlesVarNames.length * 4),
              readSymbolValuesData,
              (e) => { // ReadCallback
                if (e && e.isBusy) {
                // HANDLE PROGRESS TASKS HERE;
                // Exit callback function because request is still busy;
                  return;
                }

                if (e && !e.hasError) {
                  const { reader } = e;

                  // Read error codes from begin of TcAdsWebService.DataReader object;
                  for (let i = 0; i < handlesVarNames.length; i += 1) {
                    const err = reader.readDWORD();
                    if (err !== 0) {
                      reject(new Error('Symbol error!'));
                      return;
                    }
                  }

                  // read values
                  for (let i = 0; i < handlesVarNames.length; i += 1) {
                    let value;
                    if (handlesVarNames[i].type === 'STRING') {
                      value = reader.readString(handlesVarNames[i].size || 255);
                    } else {
                      value = reader[typeMap[handlesVarNames[i].type].read]();
                    }
                    dispatch({
                      type: `${sliceName}/setValue`,
                      payload: { key: handlesVarNames[i].name, value },
                    });
                  }
                } else if (e.error.getTypeString() === 'TcAdsWebService.ResquestError') {
                // HANDLE TcAdsWebService.ResquestError HERE;
                  reject(new Error(`Error: StatusText = ${e.error.statusText} Status: ${e.error.status}`));
                } else if (e.error.getTypeString() === 'TcAdsWebService.Error') {
                // HANDLE TcAdsWebService.Error HERE;
                  reject(new Error(`Error: ErrorMessage = ${e.error.errorMessage} ErrorCode: ${e.error.errorCode}`));
                }
              },
              null,
              generalTimeout,
              () => { reject(new Error('Read timeout!')); }, // ReadTimeoutCallback
              true,
            );
          };

          // Occurs if the readwrite for the sumcommando has finished;
          const requestHandlesCallback = (e) => {
            if (e && e.isBusy) {
            // Exit callback function because request is still busy;
              return;
            }

            if (e && !e.hasError) {
            // Get TcAdsWebService.DataReader object from TcAdsWebService.Response object;
              const { reader } = e;

              // Read error code and length for each handle;
              for (let i = 0; i < handlesVarNames.length; i += 1) {
                const err = reader.readDWORD();
                reader.readDWORD(); // originally assigned to a variable ('len'), but not used

                if (err !== 0) {
                  reject(new Error('Handle error!'));
                  return;
                }
              }

              // Read handles from TcAdsWebService.DataReader object;
              const handleValues = [];
              for (let i = 0; i < handlesVarNames.length; i += 1) {
                handleValues.push(reader.readDWORD());
              }

              // Create sum commando to read symbol values based on the handle
              const readSymbolValuesWriter = new TcAdsWebService.DataWriter();

              for (let i = 0; i < handleValues.length; i += 1) {
                readSymbolValuesWriter.writeDINT(
                  TcAdsWebService.TcAdsReservedIndexGroups.SymbolValueByHandle, // IndexGroup
                );
                // IndexOffset = The target handle
                readSymbolValuesWriter.writeDINT(handleValues[i]);
                if (handlesVarNames[i].type === 'STRING') {
                  readSymbolValuesWriter.writeDINT(
                    handlesVarNames[i].size || 255,
                  ); // Length of the string
                } else {
                  readSymbolValuesWriter.writeDINT(
                    typeMap[handlesVarNames[i].type].size, // size to read
                  );
                }
              }

              // Get Base64 encoded data from TcAdsWebService.DataWriter;
              const readSymbolValuesData = readSymbolValuesWriter.getBase64EncodedData();
              const readLoopID = window.setInterval(readLoop(readSymbolValuesData), readLoopDelay);

              const handles = {};
              for (let i = 0; i < handlesVarNames.length; i += 1) {
                handles[handlesVarNames[i].name] = handleValues[i];
              }
              resolve({ readLoopID, handles });
            } else if (e.error.getTypeString() === 'TcAdsWebService.ResquestError') {
            // HANDLE TcAdsWebService.ResquestError HERE;
              reject(new Error(`Error: StatusText = ${e.error.statusText} Status: ${e.error.status}`));
            } else if (e.error.getTypeString() === 'TcAdsWebService.Error') {
            // HANDLE TcAdsWebService.Error HERE;
              reject(new Error(`Error: ErrorMessage = ${e.error.errorMessage} ErrorCode: ${e.error.errorCode}`));
            }
          };

          client.readwrite(
            NETID,
            PORT,
            // IndexGroup = ADS list-read-write command;
            // Used to request handles for twincat symbols;
            0xF082,
            handlesVarNames.length, // IndexOffset = Count of requested symbol handles;
            // Length of requested data + 4 byte errorcode and 4 byte length per twincat symbol;
            (handlesVarNames.length * 4) + (handlesVarNames.length * 8),
            handleswriter.getBase64EncodedData(),
            requestHandlesCallback,
            null,
            generalTimeout,
            () => { reject(new Error('Request handles timeout!')); }, // RequestHandlesTimeoutCallback
            true,
          );
        });
        return await requestHandles;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    },
    {
      condition: (_, { getState }) => {
        const slice = getState()[sliceName];

        // don't run if the client is already connected or is loading
        if (slice.status === 'connected' || slice.status === 'loading') {
          return false;
        }

        return true;
      },
    },
  );

  const disconnect = createAsyncThunk(
    `${sliceName}/disconnect`,
    async (_, { getState }) => {
      const state = getState();
      if (state[sliceName].readLoopID) {
        window.clearInterval(state[sliceName].readLoopID);
      }
    },
  );

  const plcSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      setValue: (state, action) => {
        const { key, value } = action.payload;
        state.values[key] = value;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(connect.pending, (state) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(connect.rejected, (state, action) => {
          state.status = 'disconnected';
          state.error = action.payload;
        })
        .addCase(connect.fulfilled, (state, action) => {
          state.status = 'connected';
          state.error = null;
          state.readLoopID = action.payload.readLoopID;
          state.handles = action.payload.handles;
        })
        .addCase(disconnect.pending, (state) => {
          state.status = 'disconnecting';
          state.error = null;
        })
        .addCase(disconnect.rejected, (state, action) => {
          state.status = 'disconnected';
          state.error = action.payload;
        })
        .addCase(disconnect.fulfilled, (state) => {
          state.status = 'disconnected';
          state.error = null;
          state.readLoopID = null;
          state.handles = {};
        })
        .addCase(write.pending, (state) => {
          state.error = null;
          state.writeStatus = 'writing';
        })
        .addCase(write.rejected, (state, action) => {
          state.error = action.payload;
          state.writeStatus = 'idle';
        })
        .addCase(write.fulfilled, (state) => {
          state.writeStatus = 'idle';
        });
    },
  });

  const selectValues = (handleNames) => (state) => {
    const values = {};
    for (let i = 0; i < handleNames.length; i += 1) {
      values[handleNames[i]] = state[sliceName].values[handleNames[i]];
    }
    return values;
  };
  const selectStatus = (state) => state[sliceName].status;
  const selectWriteStatus = (state) => state[sliceName].writeStatus;
  const selectError = (state) => state[sliceName].error;

  return {
    reducer: plcSlice.reducer,
    write,
    connect,
    disconnect,
    selectValues,
    selectStatus,
    selectWriteStatus,
    selectError,
  };
};

export default createPlcSlice;
