import {
  Breadcrumb,
  BreadcrumbItem,
  Link,
} from 'carbon-components-react';
import {
  collection, getFirestore, query, where,
} from 'firebase/firestore';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PartsList from '../../components/PartsList';
import PasswordReset from '../../components/PasswordReset';
import UserEmail from '../../components/UserEmail';
import { selectUserEmailByID } from '../../features/user/userSlice';

function User() {
  const { userID } = useParams();
  const user = useSelector(selectUserEmailByID(userID));

  if (!user) {
    return null;
  }

  if (user.error) {
    return <p>{user.error}</p>;
  }

  const userPartsQuery = useMemo(() => query(collection(getFirestore(), 'parts'), where('uid', '==', userID)), [userID]);

  return (
    <div id="user-page">
      <div className="bx--row">
        <div className="bx--col">
          <h1><UserEmail userID={userID} /></h1>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/user/${userID}`}>
                <UserEmail userID={userID} />
                {'\'s parts'}
              </Link>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <PasswordReset userEmail={user.email} />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <PartsList partsQuery={userPartsQuery} />
        </div>
      </div>
    </div>
  );
}

export default User;
