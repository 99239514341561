import { where } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import DataTable from '../../components/DataTable';
import UserEmail from '../../components/UserEmail';

const linkCell = (e) => <Link to={`/process-data/v2/forming/${e.id}`}>{e.number}</Link>;
const operatorCell = (e) => <UserEmail userID={e.user} />;

function OrdersPage() {
  const [projectNumbers, setProjectNumbers] = useState([]);

  useEffect(() => {
    (async () => {
      const getPartNumbers = httpsCallable(getFunctions(), 'getPartNumbers');

      const { data } = await getPartNumbers();
      setProjectNumbers([
        ...(data.customerPartNumbers || []),
        ...(data.internalPartNumbers || []),
      ].map((partNumber) => (partNumber.value)));
    })();
  }, []);

  return (
    <DataTable
      id="orders"
      header="Forming Data"
      type="process_logs"
      orderField="start"
      filters={[where('station', '==', 'formingLineV2')]}
      pageSize={25}
      columnDefinitions={[
        {
          id: 'number',
          header: 'Chip Number',
          cell: (e) => linkCell(e),
        },
        {
          id: 'proejct',
          header: 'Project',
          cell: (e) => e.project,
        },
        {
          id: 'start',
          header: 'Start',
          cell: (e) => (e.start ? e.start.toDate().toLocaleString() : ''),
        },
        {
          id: 'operator',
          header: 'Operator',
          cell: (e) => operatorCell(e),
        },
        {
          id: 'production',
          header: 'Production Run',
          cell: (e) => (e.production ? '✅' : ''),
        },
        {
          id: 'hypothesis',
          header: 'Hypothesis',
          cell: (e) => e.hypothesis,
        },
        {
          id: 'observations',
          header: 'Observations',
          cell: (e) => e.observations,
        },
      ]}
      filteringOptions={projectNumbers.map((projectNumber) => ({
        propertyKey: 'project',
        value: projectNumber,
      }))}
      filteringProperties={[
        {
          key: 'project',
          operators: ['='],
          propertyLabel: 'Project',
          groupValuesLabel: 'Project IDs',
        },
      ]}
    />
  );
}

export default OrdersPage;
