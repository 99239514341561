import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { HFTSStateItems } from '../../../constants/hftsState';
import { usePLCValue } from '../../../features/plc/context';
import {
  handlify,
  titleify,
  useHFTSHandle,
  useProgramHandle,
} from '../hooks/context';
import PLCButton from './plcButton';
import PLCSelect from './plcSelect';
import PLCToggleButton from './plcToggleButton';

function OverrideControls({ side1, side2 }) {
  const programHandle = useProgramHandle();
  const side1HFTSHandle = useHFTSHandle(side1);
  const side1Handle = useMemo(() => handlify(side1), [side1]);
  const side1Title = useMemo(() => titleify(side1), [side1]);
  const side2HFTSHandle = useHFTSHandle(side2);
  const side2Handle = useMemo(() => handlify(side2), [side2]);
  const side2Title = useMemo(() => titleify(side2), [side2]);

  const lowerTooHot = usePLCValue(`${side1HFTSHandle}.bTooHot`);
  const upperTooHot = usePLCValue(`${side2HFTSHandle}.bTooHot`);

  const isAutocooling = usePLCValue(`${programHandle}.bAutoCool`);

  const hftsOptions = useMemo(() => HFTSStateItems.map((item) => ({
    label: item.text,
    value: `${item.id}`,
  })), []);

  return (
    <div className="bx--row">
      <div className="bx--col">
        <div className="bx--row">
          <div className="bx--col">
            <h4>
              Overrides
            </h4>
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCToggleButton
              handle={`${programHandle}.bOverrideCylinders`}
              label="Cylinder Override"
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCButton
              onClick={(write) => {
                [
                  `${side1HFTSHandle}.bHP1TooHot`,
                  `${side1HFTSHandle}.bHP2TooHot`,
                  `${side1HFTSHandle}.bHP3TooHot`,
                  `${side1HFTSHandle}.bHP4TooHot`,
                  `${side1HFTSHandle}.bHP5TooHot`,
                  `${side1HFTSHandle}.bHP6TooHot`,
                  `${side1HFTSHandle}.bToolTooHot`,
                  `${side1HFTSHandle}.bHotBlockTooHot`,
                  `${side1HFTSHandle}.bFrontBlockTooHot`,
                  `${side1HFTSHandle}.bRearBlockTooHot`,
                  `${side2HFTSHandle}.bHP1TooHot`,
                  `${side2HFTSHandle}.bHP2TooHot`,
                  `${side2HFTSHandle}.bHP3TooHot`,
                  `${side2HFTSHandle}.bHP4TooHot`,
                  `${side2HFTSHandle}.bHP5TooHot`,
                  `${side2HFTSHandle}.bHP6TooHot`,
                  `${side2HFTSHandle}.bToolTooHot`,
                  `${side2HFTSHandle}.bHotBlockTooHot`,
                  `${side2HFTSHandle}.bFrontBlockTooHot`,
                  `${side2HFTSHandle}.bRearBlockTooHot`,

                  `${side1HFTSHandle}.bTooHot`,
                  `${side2HFTSHandle}.bTooHot`,
                ].forEach((handle) => {
                  write({ handle, value: false });
                });
              }}
              disabled={!lowerTooHot && !upperTooHot}
            >
              Reset heater check
            </PLCButton>
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCButton
              onClick={(write) => {
                [
                  `${side1HFTSHandle}.eAutomationState`,
                  `${side2HFTSHandle}.eAutomationState`,
                ].forEach((handle) => {
                  write({ handle, value: 2 });
                });
              }}
            >
              Go to standby
            </PLCButton>
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCSelect
              label={`${side1Title} HFTS State`}
              handle={`${side1HFTSHandle}.eAutomationState`}
              options={hftsOptions}
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCSelect
              label={`${side2Title} HFTS State`}
              handle={`${side2HFTSHandle}.eAutomationState`}
              options={hftsOptions}
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            {isAutocooling ? (
              <PLCButton
                onClick={(write) => {
                  write({ handle: `${programHandle}.bAutoCool`, value: false });
                }}
              >
                Stop autocool shutdown
              </PLCButton>
            ) : (
              <PLCButton
                onClick={(write) => {
                  [
                    `${side1HFTSHandle}.eAutomationState`,
                    `${side2HFTSHandle}.eAutomationState`,
                  ].forEach((handle) => {
                    write({ handle, value: 0, type: 'INT' });
                  });
                  write({ handle: `${programHandle}.bAutoCool`, value: true, type: 'BOOL' });
                }}
              >
                Start autocool shutdown
              </PLCButton>
            )}
          </div>
        </div>
      </div>
      <div className="bx--col">
        <div className="bx--row">
          <div className="bx--col">
            <h4>
              {`${side1Title} HFTS`}
            </h4>
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCToggleButton
              handle={`${programHandle}.${side1Handle}HotCylinder`}
              label={`${side1Title} Hot Cylinder`}
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCToggleButton
              handle={`${programHandle}.${side1Handle}ColdCylinder`}
              label={`${side1Title} Cold Cylinder`}
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCToggleButton
              handle={`${programHandle}.${side1Handle}FrontCylinder`}
              label={`${side1Title} Front Cylinder`}
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCToggleButton
              handle={`${programHandle}.${side1Handle}RearCylinder`}
              label={`${side1Title} Rear Cylinder`}
            />
          </div>
        </div>
      </div>
      <div className="bx--col">
        <div className="bx--row">
          <div className="bx--col">
            <h4>
              {`${side2Title} HFTS`}
            </h4>
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCToggleButton
              handle={`${programHandle}.${side2Handle}HotCylinder`}
              label={`${side2Title} Hot Cylinder`}
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCToggleButton
              handle={`${programHandle}.${side2Handle}ColdCylinder`}
              label={`${side2Title} Cold Cylinder`}
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCToggleButton
              handle={`${programHandle}.${side2Handle}FrontCylinder`}
              label={`${side2Title} Front Cylinder`}
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCToggleButton
              handle={`${programHandle}.${side2Handle}RearCylinder`}
              label={`${side2Title} Rear Cylinder`}
            />
          </div>
        </div>
      </div>
      <div className="bx--col" />
    </div>
  );
}

OverrideControls.propTypes = {
  side1: PropTypes.string.isRequired,
  side2: PropTypes.string.isRequired,
};

export default OverrideControls;
