import {
  Button,
  FileUploaderButton,
  InlineNotification,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectPart } from '../../features/parts/partsSlice';
import CADViewer from './viewer';

function CADPreview({ partID }) {
  const [originalSrc, setOriginalSrc] = useState();
  const [newFile, setNewFile] = useState();
  const newSrc = useMemo(() => {
    if (newFile) {
      return URL.createObjectURL(newFile);
    }
    return null;
  }, [newFile]);
  const part = useSelector(selectPart(partID));

  const filePath = useMemo(() => {
    if (part?.fileID && part?.uid) {
      return `${part.uid}/cad/${part.fileID}.glb`;
    }
    return null;
  }, [part]);

  const [downloadError, setDownloadError] = useState();
  useEffect(() => {
    (async () => {
      if (filePath) {
        try {
          const storageRef = getStorage();
          const fileRef = ref(storageRef, filePath);
          const url = await getDownloadURL(fileRef);
          setOriginalSrc(url);
          setDownloadError(null);
        } catch (err) {
          setDownloadError(err.message);
        }
      }
    })();
  }, [filePath]);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState();

  if (!originalSrc && !downloadError) {
    return (
      <>
        <h4>CAD Preview</h4>
        <SkeletonPlaceholder style={{ width: '100%', height: 400 }} />
      </>
    );
  }

  return (
    <>
      <h4>CAD Preview</h4>
      {downloadError ? (
        <InlineNotification
          kind="error"
          lowContrast
          title={downloadError}
          subtitle=""
        />
      ) : null}
      {uploadError ? (
        <InlineNotification
          kind="error"
          lowContrast
          title={uploadError}
          subtitle="Try refreshing the page and uploading again."
        />
      ) : null}
      {newSrc || originalSrc ? (
        <CADViewer src={newSrc || originalSrc} style={{ height: 400, width: '100%' }} />
      ) : null}
      <p style={{ marginBottom: '1rem' }}>
        Something look wrong? You can upload a new preview file below.
        For more info on how to create a preview file, see the help page
        {' '}
        <a href="https://www.notion.so/parallelfluidics/Make-a-CAD-preview-file-4b9ce7250a7a4be6812817a0a3808221" target="_blank" rel="noreferrer">here</a>
        .
      </p>
      {newSrc ? (
        <>
          <Button
            disabled={isUploading}
            style={{ marginRight: '1rem' }}
            onClick={async () => {
              try {
                setIsUploading(true);
                const storageRef = getStorage();
                const fileRef = ref(storageRef, filePath);
                await uploadBytes(fileRef, newFile);
                window.location.reload();
              } catch (err) {
                setUploadError(err.message);
              }
            }}
          >
            Save
          </Button>
          <Button disabled={isUploading} kind="danger" onClick={() => setNewFile(null)}>Reset</Button>
        </>
      ) : (
        <div className="bx--file__container">
          <FileUploaderButton
            accept={['glb']}
            labelText="Upload new file"
            multiple={false}
            onChange={({ target }) => {
              if (target?.files && target.files.length) {
                setNewFile(target.files[0]);
              }
            }}
            size="default"
          />
        </div>
      )}
    </>
  );
}

CADPreview.propTypes = {
  partID: PropTypes.string.isRequired,
};

export default CADPreview;
