import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectPart } from '../../features/parts/partsSlice';

function QuoteRequest({ partID }) {
  const {
    material,
    filename,
    cappingStyle,
    cappingOptions,
    quantity,
    optionalNotes,
  } = useSelector(selectPart(partID));

  return (
    <>
      <h4>Original quote request</h4>
      <p>
        Filename:
        {' '}
        <code>{filename}</code>
      </p>
      <p>
        Material:
        {' '}
        <code>{material}</code>
      </p>
      <p>
        Capping Style:
        {' '}
        <code>{cappingStyle}</code>
      </p>
      <p>
        Capping Options:
        {' '}
        <code>{cappingOptions}</code>
      </p>
      <p>
        Quantity:
        {' '}
        <code>{quantity}</code>
      </p>
      { optionalNotes && (
      <p>
        Notes:
        {' '}
        <code>{optionalNotes}</code>
      </p>
      ) }
    </>
  );
}

QuoteRequest.propTypes = {
  partID: PropTypes.string.isRequired,
};

export default QuoteRequest;
