import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFunctions, httpsCallable } from 'firebase/functions';

const initialState = {
  recentPaymentIntents: [],
  recentPaymentIntentsStatus: false,
  recentPaymentIntentsFetchError: null,
  orders: {},
  lineItems: {},
  products: {},
  shipments: {},
};

export const fetchShipment = createAsyncThunk(
  'orders/fetchShipment',
  async (shipmentId, { rejectWithValue }) => {
    try {
      const functions = getFunctions();
      const retrieveShipment = httpsCallable(functions, 'retrieveShipment');
      const { data: shipment } = await retrieveShipment({ shipmentId });
      return shipment;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { orders } = getState();
      return !(orders.recentPaymentIntentsStatus === 'loading' || orders.recentSessionIdsStatus === 'fulfilled');
    },
  },
);

export const fetchProduct = createAsyncThunk(
  'orders/fetchProduct',
  async (productId, { rejectWithValue, dispatch }) => {
    try {
      const functions = getFunctions();
      const retrieveProduct = httpsCallable(functions, 'retrieveProduct');
      const { data: product } = await retrieveProduct({ productId });
      if (product.metadata.shipmentId) {
        dispatch(fetchShipment(product.metadata.shipmentId));
      }
      return product;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const fetchLineItems = createAsyncThunk(
  'orders/fetchLineItems',
  async (sessionId, { rejectWithValue, dispatch }) => {
    try {
      const functions = getFunctions();
      const listLineItems = httpsCallable(functions, 'listLineItems');
      const { data: lineItems } = await listLineItems({ sessionId });
      lineItems.forEach((lineItem) => {
        dispatch(fetchProduct(lineItem.price.product));
      });
      return lineItems;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const fetchRecentOrders = createAsyncThunk(
  'orders/fetchRecent',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const functions = getFunctions();
      const listRecentOrders = httpsCallable(functions, 'listRecentOrders');
      const { data: orders } = await listRecentOrders({ limit: 100 });
      orders.forEach((order) => {
        if (order.session) {
          dispatch(fetchLineItems(order.session.id));
        }
      });
      return orders;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { orders } = getState();
      return !(orders.recentPaymentIntentsStatus === 'loading' || orders.recentSessionIdsStatus === 'fulfilled');
    },
  },
);

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // fetchRecentOrders
      .addCase(fetchRecentOrders.pending, (state) => {
        state.recentPaymentIntentsStatus = 'loading';
        state.recentPaymentIntentsFetchError = null;
      })
      .addCase(fetchRecentOrders.rejected, (state, action) => {
        state.recentPaymentIntentsStatus = 'fulfilled';
        state.recentPaymentIntentsFetchError = action.payload;
      })
      .addCase(fetchRecentOrders.fulfilled, (state, action) => {
        state.recentPaymentIntentsStatus = 'fulfilled';
        state.recentPaymentIntentsFetchError = null;
        state.recentPaymentIntents = action.payload.map((order) => {
          state.orders[order.paymentIntent.id] = {
            ...(state.orders[order.paymentIntent.id] || {}),
            ...order,
          };
          return order.paymentIntent.id;
        });
      })

      // fetchLineItems
      .addCase(fetchLineItems.pending, (state, action) => {
        if (!state.lineItems[action.meta.arg]) {
          state.lineItems[action.meta.arg] = {};
        }
        state.lineItems[action.meta.arg].status = 'loading';
        state.lineItems[action.meta.arg].error = null;
      })
      .addCase(fetchLineItems.rejected, (state, action) => {
        if (!state.lineItems[action.meta.arg]) {
          state.lineItems[action.meta.arg] = {};
        }
        state.lineItems[action.meta.arg].status = 'fulfilled';
        state.lineItems[action.meta.arg].error = action.payload;
      })
      .addCase(fetchLineItems.fulfilled, (state, action) => {
        if (!state.lineItems[action.meta.arg]) {
          state.lineItems[action.meta.arg] = {};
        }
        state.lineItems[action.meta.arg].status = 'fulfilled';
        state.lineItems[action.meta.arg].error = null;
        state.lineItems[action.meta.arg].items = action.payload;
      })

      // fetchProduct
      .addCase(fetchProduct.pending, (state, action) => {
        if (!state.products[action.meta.arg]) {
          state.products[action.meta.arg] = {};
        }
        state.products[action.meta.arg].status = 'loading';
        state.products[action.meta.arg].error = null;
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        if (!state.products[action.meta.arg]) {
          state.products[action.meta.arg] = {};
        }
        state.products[action.meta.arg].status = 'fulfilled';
        state.products[action.meta.arg].error = action.payload;
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        if (!state.products[action.meta.arg]) {
          state.products[action.meta.arg] = {};
        }
        state.products[action.meta.arg].status = 'fulfilled';
        state.products[action.meta.arg].error = null;
        state.products[action.meta.arg].product = action.payload;
      })

      // fetchShipment
      .addCase(fetchShipment.pending, (state, action) => {
        if (!state.shipments[action.meta.arg]) {
          state.shipments[action.meta.arg] = {};
        }
        state.shipments[action.meta.arg].status = 'loading';
        state.shipments[action.meta.arg].error = null;
      })
      .addCase(fetchShipment.rejected, (state, action) => {
        if (!state.shipments[action.meta.arg]) {
          state.shipments[action.meta.arg] = {};
        }
        state.shipments[action.meta.arg].status = 'fulfilled';
        state.shipments[action.meta.arg].error = action.payload;
      })
      .addCase(fetchShipment.fulfilled, (state, action) => {
        if (!state.shipments[action.meta.arg]) {
          state.shipments[action.meta.arg] = {};
        }
        state.shipments[action.meta.arg].status = 'fulfilled';
        state.shipments[action.meta.arg].error = null;
        state.shipments[action.meta.arg].shipment = action.payload;
      });
  },
});

export const selectProduct = (productId) => (state) => state.orders.products[productId];
export const selectShipment = (shipmentId) => (state) => state.orders.shipments[shipmentId];

export const selectRecentOrders = (state) => state.orders.recentPaymentIntents.map((id) => {
  let lineItems = { isLoading: false, items: [], error: null };
  let shipping = { isLoading: false, shipping: null, error: null };

  let shipDate = null;
  let sessionId = null;
  const shipmentIds = [];
  if (state.orders.orders[id].session) {
    sessionId = state.orders.orders[id].session.id;
    if (state.orders.lineItems[sessionId].status === 'loading') {
      lineItems = { isLoading: true, items: [], error: null };
      shipping = { isLoading: true, shipping: null, error: null };
    } else if (state.orders.lineItems[sessionId].error) {
      lineItems = {
        isLoading: false,
        items: [],
        error: state.orders.lineItems[sessionId].error,
      };
      shipping = {
        isLoading: false,
        shipping: null,
        error: state.orders.lineItems[sessionId].error,
      };
    } else {
      state.orders.lineItems[sessionId].items.forEach((item) => {
        const product = selectProduct(item.price.product)(state);
        if (product.status === 'fulfilled' && !product.error) {
          if (product.product.metadata.partID && !product.product.name.includes('Setup')) {
          // add product to lineItems
            lineItems.items.push(product);

            // get ship date for this product
            const productDescription = product.product.description.split(' | ');
            const productShipDate = productDescription[productDescription.length - 1];
            if (!shipDate) {
              shipDate = productShipDate;
            } else if (shipDate !== productShipDate) {
              shipDate = 'Multiple Ship Dates';
            }
          } else {
            shipping.shipping = product;
          }
          if (product.product.metadata.shipmentId) {
            shipmentIds.push(product.product.metadata.shipmentId);
          }
        } else {
          lineItems.isLoading = true;
          shipping.isLoading = true;
        }
      });
    }
  } else {
    shipDate = 'Not available on invoiced orders';
  }

  const loadedShipments = shipmentIds.filter(
    (v, i, arr) => arr.indexOf(v) === i, // dedupe array
  ).map(
    (shipmentId) => selectShipment(shipmentId)(state), // select shipments
  ).filter(
    (shipment) => shipment.status === 'fulfilled' && !shipment.error, // only successfully fetched shipments
  ).map(
    (shipment) => shipment.shipment, // get shipment data
  );
  const loadingShipments = shipmentIds.filter(
    (v, i, arr) => arr.indexOf(v) === i, // dedupe array
  ).map(
    (shipmentId) => selectShipment(shipmentId)(state), // select shipments
  ).filter(
    (shipment) => shipment.status === 'loading', // only successfully fetched shipments
  );

  const lastCharge = state.orders.orders[id].paymentIntent.charges.data[
    state.orders.orders[id].paymentIntent.charges.data.length - 1
  ];
  return {
    id,
    email: state.orders.orders[id].paymentIntent.receipt_email,
    total: state.orders.orders[id].paymentIntent.amount,
    lineItems,
    shipping,
    shipDate,
    paidAt: lastCharge.created,
    receiptUrl: lastCharge.receipt_url,
    shipments: { shipments: loadedShipments, isLoading: loadingShipments.length > 0 },
  };
});
export const selectPaymentIntentsAreLoading = (state) => state.orders.recentPaymentIntentsStatus === 'loading';
export const selectPaymentIntentsFetchError = (
  (state) => state.orders.recentPaymentIntentsFetchError
);

export default ordersSlice.reducer;
