import {
  Accordion,
  AccordionItem,
} from 'carbon-components-react';
import React, { useMemo } from 'react';

import HeaterStatus from '../../../components/HMI/components/heaterStatus';
import HFTS from '../../../components/HMI/components/hfts';
import OverrideControls from '../../../components/HMI/components/overrideControls';
import PIDValues from '../../../components/HMI/components/pidControls';
import PLCButton from '../../../components/HMI/components/plcButton';
import PLCError from '../../../components/HMI/components/plcError';
import { ShutdownWarning, ThermocoupleWarning, TooHotWarning } from '../../../components/HMI/components/warnings';
import AutomationControls from '../../../components/HMI/Forming/automation';
import AutomationStage from '../../../components/HMI/Forming/automationStage';
import TempControl from '../../../components/HMI/Forming/tempControl';
import { usePLCAddress, usePressHandle, useProgramHandle } from '../../../components/HMI/hooks/context';
import PLCContextProvider from '../../../features/plc/context';

const getHandles = (pressHandle, programHandle) => [
  { name: `MAIN.${pressHandle}_Mode`, type: 'INT' },

  { name: `${programHandle}.InstronAudio`, type: 'BOOL' },
  { name: `${programHandle}.AirStatus`, type: 'BOOL' },
  { name: `${programHandle}.ChillerStatus`, type: 'BOOL' },
  { name: `${programHandle}.bCheckThermocouple`, type: 'BOOL' },

  { name: `${programHandle}.UpperHotHeater`, type: 'BOOL' },
  { name: `${programHandle}.UpperFrontHeater`, type: 'BOOL' },
  { name: `${programHandle}.UpperRearHeater`, type: 'BOOL' },
  { name: `${programHandle}.LowerHotHeater`, type: 'BOOL' },
  { name: `${programHandle}.LowerFrontHeater`, type: 'BOOL' },
  { name: `${programHandle}.LowerRearHeater`, type: 'BOOL' },
  { name: `${programHandle}.Strain`, type: 'BOOL' },
  { name: `${programHandle}.bInstronManualOverride`, type: 'BOOL' },

  { name: `${programHandle}.fbLowerFormingHFTS.bTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bHP1TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bHP2TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bHP3TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bHP4TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bHP5TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bHP6TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bToolTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bHotBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bFrontBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbLowerFormingHFTS.bRearBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bHP1TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bHP2TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bHP3TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bHP4TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bHP5TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bHP6TooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bToolTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bHotBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bFrontBlockTooHot`, type: 'BOOL' },
  { name: `${programHandle}.fbUpperFormingHFTS.bRearBlockTooHot`, type: 'BOOL' },

  { name: `${programHandle}.UpperHotCylinder`, type: 'BOOL' },
  { name: `${programHandle}.UpperColdCylinder`, type: 'BOOL' },
  { name: `${programHandle}.UpperFrontCylinder`, type: 'BOOL' },
  { name: `${programHandle}.UpperRearCylinder`, type: 'BOOL' },
  { name: `${programHandle}.LowerHotCylinder`, type: 'BOOL' },
  { name: `${programHandle}.LowerColdCylinder`, type: 'BOOL' },
  { name: `${programHandle}.LowerFrontCylinder`, type: 'BOOL' },
  { name: `${programHandle}.LowerRearCylinder`, type: 'BOOL' },

  { name: `${programHandle}.UpperToolRTD`, type: 'LREAL' },
  { name: `${programHandle}.UpperHotRTD`, type: 'LREAL' },
  { name: `${programHandle}.UpperFrontRTD`, type: 'LREAL' },
  { name: `${programHandle}.UpperRearRTD`, type: 'LREAL' },
  { name: `${programHandle}.LowerToolRTD`, type: 'LREAL' },
  { name: `${programHandle}.LowerHotRTD`, type: 'LREAL' },
  { name: `${programHandle}.LowerFrontRTD`, type: 'LREAL' },
  { name: `${programHandle}.LowerRearRTD`, type: 'LREAL' },

  { name: `${programHandle}.LowerHP1`, type: 'LREAL' },
  { name: `${programHandle}.LowerHP2`, type: 'LREAL' },
  { name: `${programHandle}.LowerHP3`, type: 'LREAL' },
  { name: `${programHandle}.LowerHP4`, type: 'LREAL' },
  { name: `${programHandle}.LowerHP5`, type: 'LREAL' },
  { name: `${programHandle}.LowerHP6`, type: 'LREAL' },
  { name: `${programHandle}.UpperHP1`, type: 'LREAL' },
  { name: `${programHandle}.UpperHP2`, type: 'LREAL' },
  { name: `${programHandle}.UpperHP3`, type: 'LREAL' },
  { name: `${programHandle}.UpperHP4`, type: 'LREAL' },
  { name: `${programHandle}.UpperHP5`, type: 'LREAL' },
  { name: `${programHandle}.UpperHP6`, type: 'LREAL' },

  { name: `${programHandle}.bProcessAutomationEnabled`, type: 'BOOL' },
  { name: `${programHandle}.iProcessState`, type: 'INT' },
  { name: `${programHandle}.sFirebaseSessionID`, type: 'STRING', size: 20 },
  { name: `${programHandle}.bShowObservationModal`, type: 'BOOL' },
  { name: `${programHandle}.bShowObservationModal`, type: 'BOOL' },

  { name: `${programHandle}.bLowerHotBlockHeaterSystemOn`, type: 'BOOL' },
  { name: `${programHandle}.bLowerRearHeaterSystemOn`, type: 'BOOL' },
  { name: `${programHandle}.bLowerFrontHeaterSystemOn`, type: 'BOOL' },

  { name: `${programHandle}.fbLowerFormingHFTS.fHotBlockSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fRearHeaterEngagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fRearHeaterDisengagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fFrontHeaterDisengagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fFrontHeaterEngagedSetpoint`, type: 'LREAL' },

  { name: `${programHandle}.bUpperHotBlockHeaterSystemOn`, type: 'BOOL' },
  { name: `${programHandle}.bUpperRearHeaterSystemOn`, type: 'BOOL' },
  { name: `${programHandle}.bUpperFrontHeaterSystemOn`, type: 'BOOL' },

  { name: `${programHandle}.fbUpperFormingHFTS.fHotBlockSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fRearHeaterEngagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fRearHeaterDisengagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fFrontHeaterDisengagedSetpoint`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fFrontHeaterEngagedSetpoint`, type: 'LREAL' },

  { name: `${programHandle}.InstronAudio`, type: 'BOOL' },

  { name: `${programHandle}.fbLowerFormingHFTS.fSetpointLowerBand`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fSetpointUpperBand`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fStandbyLowerBand`, type: 'LREAL' },
  { name: `${programHandle}.fbLowerFormingHFTS.fStandbyUpperBand`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fSetpointLowerBand`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fSetpointUpperBand`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fStandbyLowerBand`, type: 'LREAL' },
  { name: `${programHandle}.fbUpperFormingHFTS.fStandbyUpperBand`, type: 'LREAL' },

  { name: `${programHandle}.fbLowerFormingHFTS.eAutomationState`, type: 'INT' },
  { name: `${programHandle}.fbUpperFormingHFTS.eAutomationState`, type: 'INT' },
  { name: `${programHandle}.bOverrideCylinders`, type: 'BOOL' },
  { name: `${programHandle}.bAutoCool`, type: 'BOOL' },

  { name: `${programHandle}.tPresoakDelayTime`, type: 'DWORD' },
  { name: `${programHandle}.tPresoakDelayTimer.ET`, type: 'DWORD' },
  { name: `${programHandle}.tCoolsoakDelayTime`, type: 'DWORD' },
  { name: `${programHandle}.tCoolsoakDelayTimer.ET`, type: 'DWORD' },

  // PID configurations
  { name: `${programHandle}.stLowerFrontHeaterEngagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stLowerFrontHeaterEngagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stLowerFrontHeaterEngagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stLowerFrontHeaterEngagedParaControllerExternal.TdHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperFrontHeaterEngagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stUpperFrontHeaterEngagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperFrontHeaterEngagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperFrontHeaterEngagedParaControllerExternal.TdHeat`, type: 'DWORD' },

  { name: `${programHandle}.stLowerRearHeaterEngagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stLowerRearHeaterEngagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stLowerRearHeaterEngagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stLowerRearHeaterEngagedParaControllerExternal.TdHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperRearHeaterEngagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stUpperRearHeaterEngagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperRearHeaterEngagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperRearHeaterEngagedParaControllerExternal.TdHeat`, type: 'DWORD' },

  { name: `${programHandle}.stLowerFrontHeaterDisengagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stLowerFrontHeaterDisengagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stLowerFrontHeaterDisengagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stLowerFrontHeaterDisengagedParaControllerExternal.TdHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperFrontHeaterDisengagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stUpperFrontHeaterDisengagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperFrontHeaterDisengagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperFrontHeaterDisengagedParaControllerExternal.TdHeat`, type: 'DWORD' },

  { name: `${programHandle}.stLowerRearHeaterDisengagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stLowerRearHeaterDisengagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stLowerRearHeaterDisengagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stLowerRearHeaterDisengagedParaControllerExternal.TdHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperRearHeaterDisengagedParaControllerExternal.KpHeat`, type: 'LREAL' },
  { name: `${programHandle}.stUpperRearHeaterDisengagedParaControllerExternal.TnHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperRearHeaterDisengagedParaControllerExternal.TvHeat`, type: 'DWORD' },
  { name: `${programHandle}.stUpperRearHeaterDisengagedParaControllerExternal.TdHeat`, type: 'DWORD' },

  // Shutoff timer
  { name: `${programHandle}.tShutdownTimer.ET`, type: 'DWORD' },
  { name: `${programHandle}.tShutdownTimer.PT`, type: 'DWORD' },

  { name: `${pressHandle}.Vacuum`, type: 'BOOL' },
];

function FormingHMI() {
  const pressHandle = usePressHandle();
  const pressModeHandle = useMemo(() => `MAIN.${pressHandle}_Mode`, [pressHandle]);
  const programHandle = useProgramHandle();
  const plcAddress = usePLCAddress(pressHandle);

  const handles = useMemo(
    () => getHandles(pressHandle, programHandle),
    [pressHandle, programHandle],
  );

  return (
    <PLCContextProvider url={plcAddress} handles={handles}>
      <div className="bx--row">
        <div className="bx--col">
          <AutomationStage />
        </div>
        <div className="bx--col">
          <PLCButton
            kind="danger"
            size="sm"
            onClick={(write) => { write({ handle: pressModeHandle, value: 0 }); }}
            style={{ float: 'right' }}
          >
            Disable forming mode
          </PLCButton>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <PLCError />
          <ShutdownWarning />
          <ThermocoupleWarning />
          <TooHotWarning />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col-lg-3">
          <div className="bx--row">
            <div className="bx--col">
              <h4>Tool Temp</h4>
            </div>
          </div>
          <TempControl side="Upper" />
          <TempControl side="Lower" />
        </div>
        <div className="bx--col-lg-3">
          <div className="bx--row">
            <div className="bx--col">
              <h4>Hot Block Heater</h4>
            </div>
          </div>
          <HeaterStatus sideHandle="Upper" heaterHandle="Hot" />
          <HeaterStatus sideHandle="Lower" heaterHandle="Hot" />
        </div>
        <div className="bx--col-lg-3">
          <div className="bx--row">
            <div className="bx--col">
              <h4>Front Block Heater</h4>
            </div>
          </div>
          <HeaterStatus sideHandle="Upper" heaterHandle="Front" />
          <HeaterStatus sideHandle="Lower" heaterHandle="Front" />
        </div>
        <div className="bx--col-lg-3">
          <div className="bx--row">
            <div className="bx--col">
              <h4>Rear Block Heater</h4>
            </div>
          </div>
          <HeaterStatus sideHandle="Upper" heaterHandle="Rear" />
          <HeaterStatus sideHandle="Lower" heaterHandle="Rear" />
        </div>
        <div className="bx--col-lg-4">
          <div className="bx--row">
            <div className="bx--col">
              <h4>Automation</h4>
            </div>
          </div>
          <AutomationControls />
        </div>
      </div>
      <Accordion>
        <style>
          {`.bx--accordion__content {
          padding-right: 0;
        }`}
        </style>
        <AccordionItem title="Override controls">
          <OverrideControls side1="Upper" side2="Lower" />
        </AccordionItem>
        <AccordionItem title="PID controls">
          <div className="bx--row">
            <PIDValues sideHandle="Lower" heaterHandle="Front" engaged />
            <PIDValues sideHandle="Upper" heaterHandle="Front" engaged />
          </div>
          <div className="bx--row">
            <PIDValues sideHandle="Lower" heaterHandle="Rear" engaged />
            <PIDValues sideHandle="Upper" heaterHandle="Rear" engaged />
          </div>
          <div className="bx--row">
            <PIDValues sideHandle="Lower" heaterHandle="Front" engaged={false} />
            <PIDValues sideHandle="Upper" heaterHandle="Front" engaged={false} />
          </div>
          <div className="bx--row">
            <PIDValues sideHandle="Lower" heaterHandle="Rear" engaged={false} />
            <PIDValues sideHandle="Upper" heaterHandle="Rear" engaged={false} />
          </div>
        </AccordionItem>
        <AccordionItem title="Upper Controls">
          <div className="bx--row">
            <div className="bx--col">
              <HFTS side="Upper" />
            </div>
          </div>
        </AccordionItem>
        <AccordionItem title="Lower Controls">
          <div className="bx--row">
            <div className="bx--col">
              <HFTS side="Lower" />
            </div>
          </div>
        </AccordionItem>
      </Accordion>
    </PLCContextProvider>
  );
}

export default FormingHMI;
