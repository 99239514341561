const config = {
  version: '2023-12-27',
  rows: [
    {
      header: 'Automation',
      values: [
        {
          handle: 'PrgForm2.fbUpperFormingHFTS.fFrontHeaterEngagedSetpoint',
          label: 'Top heater setpoint',
          type: 'LREAL',
        },
        {
          handle: 'PrgForm2.fbUpperFormingHFTS.fRearHeaterEngagedSetpoint',
          label: 'Top heater standby',
          type: 'LREAL',
        },
        {
          handle: 'PrgForm2.fbLowerFormingHFTS.fFrontHeaterEngagedSetpoint',
          label: 'Bottom heater setpoint',
          type: 'LREAL',
        },
        {
          handle: 'PrgForm2.fbLowerFormingHFTS.fRearHeaterEngagedSetpoint',
          label: 'Bottom heater standby',
          type: 'LREAL',
        },
        {
          handle: 'PrgForm2.tPresoakDelayTime',
          label: 'Presoak delay time',
          type: 'TIME',
        },
        {
          handle: 'PrgForm2.tCoolsoakDelayTime',
          label: 'Coolsoak delay time',
          type: 'TIME',
        },
      ],
    },
    {
      header: 'Top config',
      values: [
        {
          handle: 'PrgForm2.fbUpperFormingHFTS.fHotBlockSetpoint',
          label: 'Hot block setpoint',
          type: 'LREAL',
        },
        {
          handle: 'PrgForm2.fbUpperFormingHFTS.fFrontHeaterDisengagedSetpoint',
          label: 'Front disengaged setpoint',
          type: 'LREAL',
        },
        {
          handle: 'PrgForm2.fbUpperFormingHFTS.fRearHeaterDisengagedSetpoint',
          label: 'Rear disengaged setpoint',
          type: 'LREAL',
        },
        {
          handle: 'PrgForm2.fbUpperFormingHFTS.fStandbySwapDelta',
          label: 'Standby swap delta',
          type: 'LREAL',
        },
        {
          handle: 'PrgForm2.fbUpperFormingHFTS.fSetpointSwapDelta',
          label: 'Heater setpoint swap delta',
          type: 'LREAL',
        },
      ],
    },
    {
      header: 'Bottom config',
      values: [
        {
          handle: 'PrgForm2.fbLowerFormingHFTS.fHotBlockSetpoint',
          label: 'Hot block setpoint',
          type: 'LREAL',
        },
        {
          handle: 'PrgForm2.fbLowerFormingHFTS.fFrontHeaterDisengagedSetpoint',
          label: 'Front disengaged setpoint',
          type: 'LREAL',
        },
        {
          handle: 'PrgForm2.fbLowerFormingHFTS.fRearHeaterDisengagedSetpoint',
          label: 'Rear disengaged setpoint',
          type: 'LREAL',
        },
        {
          handle: 'PrgForm2.fbLowerFormingHFTS.fStandbySwapDelta',
          label: 'Standby swap delta',
          type: 'LREAL',
        },
        {
          handle: 'PrgForm2.fbLowerFormingHFTS.fSetpointSwapDelta',
          label: 'Heater setpoint swap delta',
          type: 'LREAL',
        },
      ],
    },
    {
      header: 'Front heater PIDs',
      values: [
        {
          handle: 'PrgForm2.stLowerFrontHeaterEngagedParaControllerExternal.KpHeat',
          label: 'Lower Front Heater PID - Kp',
          type: 'LREAL',
        },
        {
          handle: 'PrgForm2.stLowerFrontHeaterEngagedParaControllerExternal.TnHeat',
          label: 'Lower Front Heater PID - Tn',
          type: 'TIME',
        },
        {
          handle: 'PrgForm2.stLowerFrontHeaterEngagedParaControllerExternal.TvHeat',
          label: 'Lower Front Heater PID - Tv',
          type: 'TIME',
        },
        {
          handle: 'PrgForm2.stLowerFrontHeaterEngagedParaControllerExternal.TdHeat',
          label: 'Lower Front Heater PID - Td',
          type: 'TIME',
        },
        {
          handle: 'PrgForm2.stUpperFrontHeaterEngagedParaControllerExternal.KpHeat',
          label: 'Upper Front Heater PID - Kp',
          type: 'LREAL',
        },
        {
          handle: 'PrgForm2.stUpperFrontHeaterEngagedParaControllerExternal.TnHeat',
          label: 'Upper Front Heater PID - Tn',
          type: 'TIME',
        },
        {
          handle: 'PrgForm2.stUpperFrontHeaterEngagedParaControllerExternal.TvHeat',
          label: 'Upper Front Heater PID - Tv',
          type: 'TIME',
        },
        {
          handle: 'PrgForm2.stUpperFrontHeaterEngagedParaControllerExternal.TdHeat',
          label: 'Upper Front Heater PID - Td',
          type: 'TIME',
        },
      ],
    },
    {
      header: 'Rear heater PIDs',
      values: [
        {
          handle: 'PrgForm2.stLowerRearHeaterEngagedParaControllerExternal.KpHeat',
          label: 'Lower Rear Heater PID - Kp',
          type: 'LREAL',
        },
        {
          handle: 'PrgForm2.stLowerRearHeaterEngagedParaControllerExternal.TnHeat',
          label: 'Lower Rear Heater PID - Tn',
          type: 'TIME',
        },
        {
          handle: 'PrgForm2.stLowerRearHeaterEngagedParaControllerExternal.TvHeat',
          label: 'Lower Rear Heater PID - Tv',
          type: 'TIME',
        },
        {
          handle: 'PrgForm2.stLowerRearHeaterEngagedParaControllerExternal.TdHeat',
          label: 'Lower Rear Heater PID - Td',
          type: 'TIME',
        },
        {
          handle: 'PrgForm2.stUpperRearHeaterEngagedParaControllerExternal.KpHeat',
          label: 'Upper Rear Heater PID - Kp',
          type: 'LREAL',
        },
        {
          handle: 'PrgForm2.stUpperRearHeaterEngagedParaControllerExternal.TnHeat',
          label: 'Upper Rear Heater PID - Tn',
          type: 'TIME',
        },
        {
          handle: 'PrgForm2.stUpperRearHeaterEngagedParaControllerExternal.TvHeat',
          label: 'Upper Rear Heater PID - Tv',
          type: 'TIME',
        },
        {
          handle: 'PrgForm2.stUpperRearHeaterEngagedParaControllerExternal.TdHeat',
          label: 'Upper Rear Heater PID - Td',
          type: 'TIME',
        },
      ],
    },
  ],
};

export default config;
