/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  TextInput,
  Toggle,
} from 'carbon-components-react';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { selectQuote } from '../../../features/parts/partsSlice';

const schema = Yup.object().shape({
  leadtimeDaysEnabled: Yup.bool(),
  leadtimeDays: Yup.object().shape({
    'leadtime-standard': Yup.number(),
    'leadtime-expedited': Yup.number(),
    'leadtime-rush': Yup.number(),
  }),
  leadtimeTextEnabled: Yup.bool(),
  leadtimeText: Yup.object().shape({
    'leadtime-standard': Yup.string(),
    'leadtime-expedited': Yup.string(),
    'leadtime-rush': Yup.string(),
  }),
});

function Leadtime({
  partID,
}) {
  const { id: quoteID, hasPendingWrites, pricingV2 } = useSelector(selectQuote(partID));
  const initialValues = {
    leadtimeDaysEnabled: !!pricingV2.leadtimeDays,
    leadtimeDays: pricingV2.leadtimeDays ? { ...pricingV2.leadtimeDays } : {
      'leadtime-standard': 10,
      'leadtime-expedited': 5,
      'leadtime-rush': 3,
    },
    leadtimeTextEnabled: !!pricingV2.leadtimeText,
    leadtimeText: pricingV2.leadtimeText ? { ...pricingV2.leadtimeText } : {
      'leadtime-standard': '',
      'leadtime-expedited': '',
      'leadtime-rush': '',
    },
  };

  const leadtimeDaysRef = useRef();
  const leadtimeTextRef = useRef();

  window.leadtimeDaysRef = leadtimeDaysRef.current;
  window.leadtimeTextRef = leadtimeTextRef.current;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values) => {
        const newPricingV2 = { ...pricingV2 };
        if (!values.leadtimeDaysEnabled && newPricingV2.leadtimeDays) {
          delete newPricingV2.leadtimeDays;
        }
        if (!values.leadtimeTextEnabled && newPricingV2.leadtimeText) {
          delete newPricingV2.leadtimeText;
        }
        if (values.leadtimeDaysEnabled) {
          newPricingV2.leadtimeDays = {
            'leadtime-standard': parseInt(values.leadtimeDays['leadtime-standard'], 10),
            'leadtime-expedited': parseInt(values.leadtimeDays['leadtime-expedited'], 10),
            'leadtime-rush': parseInt(values.leadtimeDays['leadtime-rush'], 10),
          };
        }
        if (values.leadtimeTextEnabled) {
          newPricingV2.leadtimeText = { ...values.leadtimeText };
        }
        updateDoc(doc(getFirestore(), 'quotes', quoteID), { pricingV2: newPricingV2 });
      }}
    >
      {({
        values, handleBlur, handleChange, setFieldValue, handleSubmit,
      }) => {
        let unsavedChanges = false;
        if (values.leadtimeDaysEnabled) {
          if (!pricingV2.leadtimeDays) {
            unsavedChanges = true;
          } else {
            ['leadtime-standard', 'leadtime-expedited', 'leadtime-rush'].forEach((key) => {
              if (parseInt(values.leadtimeDays[key], 10) !== pricingV2.leadtimeDays[key]) {
                unsavedChanges = true;
              }
            });
          }
        } else if (pricingV2.leadtimeDays) {
          unsavedChanges = true;
        }
        if (values.leadtimeTextEnabled) {
          if (!pricingV2.leadtimeText) {
            unsavedChanges = true;
          } else {
            ['leadtime-standard', 'leadtime-expedited', 'leadtime-rush'].forEach((key) => {
              if (values.leadtimeText[key] !== pricingV2.leadtimeText[key]) {
                unsavedChanges = true;
              }
            });
          }
        } else if (pricingV2.leadtimeText) {
          unsavedChanges = true;
        }

        return (
          <>
            <div className="bx--row">
              <div className="bx--col">
                <Toggle
                  id="leadtimeDaysEnabled"
                  labelA="Custom leadtime duration disabled"
                  labelB="Custom leadtime duration enabled"
                  style={{ paddingBottom: '0.5rem' }}
                  toggled={values.leadtimeDaysEnabled}
                  onToggle={(isOn) => {
                    if (isOn && values.leadtimeTextEnabled) {
                      setFieldValue('leadtimeTextEnabled', false);
                    }
                    setFieldValue('leadtimeDaysEnabled', isOn);
                  }}
                  ref={leadtimeDaysRef}
                />
              </div>
            </div>
            {values.leadtimeDaysEnabled && (Object.keys(values.leadtimeDays).map((key) => (
              <div className="bx--row" key={key}>
                <div className="bx--col">
                  <TextInput
                    labelText={key}
                    id={`leadtimeDays[${key}]`}
                    value={values.leadtimeDays[key]}
                    disabled={hasPendingWrites}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )))}
            <div className="bx--row">
              <div className="bx--col">
                <Toggle
                  id="leadtimeTextEnabled"
                  labelA="Custom leadtime text disabled"
                  labelB="Custom leadtime text enabled"
                  style={{ paddingBottom: '0.5rem' }}
                  toggled={values.leadtimeTextEnabled}
                  onToggle={(isOn) => {
                    if (isOn && values.leadtimeDaysEnabled) {
                      setFieldValue('leadtimeDaysEnabled', false);
                    }
                    setFieldValue('leadtimeTextEnabled', isOn);
                  }}
                  ref={leadtimeTextRef}
                />
              </div>
            </div>
            {values.leadtimeTextEnabled && (Object.keys(values.leadtimeText).map((key) => (
              <div className="bx--row" key={key}>
                <div className="bx--col">
                  <TextInput
                    labelText={key}
                    id={`leadtimeText[${key}]`}
                    value={values.leadtimeText[key]}
                    disabled={hasPendingWrites}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )))}
            <Button
              disabled={!unsavedChanges || hasPendingWrites}
              onClick={handleSubmit}
            >
              {hasPendingWrites ? 'Upadating...' : 'Save'}
            </Button>
          </>
        );
      }}
    </Formik>
  );
}

Leadtime.propTypes = {
  partID: PropTypes.string.isRequired,
};

export default Leadtime;
