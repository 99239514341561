import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const initialState = {
  forming: {},
  bonding: {},
  currentSession: {},
};

const DEFAULT_END_OFFSET = 20;

export const selectProcessData = ({ processType, chipName }) => (state) => (
  state.processData[processType][chipName] || {});
export const selectionSessionError = (state) => (
  state.processData.currentSession?.error
  || state.processData.currentSession?.plcData?.error
  || state.processData.currentSession?.instronData?.error
  || null
);
export const selectCurrentSession = (state) => {
  const session = {
    ...state.processData.currentSession?.session,
  };
  if (session.start) {
    session.start = new Date(session.start);
  }
  if (session.end) {
    session.end = new Date(session.end);
  }
  return session;
};
export const selectEndOffset = (state) => (
  state.processData.currentSession?.endOffset
);
export const selectIsCurrentSessionPLCDataLoading = (state) => (
  state.processData.currentSession?.plcData?.status !== 'fulfilled'
);
export const selectCurrentSessionProcessData = (state) => (
  state.processData.currentSession?.plcData?.data || []
);
export const selectIsCurrentSessionInstronDataLoading = (state) => (
  state.processData.currentSession?.instronData?.status !== 'fulfilled'
);
export const selectCurrentSessionInstronData = (state) => {
  if (state.processData.currentSession?.instronData?.data) {
    const instronData = { ...state.processData.currentSession.instronData.data };
    if (instronData.startTime) {
      instronData.startTime = new Date(instronData.startTime);
    }
    if (instronData.endTime) {
      instronData.endTime = new Date(instronData.endTime);
    }
    if (instronData.timestamps) {
      instronData.timestamps = instronData.timestamps.map((timestamp) => new Date(timestamp));
    }
    return instronData;
  }
  return {};
};

export const queryProcessData = createAsyncThunk(
  'processData/queryProcessData',
  async ({
    startTime, endTime, station, press,
  }, { rejectWithValue }) => {
    try {
      if (press) {
        const functions = getFunctions();
        const { data } = await httpsCallable(functions, 'queryProcessDataV3')(
          { startTime, endTime, press },
        );
        const d = data.map((row) => ({
          ...row,
          timestamp: new Date(row.timestamp),
        }));
        return d;
      }

      const useV2 = station.toLowerCase().includes('v2') || startTime > new Date('2023-08-07');
      let database = 'line1';
      if (station.toLowerCase().includes('v2')) {
        database = 'line2';
      }
      const functions = getFunctions();
      const functionName = useV2 ? 'queryProcessDataV2' : 'queryProcessDataV1';
      const { data } = await httpsCallable(functions, functionName)(
        { database, startTime, endTime },
      );
      const d = data.map((row) => ({
        ...row,
        timestamp: useV2 ? new Date(row.timestamp) : row.timestamp.value,
      }));
      return d;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const queryInstronData = createAsyncThunk(
  'processData/queryInstronData',
  async ({ startTime, endTime, station }, { rejectWithValue }) => {
    try {
      // give ourselves 30s of buffer time
      const startTimeWithBuffer = new Date();
      startTimeWithBuffer.setTime(startTime.getTime() - 30 * 1000);

      // query firestore for instron_data between start and end
      const q = query(
        collection(getFirestore(), 'instron_data'),
        where('startTime', '>', startTimeWithBuffer),
        where('startTime', '<', endTime),
      );
      const querySnapshot = await getDocs(q);

      const instronPrefix = {
        formingLineV2: 'forming_v2',
        formingLineV1: 'forming_v1',
        bondingLineV2: 'bonding_v2',
        bondingLineV0: 'bonding_v0',
      }[station];
      let found = false;
      let instronData = {};
      querySnapshot.forEach((snapshot) => {
        if (snapshot.id.startsWith(instronPrefix)) {
          if (found) {
            throw Error('Multiple Instron records found. Couldn\'t determine which to use.');
          }
          found = true;
          instronData = snapshot.data();
        }
      });
      if (!found) {
        return rejectWithValue('No matching Instron records.');
      }
      return {
        ...instronData,
        startTime: instronData.startTime.toDate().toISOString(),
        endTime: instronData.endTime.toDate().toISOString(),
        timestamps: instronData.timestamps.map((t) => t.toDate().toISOString()),
      };
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const queryInstronDataV2 = createAsyncThunk(
  'processData/queryInstronDataV2',
  async ({ startTime, endTime, press }, { rejectWithValue }) => {
    try {
      // give ourselves 30s of buffer time
      const startTimeWithBuffer = new Date();
      startTimeWithBuffer.setTime(startTime.getTime() - 30 * 1000);

      // query firestore for instron_data between start and end
      const q = query(
        collection(getFirestore(), 'instron_data'),
        where('startTime', '>', startTimeWithBuffer),
        where('startTime', '<', endTime),
        where('press', '==', press),
      );
      const querySnapshot = await getDocs(q);

      let found = false;
      let instronData = {};
      querySnapshot.forEach((snapshot) => {
        if (found) {
          throw Error('Multiple Instron records found. Couldn\'t determine which to use.');
        }
        found = true;
        instronData = snapshot.data();
      });
      if (!found) {
        return rejectWithValue('No matching Instron records.');
      }
      return {
        ...instronData,
        startTime: instronData.startTime.toDate().toISOString(),
        endTime: instronData.endTime.toDate().toISOString(),
        timestamps: instronData.timestamps.map((t) => t.toDate().toISOString()),
      };
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const setEndOffset = createAsyncThunk(
  'processData/setEndOffset',
  async ({ offset }, { getState, dispatch, rejectWithValue }) => {
    try {
      const { start, station, press } = selectCurrentSession(getState());

      if (!start) {
        return rejectWithValue('No current session');
      }
      const end = new Date(start);
      end.setSeconds(end.getSeconds() + offset * 60);
      dispatch(queryProcessData({ startTime: start, endTime: end, station }));
      if (press) {
        dispatch(queryInstronDataV2({ startTime: start, endTime: end, press }));
      } else {
        dispatch(queryInstronData({ startTime: start, endTime: end, station }));
      }
      return offset;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const setCurrentSession = createAsyncThunk(
  'processData/setCurrentSession',
  async ({ sessionID }, { rejectWithValue, dispatch }) => {
    try {
      const db = getFirestore();
      const docRef = doc(db, 'process_logs', sessionID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const startTime = data.start.toDate();
        let endTime;
        if (data.end) {
          endTime = data.end.toDate();
        } else {
          endTime = new Date(startTime);
          endTime.setSeconds(endTime.getSeconds() + DEFAULT_END_OFFSET * 60);
        }
        const { press, station } = data;
        dispatch(queryProcessData({
          startTime, endTime, station: data.station, press,
        }));
        if (press) {
          dispatch(queryInstronDataV2({ startTime, endTime, press }));
        } else {
          dispatch(queryInstronData({ startTime, endTime, station }));
        }
        return {
          id: docSnap.id,
          ...data,
          start: startTime.toISOString(),
          end: data.end ? endTime.toISOString() : null,
        };
      }
      return {};
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const processDataSlice = createSlice({
  name: 'processData',
  initialState,
  reducers: {
    logout: () => initialState,
    clearCurrentSession: (state) => {
      state.currentSession = {
        session: {},
        instronData: {},
        processData: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(queryProcessData.pending, (state) => {
        state.currentSession.plcData = {
          status: 'loading',
          error: null,
          data: [],
        };
      })
      .addCase(queryProcessData.rejected, (state, action) => {
        state.currentSession.plcData = {
          status: 'fulfilled',
          error: action.payload,
          data: [],
        };
      })
      .addCase(queryProcessData.fulfilled, (state, action) => {
        state.currentSession.plcData = {
          status: 'fulfilled',
          error: null,
          data: action.payload,
        };
      })
      .addCase(queryInstronData.pending, (state) => {
        state.currentSession.instronData = {
          status: 'loading',
          error: null,
          data: [],
        };
      })
      .addCase(queryInstronData.rejected, (state, action) => {
        state.currentSession.instronData = {
          status: 'fulfilled',
          error: action.payload,
          data: [],
        };
      })
      .addCase(queryInstronData.fulfilled, (state, action) => {
        state.currentSession.instronData = {
          status: 'fulfilled',
          error: null,
          data: action.payload,
        };
      })
      .addCase(queryInstronDataV2.pending, (state) => {
        state.currentSession.instronData = {
          status: 'loading',
          error: null,
          data: [],
        };
      })
      .addCase(queryInstronDataV2.rejected, (state, action) => {
        state.currentSession.instronData = {
          status: 'fulfilled',
          error: action.payload,
          data: [],
        };
      })
      .addCase(queryInstronDataV2.fulfilled, (state, action) => {
        state.currentSession.instronData = {
          status: 'fulfilled',
          error: null,
          data: action.payload,
        };
      })
      .addCase(setEndOffset.rejected, (state, action) => {
        state.currentSession.error = action.payload;
      })
      .addCase(setEndOffset.fulfilled, (state, action) => {
        state.currentSession.endOffset = action.payload;
      })
      .addCase(setCurrentSession.pending, (state) => {
        state.currentSession = {
          status: 'loading',
          error: null,
          session: {},
        };
      })
      .addCase(setCurrentSession.rejected, (state, action) => {
        state.currentSession = {
          status: 'fulfilled',
          error: action.payload,
          session: {},
          endOffset: DEFAULT_END_OFFSET,
        };
      })
      .addCase(setCurrentSession.fulfilled, (state, action) => {
        state.currentSession = {
          status: 'fulfilled',
          error: null,
          session: action.payload,
        };
        if (action.payload.end) {
          state.currentSession.endOffset = Math.ceil((
            new Date(Date.parse(action.payload.end))
            - new Date(Date.parse(action.payload.start))
          ) / 1000 / 60);
        } else {
          state.currentSession.endOffset = DEFAULT_END_OFFSET;
        }
      });
  },
});

export const { clearCurrentSession } = processDataSlice.actions;

export default processDataSlice.reducer;
