/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  DataTable,
  InlineNotification,
  Modal,
  NotificationActionButton,
  OverflowMenu,
  OverflowMenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarAction,
  TableToolbarContent,
  TableToolbarMenu,
  TextInput,
} from 'carbon-components-react';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import * as Yup from 'yup';

import { selectQuote } from '../../../features/parts/partsSlice';

const schema = Yup.object().shape({
  quantity: Yup.number().required().min(1).integer(),
  uncappedPrice: Yup.number().required().min(0),
  cappedPrice: Yup.number().required().min(0),
});

function EditBreakpoint({
  partID, breakpoint, setBreakpoint, isOpen, close,
}) {
  const { hasPendingWrites } = useSelector(selectQuote(partID));

  return (
    <Formik
      initialValues={breakpoint}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        setBreakpoint(values);
        close();
        setSubmitting(false);
      }}
    >
      {({
        values, errors, handleBlur, handleChange, handleSubmit,
      }) => (
        <Modal
          size="lg"
          open={isOpen}
          onRequestClose={() => { close(); }}
          modalHeading="Edit breakpoint"
          primaryButtonText="Update"
          secondaryButtonText="Cancel"
          onRequestSubmit={handleSubmit}
        >
          <div className="bx--row">
            <div className="bx--col">
              <TextInput
                labelText="Quantity"
                id="quantity"
                key="quantity"
                value={values.quantity}
                disabled={hasPendingWrites}
                onBlur={handleBlur}
                onChange={handleChange}
                invalidText={errors.quantity}
                invalid={!!errors.quantity}
              />
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <TextInput
                labelText="Uncapped unit price"
                id="uncappedPrice"
                key="uncappedPrice"
                value={values.uncappedPrice}
                disabled={hasPendingWrites}
                onBlur={handleBlur}
                onChange={handleChange}
                invalidText={errors.uncappedPrice}
                invalid={!!errors.uncappedPrice}
              />
              <div className="bx--row">
                <div className="bx--col" />
              </div>
              <TextInput
                labelText="Capped unit price"
                id="cappedPrice"
                key="cappedPrice"
                value={values.cappedPrice}
                disabled={hasPendingWrites}
                onBlur={handleBlur}
                onChange={handleChange}
                invalidText={errors.cappedPrice}
                invalid={!!errors.cappedPrice}
              />
            </div>
          </div>
        </Modal>
      )}
    </Formik>
  );
}

EditBreakpoint.propTypes = {
  partID: PropTypes.string.isRequired,
  breakpoint: PropTypes.shape({
    quantity: PropTypes.number,
    cappedPrice: PropTypes.number,
    uncappedPrice: PropTypes.number,
  }).isRequired,
  setBreakpoint: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

function Breakpoints({ partID }) {
  const { id: quoteID, pricingV2, hasPendingWrites } = useSelector(selectQuote(partID));

  const [focusedBreakpointItem, setFocusedBreakpointItem] = useState({
    quantity: 0,
    uncappedPrice: 0,
    cappedPrice: 0,
  });
  const [updateBreakpointFunc, setUpdateBreakpointFunc] = useState(() => () => {});
  const [isBreakpointEditorOpen, setBreakpointEditorOpen] = useState(false);

  if (!pricingV2 || !pricingV2.pricingLevels) {
    return null;
  }

  const breakpoints = [...pricingV2.pricingLevels];
  breakpoints.sort((a, b) => a.quantity - b.quantity);

  return (
    <Formik
      initialValues={{ breakpoints }}
      enableReinitialize
    >
      {({
        values, setFieldValue,
      }) => {
        // check for unsaved changes
        let unsavedChanges = false;
        if (values.breakpoints.length !== pricingV2.pricingLevels.length) {
          unsavedChanges = true;
        } else {
          values.breakpoints.forEach((item, index) => {
            if (item.quantity !== breakpoints[index].quantity
              || item.price.uncapped !== breakpoints[index].price.uncapped
              || item.price.capped !== breakpoints[index].price.capped) {
              unsavedChanges = true;
            }
          });
        }
        const blocker = useBlocker(unsavedChanges);
        window.onbeforeunload = unsavedChanges ? () => true : null;

        const headerData = [
          {
            header: 'Quantity',
            key: 'quantity',
          },
          {
            header: 'Uncapped Unit Price',
            key: 'uncappedPrice',
          },
          {
            header: 'Capped Unit Price',
            key: 'cappedPrice',
          },
        ];
        return (
          <>
            {typeof document === 'undefined'
              ? null
              : ReactDOM.createPortal(
                <EditBreakpoint
                  partID={partID}
                  breakpoint={focusedBreakpointItem}
                  isOpen={isBreakpointEditorOpen}
                  close={() => {
                    setBreakpointEditorOpen(false);
                    setFocusedBreakpointItem({ quantity: 0, cappedPrice: 0, uncappedPrice: 0 });
                  }}
                  setBreakpoint={updateBreakpointFunc}
                />,
                document.body,
              )}
            {blocker.state === 'blocked' ? (
              <InlineNotification
                kind="error"
                lowContrast
                hideCloseButton
                title="There is unsaved pricing info."
                subtitle="Save your changes or click the Proceed button to discard your changes."
                style={{ minWidth: '100%', width: '100%' }}
                actions={(
                  <NotificationActionButton
                    onClick={() => { blocker.proceed(); }}
                  >
                    Proceed
                  </NotificationActionButton>
            )}
              />
            ) : null}
            <DataTable
              rows={values.breakpoints.map((row, index) => ({
                id: `breakpoint-${index}`,
                quantity: row.quantity,
                uncappedPrice: row.price.uncapped,
                cappedPrice: row.price.capped,
              }))}
              headers={headerData}
            >
              {({
                rows, headers, getToolbarProps, getHeaderProps, getTableProps,
              }) => {
                // edit a breakpoint
                const handleEditBreakpoint = (index) => {
                  setFocusedBreakpointItem({
                    quantity: values.breakpoints[index].quantity,
                    uncappedPrice: values.breakpoints[index].price.uncapped,
                    cappedPrice: values.breakpoints[index].price.capped,
                  });
                  setUpdateBreakpointFunc(() => (newBreakpoint) => {
                    setFieldValue(`breakpoints[${index}].quantity`, parseInt(newBreakpoint.quantity, 10));
                    setFieldValue(`breakpoints[${index}].price.uncapped`, parseFloat(newBreakpoint.uncappedPrice));
                    setFieldValue(`breakpoints[${index}].price.capped`, parseFloat(newBreakpoint.cappedPrice));
                  });
                  setBreakpointEditorOpen(true);
                };

                // save changes
                const handleSave = () => {
                  updateDoc(doc(getFirestore(), 'quotes', quoteID), {
                    pricingV2: { ...pricingV2, pricingLevels: values.breakpoints },
                  });
                };

                // add a new breakpoint
                const handleAddBreakpoint = () => {
                  const newBreakpoint = {
                    quantity: 5,
                    price: {
                      uncapped: 0,
                      capped: 0,
                    },
                  };
                  setFieldValue('breakpoints', [...values.breakpoints, newBreakpoint]);
                };

                // delete a breakpoint
                const handleDeleteBreakpoint = (index) => {
                  setFieldValue('breakpoints', values.breakpoints.filter((item, i) => i !== index));
                };

                return (
                  <TableContainer>
                    <TableToolbar {...getToolbarProps()}>
                      <TableToolbarContent>
                        <TableToolbarMenu light>
                          <TableToolbarAction
                            onClick={handleAddBreakpoint}
                            disabled={hasPendingWrites}
                          >
                            Add new breakpoint
                          </TableToolbarAction>
                        </TableToolbarMenu>
                        <Button
                          onClick={handleSave}
                          disabled={!unsavedChanges}
                        >
                          {hasPendingWrites ? 'Upadating...' : 'Save'}
                        </Button>
                      </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          {headers.map((header) => (
                            <TableHeader {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                          <TableHeader key="overflow-menu" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, rowIndex) => (
                          <TableRow key={row.id}>
                            {row.cells.map((cell) => {
                              switch (cell.info.header) {
                                case 'uncappedPrice':
                                case 'cappedPrice': {
                                  return (
                                    <TableCell key={cell.id}>
                                      { new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value) }
                                    </TableCell>
                                  );
                                }
                                default: {
                                  return <TableCell key={cell.id}>{cell.value}</TableCell>;
                                }
                              }
                            })}
                            <TableCell key={`${row.id}:overflow`} width={32} className="bx--table-column-menu">
                              <OverflowMenu size="sm" light flipped>
                                <OverflowMenuItem
                                  key={`${row.id}:overflow:edit`}
                                  itemText="Edit"
                                  onClick={() => { handleEditBreakpoint(rowIndex); }}
                                />
                                <OverflowMenuItem
                                  key={`${row.id}:overflow:delete`}
                                  isDelete
                                  itemText="Delete"
                                  disabled={values.breakpoints.length === 2}
                                  onClick={() => { handleDeleteBreakpoint(rowIndex); }}
                                />
                              </OverflowMenu>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              }}
            </DataTable>
          </>
        );
      }}
    </Formik>
  );
}

Breakpoints.propTypes = {
  partID: PropTypes.string.isRequired,
};

export default Breakpoints;
