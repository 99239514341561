import '@carbon/charts/styles.css';

import { LineChart } from '@carbon/charts-react';
import { Button, NumberInput } from 'carbon-components-react';
import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectCurrentSession,
  selectCurrentSessionInstronData,
  selectCurrentSessionProcessData,
  selectEndOffset,
  selectIsCurrentSessionPLCDataLoading,
  setEndOffset,
} from '../../../features/processData/processDataSlice';

function ProcessGraph() {
  const ref = useRef();
  const dispatch = useDispatch();

  const session = useSelector(selectCurrentSession);
  const endOffset = useSelector(selectEndOffset);
  const isPLCDataLoading = useSelector(selectIsCurrentSessionPLCDataLoading);
  const plcPoints = useSelector(selectCurrentSessionProcessData);
  const instronData = useSelector(selectCurrentSessionInstronData);

  const [chartData, defaultSelectedGroups] = useMemo(() => {
    if (!plcPoints) {
      return [];
    }

    let selectedGroups = [];
    const tempData = [];

    if (instronData.timestamps && instronData.timestamps.length > 0) {
      for (let i = 0; i < instronData.timestamps.length; i += 1) {
        tempData.push({ group: 'Force', timestamp: instronData.timestamps[i], force: instronData.forces[i] });
      }
      if (selectedGroups.length > 0) {
        selectedGroups.push('Force');
      }
    }

    for (let i = 0; i < plcPoints.length; i += 1) {
      const {
        forming_line_v1_bottom_temp: formingLineV1BottomTemp,
        forming_line_v1_bottom_setpoint: formingLineV1BottomSetpoint,
        forming_line_v1_upper_temp: formingLineV1UpperTemp,
        forming_line_v1_upper_setpoint: formingLineV1UpperSetpoint,

        heater_3_setpoint: heater3Setpoint,
        heater_3_temp: heater3Temp,
        heater_4_setpoint: heater4Setpoint,
        heater_4_temp: heater4Temp,

        upperFormingToolTemp,
        lowerFormingToolTemp,
        upperFormingHP1Temp,
        upperFormingHP2Temp,
        upperFormingHP3Temp,
        upperFormingHP4Temp,
        upperFormingHP5Temp,
        upperFormingHP6Temp,
        lowerFormingHP1Temp,
        lowerFormingHP2Temp,
        lowerFormingHP3Temp,
        lowerFormingHP4Temp,
        lowerFormingHP5Temp,
        lowerFormingHP6Temp,
        upperBondingToolTemp,
        lowerBondingToolTemp,
        upperBondingHP1Temp,
        upperBondingHP2Temp,
        upperBondingHP3Temp,
        upperBondingHP4Temp,
        upperBondingHP5Temp,
        upperBondingHP6Temp,
        lowerBondingHP1Temp,
        lowerBondingHP2Temp,
        lowerBondingHP3Temp,
        lowerBondingHP4Temp,
        lowerBondingHP5Temp,
        lowerBondingHP6Temp,

        timestamp,
      } = plcPoints[i];

      switch (session.station) {
        case 'formingLineV1': {
          tempData.push({ group: 'Bottom Temp', timestamp, temperature: formingLineV1BottomTemp });
          tempData.push({ group: 'Bottom Setpoint', timestamp, temperature: formingLineV1BottomSetpoint });
          tempData.push({ group: 'Top Temp', timestamp, temperature: formingLineV1UpperTemp });
          tempData.push({ group: 'Top Setpoint', timestamp, temperature: formingLineV1UpperSetpoint });
          break;
        }
        case 'formingLineV2': {
          tempData.push({ group: 'Top Temp', timestamp: new Date(timestamp), temperature: upperFormingToolTemp });
          tempData.push({ group: 'Bottom Temp', timestamp: new Date(timestamp), temperature: lowerFormingToolTemp });
          tempData.push({ group: 'Top HP1', timestamp: new Date(timestamp), temperature: upperFormingHP1Temp });
          tempData.push({ group: 'Top HP2', timestamp: new Date(timestamp), temperature: upperFormingHP2Temp });
          tempData.push({ group: 'Top HP3', timestamp: new Date(timestamp), temperature: upperFormingHP3Temp });
          tempData.push({ group: 'Top HP4', timestamp: new Date(timestamp), temperature: upperFormingHP4Temp });
          tempData.push({ group: 'Top HP5', timestamp: new Date(timestamp), temperature: upperFormingHP5Temp });
          tempData.push({ group: 'Top HP6', timestamp: new Date(timestamp), temperature: upperFormingHP6Temp });
          tempData.push({ group: 'Bottom HP1', timestamp: new Date(timestamp), temperature: lowerFormingHP1Temp });
          tempData.push({ group: 'Bottom HP2', timestamp: new Date(timestamp), temperature: lowerFormingHP2Temp });
          tempData.push({ group: 'Bottom HP3', timestamp: new Date(timestamp), temperature: lowerFormingHP3Temp });
          tempData.push({ group: 'Bottom HP4', timestamp: new Date(timestamp), temperature: lowerFormingHP4Temp });
          tempData.push({ group: 'Bottom HP5', timestamp: new Date(timestamp), temperature: lowerFormingHP5Temp });
          tempData.push({ group: 'Bottom HP6', timestamp: new Date(timestamp), temperature: lowerFormingHP6Temp });
          break;
        }
        case 'bondingLineV2': {
          tempData.push({ group: 'Top Temp', timestamp: new Date(timestamp), temperature: upperBondingToolTemp });
          tempData.push({ group: 'Bottom Temp', timestamp: new Date(timestamp), temperature: lowerBondingToolTemp });
          tempData.push({ group: 'Top HP1', timestamp: new Date(timestamp), temperature: upperBondingHP1Temp });
          tempData.push({ group: 'Top HP2', timestamp: new Date(timestamp), temperature: upperBondingHP2Temp });
          tempData.push({ group: 'Top HP3', timestamp: new Date(timestamp), temperature: upperBondingHP3Temp });
          tempData.push({ group: 'Top HP4', timestamp: new Date(timestamp), temperature: upperBondingHP4Temp });
          tempData.push({ group: 'Top HP5', timestamp: new Date(timestamp), temperature: upperBondingHP5Temp });
          tempData.push({ group: 'Top HP6', timestamp: new Date(timestamp), temperature: upperBondingHP6Temp });
          tempData.push({ group: 'Bottom HP1', timestamp: new Date(timestamp), temperature: lowerBondingHP1Temp });
          tempData.push({ group: 'Bottom HP2', timestamp: new Date(timestamp), temperature: lowerBondingHP2Temp });
          tempData.push({ group: 'Bottom HP3', timestamp: new Date(timestamp), temperature: lowerBondingHP3Temp });
          tempData.push({ group: 'Bottom HP4', timestamp: new Date(timestamp), temperature: lowerBondingHP4Temp });
          tempData.push({ group: 'Bottom HP5', timestamp: new Date(timestamp), temperature: lowerBondingHP5Temp });
          tempData.push({ group: 'Bottom HP6', timestamp: new Date(timestamp), temperature: lowerBondingHP6Temp });
          break;
        }
        case 'bondingLineV0': {
          tempData.push({ group: 'Bottom Temp', timestamp, temperature: heater3Temp });
          tempData.push({ group: 'Bottom Setpoint', timestamp, temperature: heater3Setpoint });
          tempData.push({ group: 'Top Temp', timestamp, temperature: heater4Temp });
          tempData.push({ group: 'Top Setpoint', timestamp, temperature: heater4Setpoint });
          break;
        }
        default:
          break;
      }
    }

    switch (session.station) {
      case 'formingLineV2': {
        selectedGroups = ['Bottom Temp', 'Top Temp'];
        break;
      }
      case 'bondingLineV2': {
        selectedGroups = ['Bottom Temp', 'Top Temp'];
        break;
      }
      default:
        break;
    }

    if (instronData.timestamps && instronData.timestamps.length > 0 && selectedGroups.length > 0) {
      selectedGroups.push('Force');
    }

    return [tempData, selectedGroups];
  }, [plcPoints, instronData]);

  const options = {
    axes: {
      bottom: {
        title: 'Time',
        mapsTo: 'timestamp',
        scaleType: 'time',
      },
      left: {
        mapsTo: 'temperature',
        title: 'Temperature (F)',
        scaleType: 'linear',
        includeZero: false,
      },
      right: {
        mapsTo: 'force',
        title: 'Force (kN)',
        scaleType: 'linear',
        correspondingDatasets: [
          'Force',
        ],
      },
    },
    curve: 'curveLinear',
    data: {
      loading: isPLCDataLoading,
      selectedGroups: defaultSelectedGroups,
    },
    points: { enabled: false },
    height: '600px',
    tooltip: {
      showTotal: false,
      valueFormatter: (value) => {
        if (value instanceof Date) {
          return value.toLocaleDateString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric' }).split(' ')[1];
        }
        return value;
      },
    },
    legend: {
      truncation: { type: 'none' },
    },
  };

  if (!chartData) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {endOffset ? (
        <>
          <div className="bx--row">
            <div className="bx--col">
              <p>
                {session.end ? null : 'End time wasn\'t captured correctly. '}
                Use the options below to manually set the time window (in minutes).
              </p>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <NumberInput
                id="offset"
                max={1000}
                min={0}
                step={1}
                defaultValue={endOffset}
                ref={ref}
                disabled={isPLCDataLoading}
              />
            </div>
            <div className="bx--col">
              <Button
                onClick={() => {
                  const newValue = Number.parseInt(ref.current?.value, 10);
                  dispatch(setEndOffset({ offset: newValue * 60 }));
                }}
                disabled={isPLCDataLoading}
                size="md"
              >
                Reload
              </Button>
            </div>
            <div className="bx--col" />
            <div className="bx--col" />
          </div>
        </>
      ) : null}
      <div className="bx--row">
        <div className="bx--col">
          <LineChart
            data={chartData}
            options={options}
          />
        </div>
      </div>
    </>
  );
}

export default ProcessGraph;
