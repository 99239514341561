import React from 'react';

import Travelers from '../../components/OrdersList/travelers';

function OldOrders() {
  return (
    <>
      <div className="bx--row">
        <div className="bx--col">
          <Travelers
            customerName="Unchained"
            projectNumber="C00078"
            shipDate="2024-04-17"
            moldedGoal={30}
            postmachinedGoal={29}
            bondedGoal={20}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Travelers
            customerName="FL85 (PC)"
            projectNumber="C00076"
            shipDate="2024-04-11"
            moldedGoal={9}
            postmachinedGoal={8}
            bondedGoal={4}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Travelers
            customerName="FL85"
            projectNumber="C00074"
            shipDate="2024-04-08"
            moldedGoal={18}
            postmachinedGoal={17}
            bondedGoal={10}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Travelers
            customerName="Xellar"
            projectNumber="C00073"
            shipDate="2024-04-04"
            moldedGoal={Math.ceil(10 * 1.2)}
            postmachinedGoal={10}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Travelers
            customerName="MIT"
            projectNumber="C00072"
            shipDate="2024-04-04"
            moldedGoal={Math.ceil(51 * 1.1 * 1.2)}
            postmachinedGoal={Math.ceil(51 * 1.2)}
            bondedGoal={51}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Travelers
            customerName="Sample Chip"
            projectNumber="P035-COP-COC"
            shipDate="2024-03-22"
            moldedGoal={Math.ceil(20 * 1.1 * 1.2 * 1.1)}
            postmachinedGoal={Math.ceil(20 * 1.1 * 1.2)}
            bondedGoal={Math.ceil(20 * 1.1)}
            connectorizedGoal={20}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Travelers
            customerName="Valve chip"
            projectNumber="P036-COP-COC-X1"
            shipDate="2024-03-22"
            moldedGoal={Math.ceil(20 * 1.1 * 1.2 * 1.1)}
            postmachinedGoal={Math.ceil(20 * 1.1 * 1.2)}
            bondedGoal={Math.ceil(20 * 1.1)}
            connectorizedGoal={20}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Travelers
            customerName="Unchained"
            projectNumber="C00071"
            shipDate="2024-03-20"
            moldedGoal={13}
            postmachinedGoal={10}
            bondedGoal={5}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Travelers
            customerName="Xellar"
            projectNumber="C00070"
            shipDate="2024-02-27"
            moldedGoal={7}
            postmachinedGoal={5}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Travelers
            customerName="Unchained"
            projectNumber="C00068"
            shipDate="2024-02-23"
            moldedGoal={20}
            postmachinedGoal={18}
            bondedGoal={5}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Travelers
            customerName="Acorn Genetics"
            projectNumber="C00069"
            shipDate="2024-02-16"
            moldedGoal={20}
            postmachinedGoal={18}
            bondedGoal={8}
            connectorizedGoal={5}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Travelers
            customerName="FL85"
            projectNumber="C00067"
            shipDate="2024-02-15"
            moldedGoal={20}
            postmachinedGoal={18}
            bondedGoal={8}
          />
        </div>
      </div>
    </>
  );
}

export default OldOrders;
