import PropTypes from 'prop-types';
import React from 'react';

import { disengagedTempCalculations } from '../components/hfts';
import PLCNumberInput from '../components/plcNumberInput';
import PLCValue from '../components/plcValue';
import { useHFTSHandle, useProgramHandle } from '../hooks/context';
import useHFTSTarget from '../hooks/useHFTSTarget';

function TempControl({ side }) {
  const programHandle = useProgramHandle();
  const hftsHandle = useHFTSHandle(side);
  const target = useHFTSTarget(side);

  return (
    <>
      <div className="bx--row">
        <div className="bx--col">
          <p style={{ textAlign: 'center', fontSize: 16 }}>
            {`${side} Temp: `}
            <PLCValue
              handle={`${programHandle}.${side}ToolRTD`}
              format={(value) => `${value / 10} °C`}
              style={{ fontWeight: 800 }}
            />
            <br />
            (Target:
            {' '}
            {target}
            &deg;C)
          </p>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <PLCNumberInput
            label={`${side} Setpoint`}
            handle={`${hftsHandle}.fFrontHeaterEngagedSetpoint`}
            type="float"
            writeSideEffect={({ value, write }) => {
              const disengagedSetpoint = disengagedTempCalculations[side].front(value);
              write({ handle: `${hftsHandle}.fFrontHeaterDisengagedSetpoint`, value: disengagedSetpoint });
            }}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <PLCNumberInput
            label={`${side} Standby`}
            handle={`${hftsHandle}.fRearHeaterEngagedSetpoint`}
            type="float"
            writeSideEffect={({ value, write }) => {
              const disengagedSetpoint = disengagedTempCalculations[side].rear(value);
              write({ handle: `${hftsHandle}.fRearHeaterDisengagedSetpoint`, value: disengagedSetpoint });
            }}
          />
        </div>
      </div>
    </>
  );
}

TempControl.propTypes = {
  side: PropTypes.oneOf(['Upper', 'Lower']).isRequired,
};

export default TempControl;
