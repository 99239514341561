import { InlineNotification } from 'carbon-components-react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ProcessGraph from '../../components/ProcessData/graph';
import Overview from '../../components/ProcessData/overview';
import {
  clearCurrentSession,
  selectionSessionError,
  setCurrentSession,
} from '../../features/processData/processDataSlice';

export default function ProcessDataPage() {
  const { sessionID } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (dispatch) {
      dispatch(setCurrentSession({ sessionID }));
      return () => {
        dispatch(clearCurrentSession());
      };
    }
    return () => {};
  }, [dispatch]);

  const error = useSelector(selectionSessionError);

  return (
    <div>
      {error ? (
        <InlineNotification title="Error" subtitle={error} kind="error" hideCloseButton lowContrast />
      ) : null}
      <Overview sessionID={sessionID} />
      <ProcessGraph />
    </div>
  );
}
