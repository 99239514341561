import {
  Button,
  Toggle,
} from 'carbon-components-react';
import {
  doc,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectQuote } from '../../../features/parts/partsSlice';

function CappingAndMaterials({ partID }) {
  const { id: quoteID, hasPendingWrites, rates } = useSelector(selectQuote(partID));

  if (!rates) {
    return null;
  }

  const initialValues = {
    'capping-thermoplastic': !!rates.capping.thermoplastic,
    'capping-adhesive': !!rates.capping.adhesive,
    'fluidicLayer-pmma': !!rates.fluidicLayer.pmma,
    'fluidicLayer-pc': !!rates.fluidicLayer.pc,
    'fluidicLayer-coc': !!rates.fluidicLayer.coc,
    'fluidicLayer-cop': !!rates.fluidicLayer.cop,
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values) => {
        const newCapping = { ...rates.capping };
        ['thermoplastic', 'adhesive'].forEach((option) => {
          if (values[`capping-${option}`] !== initialValues[`capping-${option}`]) {
            if (values[`capping-${option}`]) {
              newCapping[option] = 100; // old initial value from v0 pricing
            } else {
              delete newCapping[option];
            }
          }
        });

        const newFluidicLayer = { ...rates.fluidicLayer };
        ['pmma', 'pc', 'coc', 'cop'].forEach((option) => {
          if (values[`fluidicLayer-${option}`] !== initialValues[`fluidicLayer-${option}`]) {
            if (values[`fluidicLayer-${option}`]) {
              newFluidicLayer[option] = 200; // old initial value from v0 pricing
            } else {
              delete newFluidicLayer[option];
            }
          }
        });

        const newRates = {
          ...rates,
          capping: { ...newCapping },
          fluidicLayer: { ...newFluidicLayer },
        };
        updateDoc(doc(getFirestore(), 'quotes', quoteID), { rates: newRates });
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        let unsavedChanges = false;
        return (
          <>
            {[
              ['Adhesive capping', 'capping-adhesive'],
              ['Thermoplastic capping', 'capping-thermoplastic'],
              ['PMMA', 'fluidicLayer-pmma'],
              ['PC', 'fluidicLayer-pc'],
              ['COC', 'fluidicLayer-coc'],
              ['COP', 'fluidicLayer-cop'],
            ].map(([label, key]) => {
              if (values[key] !== initialValues[key]) {
                unsavedChanges = true;
              }
              return (
                <Toggle
                  key={key}
                  labelA={`${label} disabled`}
                  labelB={`${label} enabled`}
                  id={key}
                  style={{ marginTop: '0.5rem' }}
                  defaultToggled={values[key]}
                  onToggle={(isOn) => {
                    setFieldValue(key, isOn);
                  }}
                />
              );
            })}
            <Button
              style={{ marginTop: '1rem' }}
              disabled={hasPendingWrites || !unsavedChanges}
              onClick={handleSubmit}
            >
              {hasPendingWrites ? 'Updating...' : 'Save'}
            </Button>
          </>
        );
      }}
    </Formik>
  );
}

CappingAndMaterials.propTypes = {
  partID: PropTypes.string.isRequired,
};

export default CappingAndMaterials;
