import { Accordion, AccordionItem, InlineNotification } from 'carbon-components-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectQuote } from '../../../features/parts/partsSlice';
import Breakpoints from './breakpoints';
import CappingAndMaterials from './cappingAndMaterials';
import CustomMaterials from './customMaterials';
import Leadtime from './leadtime';
import SetupFees from './setup';

function Pricing({ partID }) {
  const { pricingVersion } = useSelector(selectQuote(partID));
  if (pricingVersion !== 'v2') {
    return (
      <InlineNotification
        title="Pricing version conflict."
        subtitle="Please contact Andy to resolve this issue."
        kind="error"
        hideCloseButton
        lowContrast
        style={{ minWidth: '100%', width: '100%' }}
      />
    );
  }

  return (
    <>
      <div className="bx--row">
        <div className="bx--col-lg-4">
          <h4 style={{ marginBottom: '1rem' }}>Setup fees</h4>
          <SetupFees partID={partID} />
        </div>
        <div className="bx--col-lg-12" style={{ marginBottom: '1.5rem' }}>
          <h4 style={{ marginBottom: '1rem' }}>Breakpoints</h4>
          <Breakpoints partID={partID} />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Accordion>
            <AccordionItem title="Advanced options">
              <div className="bx--row">
                <div className="bx--col">
                  <CappingAndMaterials partID={partID} />
                </div>
                <div className="bx--col">
                  <Leadtime partID={partID} />
                </div>
                <div className="bx--col">
                  <CustomMaterials partID={partID} />
                </div>
              </div>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </>
  );
}

Pricing.propTypes = {
  partID: PropTypes.string.isRequired,
};

export default Pricing;
