/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  DataTable,
  InlineNotification,
  NotificationActionButton,
  OverflowMenu,
  OverflowMenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarAction,
  TableToolbarContent,
  TableToolbarMenu,
} from 'carbon-components-react';
import {
  doc,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

import { selectFeedbackChecklist, selectOptionalFeedbackChecklist } from '../../features/feedbackChecklist';
import { selectPart, selectQuote } from '../../features/parts/partsSlice';
import Checklist from './checklist';
import EditFeedback from './editFeedback';

function Feedback({ partID }) {
  const { uid: userID } = useSelector(selectPart(partID));
  const {
    id: quoteID,
    feedback: feedbackIn,
    optionalFeedback: optionalFeedbackIn,
    hasPendingWrites,
    feedbackChecklistVersion,
  } = useSelector(selectQuote(partID));

  const feedback = useMemo(() => feedbackIn || [], [feedbackIn]);
  const optionalFeedback = useMemo(() => optionalFeedbackIn || [], [optionalFeedbackIn]);

  const [focusedFeedbackItem, setFocusedFeedbackItem] = useState({
    title: '',
    text: '',
    image: '',
    checkboxText: '',
    category: '',
  });
  const [updateFeedbackFunc, setUpdateFeedbackFunc] = useState(() => () => {});
  const [isFeedbackEditorOpen, setFeedbackEditorOpen] = useState(false);

  const checklist = useSelector(selectFeedbackChecklist);
  const optionalChecklist = useSelector(selectOptionalFeedbackChecklist);

  if (!feedback) {
    return null;
  }

  return (
    <Formik
      initialValues={{ feedback, optionalFeedback }}
      enableReinitialize
    >
      {({
        values, setFieldValue,
      }) => {
        // check for unsaved changes
        let unsavedChanges = false;
        if (values.feedback?.length !== feedback?.length) {
          unsavedChanges = true;
        } else {
          values.feedback.forEach((item, index) => {
            if (item.title !== feedback[index].title
                || item.text !== feedback[index].text
                || item.checkboxText !== feedback[index].checkboxText
                || (item.image || '') !== (feedback[index].image || '')) {
              unsavedChanges = true;
            }
          });
        }
        if (values.optionalFeedback?.length !== optionalFeedback?.length) {
          unsavedChanges = true;
        } else {
          values.optionalFeedback.forEach((item, index) => {
            if (item.title !== optionalFeedback[index].title
                || item.text !== optionalFeedback[index].text
                || item.checkboxText !== optionalFeedback[index].checkboxText
                || (item.image || '') !== (optionalFeedback[index].image || '')) {
              unsavedChanges = true;
            }
          });
        }
        const blocker = useBlocker(unsavedChanges);
        window.onbeforeunload = unsavedChanges ? () => true : null;

        const headerData = [
          {
            header: 'Title',
            key: 'title',
          },
          {
            header: 'Text',
            key: 'text',
          },
          {
            header: 'Image',
            key: 'image',
          },
          {
            header: 'Checkbox Text',
            key: 'checkboxText',
          },
          {
            header: 'Category',
            key: 'category',
          },
        ];

        // edit a feedback item
        const handleEditFeedbackSection = (type, index) => {
          setFocusedFeedbackItem(values[type][index]);
          setUpdateFeedbackFunc(() => (newFeedback) => {
            setFieldValue(`${type}[${index}].title`, newFeedback.title);
            setFieldValue(`${type}[${index}].text`, newFeedback.text);
            if (newFeedback.checkboxText) {
              setFieldValue(`${type}[${index}].checkboxText`, newFeedback.checkboxText);
            } else {
              setFieldValue(`${type}[${index}].checkboxText`, null);
            }
            if (newFeedback.image) {
              setFieldValue(`${type}[${index}].image`, newFeedback.image);
            } else {
              setFieldValue(`${type}[${index}].image`, null);
            }
          });
          setFeedbackEditorOpen(true);
        };

        // save changes
        const handleSave = () => {
          updateDoc(doc(getFirestore(), 'quotes', quoteID), values);
        };

        // add a new feedback item
        const handleAddFeedbackSection = (newFeedback = {
          title: 'New feedback', text: 'New feedback', checkboxText: '', image: null, category: 'Custom',
        }) => {
          if (newFeedback.category && newFeedback.category !== 'Custom') {
            const quoteRef = doc(getFirestore(), 'quotes', quoteID);
            updateDoc(quoteRef, { [`feedbackChecklistPasses.${newFeedback.category}`]: false });
          }
          setFieldValue('feedback', [...values.feedback, newFeedback]);
        };

        // delete a feedback item
        const handleDeleteFeedbackSection = (type, index) => {
          setFieldValue(type, values[type].filter((item, i) => i !== index));
        };

        const feedbackRows = useMemo(() => values.feedback.map((row, index) => ({ id: `feedback-${index}`, ...row })), [values.feedback]);
        const optionalFeedbackRows = useMemo(() => values.optionalFeedback.map((row, index) => ({ id: `optionalFeedback-${index}`, ...row })), [values.optionalFeedback]);

        return (
          <>
            {typeof document === 'undefined'
              ? null
              : ReactDOM.createPortal(
                <EditFeedback
                  userID={userID}
                  partID={partID}
                  feedback={focusedFeedbackItem}
                  isOpen={isFeedbackEditorOpen}
                  close={() => {
                    setFeedbackEditorOpen(false); setFocusedFeedbackItem({
                      title: '', text: '', image: '', checkboxText: '', category: '',
                    });
                  }}
                  setFeedback={updateFeedbackFunc}
                />,
                document.body,
              )}
            {blocker.state === 'blocked' ? (
              <InlineNotification
                kind="error"
                lowContrast
                hideCloseButton
                title="There are unsaved feedback items."
                subtitle="Save your changes or click the Proceed button to discard your changes."
                style={{ minWidth: '100%', width: '100%' }}
                actions={(
                  <NotificationActionButton
                    onClick={() => { blocker.proceed(); }}
                  >
                    Proceed
                  </NotificationActionButton>
            )}
              />
            ) : null}
            <div className="bx--row">
              { feedbackChecklistVersion ? (
                <div className="bx--col-md-2">
                  <Checklist
                    addFeedback={handleAddFeedbackSection}
                    saveFeedback={handleSave}
                    currentFeedbackCategories={values.feedback.map((row) => (row.category || 'Custom'))}
                    version={feedbackChecklistVersion}
                    partID={partID}
                  />
                </div>
              ) : null }
              <div className="bx--col">
                {feedbackChecklistVersion > 1 ? (
                  <div className="bx--row">
                    <div className="bx--col">
                      <h4>Standard Feedback</h4>
                    </div>
                  </div>
                ) : null}
                <div className="bx--row">
                  <div className="bx--col">
                    <DataTable
                      rows={feedbackRows}
                      headers={headerData}
                    >
                      {({
                        rows, headers, getToolbarProps, getHeaderProps, getTableProps,
                      }) => (
                        <TableContainer>
                          <TableToolbar {...getToolbarProps()}>
                            <TableToolbarContent>
                              <TableToolbarMenu>
                                {checklist.map((category) => (
                                  <TableToolbarAction
                                    onClick={() => handleAddFeedbackSection({
                                      title: category.title, text: category.text, checkboxText: '', image: null, category: category.title,
                                    })}
                                    disabled={hasPendingWrites}
                                    key={category.title}
                                  >
                                    Add &quot;
                                    {category.title}
                                    &quot;
                                  </TableToolbarAction>
                                ))}
                                <TableToolbarAction
                                  onClick={() => handleAddFeedbackSection()}
                                  disabled={hasPendingWrites}
                                >
                                  Add
                                  {' '}
                                  {checklist.length > 0 ? '"Custom" ' : ''}
                                  feedback
                                </TableToolbarAction>
                              </TableToolbarMenu>
                              <Button
                                onClick={handleSave}
                                disabled={!unsavedChanges}
                              >
                                {hasPendingWrites ? 'Upadating...' : 'Save'}
                              </Button>
                            </TableToolbarContent>
                          </TableToolbar>
                          <Table {...getTableProps()}>
                            <TableHead>
                              <TableRow>
                                {headers.map((header) => (
                                  <TableHeader {...getHeaderProps({ header })}>
                                    {header.header}
                                  </TableHeader>
                                ))}
                                <TableHeader key="overflow-menu" />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row, rowIndex) => (
                                <TableRow key={row.id}>
                                  {row.cells.map((cell) => {
                                    if (cell.info.header === 'checkboxText') {
                                      return (
                                        <TableCell key={cell.id}>
                                          {cell.value || <span style={{ fontStyle: 'italic' }}>Accept changes</span>}
                                        </TableCell>
                                      );
                                    }
                                    if (cell.info.header === 'image') {
                                      return (
                                        <TableCell key={cell.id}>
                                          { cell.value
                                            ? cell.value.split('/')[cell.value.split('/').length - 1]
                                            : 'No image'}
                                        </TableCell>
                                      );
                                    }
                                    return <TableCell key={cell.id}>{cell.value}</TableCell>;
                                  })}
                                  <TableCell key={`${row.id}:overflow`} width={32} className="bx--table-column-menu">
                                    <OverflowMenu size="sm" light flipped>
                                      <OverflowMenuItem
                                        key={`${row.id}:overflow:edit`}
                                        itemText="Edit"
                                        onClick={() => { handleEditFeedbackSection('feedback', rowIndex); }}
                                      />
                                      <OverflowMenuItem
                                        key={`${row.id}:overflow:delete`}
                                        isDelete
                                        itemText="Delete"
                                        disabled={values.feedback.length === 1}
                                        onClick={() => { handleDeleteFeedbackSection('feedback', rowIndex); }}
                                      />
                                    </OverflowMenu>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </DataTable>
                  </div>
                </div>
                {feedbackChecklistVersion > 1 ? (
                  <>
                    <div className="bx--row">
                      <div className="bx--col">
                        <h4>Optional Feedback</h4>
                      </div>
                    </div>
                    {values.optionalFeedback && values.optionalFeedback.length > 0 ? (
                      <div className="bx--row">
                        <div className="bx--col">
                          <DataTable
                            rows={optionalFeedbackRows}
                            headers={headerData}
                          >
                            {({
                              rows, headers, getHeaderProps, getTableProps,
                            }) => (
                              <TableContainer>
                                <Table {...getTableProps()}>
                                  <TableHead>
                                    <TableRow>
                                      {headers.map((header) => (
                                        <TableHeader {...getHeaderProps({ header })}>
                                          {header.header}
                                        </TableHeader>
                                      ))}
                                      <TableHeader key="overflow-menu" />
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {rows.map((row, rowIndex) => (
                                      <TableRow key={row.id}>
                                        {row.cells.map((cell) => {
                                          if (cell.info.header === 'checkboxText') {
                                            return (
                                              <TableCell key={cell.id}>
                                                {cell.value || <span style={{ fontStyle: 'italic' }}>Accept changes</span>}
                                              </TableCell>
                                            );
                                          }
                                          if (cell.info.header === 'image') {
                                            return (
                                              <TableCell key={cell.id}>
                                                { cell.value
                                                  ? cell.value.split('/')[cell.value.split('/').length - 1]
                                                  : 'No image'}
                                              </TableCell>
                                            );
                                          }
                                          return <TableCell key={cell.id}>{cell.value}</TableCell>;
                                        })}
                                        <TableCell key={`${row.id}:overflow`} width={32} className="bx--table-column-menu">
                                          <OverflowMenu size="sm" flipped>
                                            <OverflowMenuItem
                                              key={`${row.id}:overflow:edit`}
                                              itemText="Edit"
                                              onClick={() => { handleEditFeedbackSection('optionalFeedback', rowIndex); }}
                                            />
                                            <OverflowMenuItem
                                              key={`${row.id}:overflow:delete`}
                                              isDelete
                                              itemText="Delete"
                                              onClick={() => {
                                                handleDeleteFeedbackSection('optionalFeedback', rowIndex);
                                              }}
                                            />
                                          </OverflowMenu>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )}
                          </DataTable>
                        </div>
                      </div>
                    ) : (
                      <Button
                        onClick={() => { setFieldValue('optionalFeedback', [...optionalChecklist]); }}
                        disabled={hasPendingWrites}
                      >
                        Add corner radius toggle
                      </Button>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
}

Feedback.propTypes = {
  partID: PropTypes.string.isRequired,
};

export default Feedback;
