import { DataShare32 } from '@carbon/icons-react';
import {
  Button,
  Loading,
  Modal,
  TextInput,
} from 'carbon-components-react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { selectPart } from '../../features/parts/partsSlice';
import { getUserByEmail, selectUserIDByEmail } from '../../features/user/userSlice';
import UserEmail from '../UserEmail';

function TransferPart({ partID }) {
  const part = useSelector(selectPart(partID));

  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newUserID, setNewUserID] = useState(null);
  const [finishedSuccessfully, setFinishedSuccessfully] = useState(false);

  return (
    <>
      {typeof document === 'undefined'
        ? null
        : ReactDOM.createPortal(
          <Formik
            initialValues={{ userEmail: '' }}
            enableReinitialize
          >
            {({
              values, handleChange, handleBlur, setFieldValue,
            }) => {
              const [userEmail, setUserEmail] = useState(null);
              const user = useSelector(selectUserIDByEmail(userEmail));
              useEffect(() => {
                if (user && user.id) {
                  setNewUserID(user.id);
                } else {
                  setNewUserID(null);
                }
              }, [user]);

              const handleTransfer = () => {
                if (!part || !part.uid) {
                  return;
                }
                if (!newUserID) {
                  setError('Missing new user ID');
                  return;
                }
                setLoading(true);
                setError(null);
                const functions = getFunctions();
                const transferPart = httpsCallable(functions, 'transferPart');
                transferPart({ partID, oldUserID: part.uid, newUserID })
                  .then(() => { setFinishedSuccessfully(true); })
                  .catch((err) => { setError(err.message); })
                  .finally(() => { setLoading(false); });
              };

              const handleClear = () => {
                setFieldValue('userEmail', '');
                setUserEmail(null);
                setError(null);
                setFinishedSuccessfully(false);
              };

              // Disable transfer for parts created before 2023-04-26
              // See https://github.com/parallel-fluidics/parallel-fluidics/issues/27
              // for more information
              if (part.uploadedAt && part.uploadedAt < 1682467200000) {
                return (
                  <Modal
                    open={isModalOpen}
                    passiveModal
                    onRequestClose={() => { setIsModalOpen(false); }}
                    modalHeading="Unable to transfer part"
                  >
                    <p>
                      This quote uses an outdated data model and cannot be transferred safely.
                      Please contact Andy if you need to transfer this part.
                    </p>
                  </Modal>
                );
              }

              if (finishedSuccessfully) {
                return (
                  <Modal
                    open={isModalOpen}
                    passiveModal
                    onRequestClose={() => { setIsModalOpen(false); handleClear(); }}
                    modalHeading="Transfer part"
                  >
                    <p>Successfully transferred part!</p>
                  </Modal>
                );
              }

              if (error) {
                return (
                  <Modal
                    open={isModalOpen}
                    passiveModal
                    onRequestClose={() => { setIsModalOpen(false); handleClear(); }}
                    modalHeading="Transfer part"
                  >
                    <p>
                      Error:
                      {' '}
                      <span style={{ fontWeight: 800 }}>{error}</span>
                    </p>
                  </Modal>
                );
              }

              return (
                <Modal
                  open={isModalOpen}
                  onRequestClose={() => { setIsModalOpen(false); handleClear(); }}
                  modalHeading="Transfer part"
                  primaryButtonText="Transfer"
                  primaryButtonDisabled={loading || !part || !part.uid || !newUserID}
                  secondaryButtonText="Cancel"
                  onRequestSubmit={handleTransfer}
                >
                  <>
                    {loading && <Loading />}
                    { newUserID ? (
                      <>
                        <p>
                          This part is currently owned by
                          {' '}
                          <span style={{ fontWeight: 800 }}>{part && part.uid ? <UserEmail userID={part.uid} /> : '(Loading...)'}</span>
                          and will be transferred to
                          {' '}
                          <span style={{ fontWeight: 800 }}><UserEmail userID={newUserID} /></span>
                          . Click the button below to complete the transfer.
                        </p>
                        <Button
                          kind="danger"
                          onClick={handleClear}
                          disabled={!newUserID}
                          style={{ marginTop: '1rem', marginRight: '1rem' }}
                        >
                          Choose a different user
                        </Button>
                      </>
                    ) : (
                      <>
                        <p style={{ marginBottom: '1rem' }}>
                          This part is currently owned by
                          {' '}
                          <span style={{ fontWeight: 800 }}>{part && part.uid ? <UserEmail userID={part.uid} /> : '(Loading...)'}</span>
                          . Who would you like to transfer it to?
                        </p>
                        <TextInput
                          id="userEmail"
                          type="text"
                          value={values.userEmail}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={user.status === 'loading' || !!newUserID}
                          labelText="Search for a user by email address"
                          invalid={!!user.error}
                          invalidText={user.error}
                        />
                        <Button
                          onClick={() => {
                            const newEmail = values.userEmail.trim();
                            setUserEmail(newEmail);
                            dispatch(getUserByEmail(newEmail));
                          }}
                          disabled={user.status === 'loading' || !!newUserID}
                          style={{ marginTop: '1rem', marginRight: '1rem' }}
                        >
                          Search
                        </Button>
                      </>
                    )}
                  </>
                </Modal>
              );
            } }
          </Formik>,
          document.body,
        )}
      <Button
        kind="secondary"
        onClick={() => { setIsModalOpen(true); }}
        renderIcon={DataShare32}
      >
        Transfer Part
      </Button>
    </>
  );
}

TransferPart.propTypes = {
  partID: PropTypes.string.isRequired,
};

export default TransferPart;
