import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { usePLCValue } from '../../../features/plc/context';
import {
  useHFTSHandle,
  useProgramHandle,
} from '../hooks/context';
import PLCNumberInput from './plcNumberInput';
import PLCTag from './plcTag';
import PLCToggleButton from './plcToggleButton';
import PLCValue from './plcValue';

const disengagedTempCalculations = {
  Upper: {
    front: (value) => parseFloat((1.5702 * value - 97.4825).toFixed(1)),
    rear: (value) => parseFloat((value + 15).toFixed(1)),
  },
  Lower: {
    front: (value) => parseFloat((1.5000 * value - 42.5).toFixed(1)),
    rear: (value) => value,
  },
  ChipSide: {
    front: (value) => parseFloat((1.5702 * value - 97.4825).toFixed(1)),
    rear: (value) => parseFloat((value + 15).toFixed(1)),
  },
  CapSide: {
    front: (value) => parseFloat((1.5000 * value - 42.5).toFixed(1)),
    rear: (value) => value,
  },
};

function HFTS({ side }) {
  const programHandle = useProgramHandle();
  const hftsHandle = useHFTSHandle(side);

  const frontDisengagedSetpoint = usePLCValue(`${hftsHandle}.fFrontHeaterEngagedSetpoint`);
  const suggestedFrontDisengagedSetpoint = useMemo(
    () => disengagedTempCalculations[side].front(frontDisengagedSetpoint),
    [frontDisengagedSetpoint],
  );

  const rearDisengagedSetpoint = usePLCValue(`${hftsHandle}.fRearHeaterEngagedSetpoint`);
  const suggestedRearDisengagedSetpoint = useMemo(
    () => disengagedTempCalculations[side].rear(rearDisengagedSetpoint),
    [rearDisengagedSetpoint],
  );

  return (
    <div className="bx--row">
      <div className="bx--col-lg-4">
        <div className="bx--row">
          <div className="bx--col">
            <h4>Hot Block heater</h4>
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCToggleButton
              handle={`${programHandle}.b${side}HotBlockHeaterSystemOn`}
              label="heater"
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCNumberInput
              label="Setpoint"
              description={<br />}
              handle={`${hftsHandle}.fHotBlockSetpoint`}
              type="float"
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <p style={{ textAlign: 'center', fontSize: 16 }}>
              Current Temp:
              {' '}
              <PLCValue handle={`${programHandle}.${side}HotRTD`} format={(value) => `${value / 10} °C`} style={{ fontWeight: 800 }} />
            </p>
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCTag
              style={{ width: '100%' }}
              handle={`${programHandle}.${side}HotHeater`}
              onColor="red"
              offColor="gray"
            />
          </div>
        </div>
      </div>
      <div className="bx--col-lg-4">
        <div className="bx--row">
          <div className="bx--col">
            <h4>Front Block heater</h4>
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCToggleButton
              handle={`${programHandle}.b${side}FrontHeaterSystemOn`}
              label="heater"
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCNumberInput
              label="Disengaged Setpoint"
              description={`Suggested: ${suggestedFrontDisengagedSetpoint}°C`}
              handle={`${hftsHandle}.fFrontHeaterDisengagedSetpoint`}
              type="float"
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <p style={{ textAlign: 'center', fontSize: 16 }}>
              Current Temp:
              {' '}
              <PLCValue handle={`${programHandle}.${side}FrontRTD`} format={(value) => `${value / 10} °C`} style={{ fontWeight: 800 }} />
            </p>
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCTag
              style={{ width: '100%' }}
              handle={`${programHandle}.${side}FrontHeater`}
              onColor="red"
              offColor="gray"
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCNumberInput
              label="Tool Setpoint"
              handle={`${hftsHandle}.fFrontHeaterEngagedSetpoint`}
              type="float"
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCNumberInput
              label="Lower Band"
              handle={`${hftsHandle}.fSetpointLowerBand`}
              type="float"
            />
          </div>
          <div className="bx--col">
            <PLCNumberInput
              label="Upper Band"
              handle={`${hftsHandle}.fSetpointUpperBand`}
              type="float"
            />
          </div>
        </div>
      </div>
      <div className="bx--col-lg-4">
        <div className="bx--row">
          <div className="bx--col">
            <h4>Rear (Standby) Block heater</h4>
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCToggleButton
              handle={`${programHandle}.b${side}RearHeaterSystemOn`}
              label="heater"
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCNumberInput
              handle={`${hftsHandle}.fRearHeaterDisengagedSetpoint`}
              label="Disengaged Setpoint"
              description={`Suggested: ${suggestedRearDisengagedSetpoint}°C`}
              type="float"
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <p style={{ textAlign: 'center', fontSize: 16 }}>
              Current Temp:
              <PLCValue handle={`${programHandle}.${side}RearRTD`} format={(value) => `${value / 10} °C`} style={{ fontWeight: 800 }} />
            </p>
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCTag
              style={{ width: '100%' }}
              handle={`${programHandle}.${side}RearHeater`}
              onColor="red"
              offColor="gray"
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCNumberInput
              label="Tool Standby"
              handle={`${hftsHandle}.fRearHeaterEngagedSetpoint`}
              type="float"
            />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <PLCNumberInput
              label="Lower Band"
              handle={`${hftsHandle}.fStandbyLowerBand`}
              type="float"
            />
          </div>
          <div className="bx--col">
            <PLCNumberInput
              label="Upper Band"
              handle={`${hftsHandle}.fStandbyUpperBand`}
              type="float"
            />
          </div>
        </div>
      </div>
      <div className="bx--col-lg-4" />
    </div>
  );
}

HFTS.propTypes = {
  side: PropTypes.oneOf(['Upper', 'Lower', 'ChipSide', 'CapSide']).isRequired,
};

export default HFTS;
export { disengagedTempCalculations };
