import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  optionalItems: [],
  isComplete: false,
};

export const feedbackChecklistSlice = createSlice({
  name: 'feedbackChecklist',
  initialState,
  reducers: {
    setFeedbackChecklistItems: (state, action) => {
      state.items = action.payload.items || [];
      state.optionalItems = action.payload.optionalItems || [];
    },
    setIsComplete: (state, action) => {
      state.isComplete = action.payload;
    },
    resetFeedbackChecklist: () => initialState,
  },
});

export const {
  setFeedbackChecklistItems, setIsComplete, resetFeedbackChecklist,
} = feedbackChecklistSlice.actions;

export const selectFeedbackChecklist = (state) => state.feedbackChecklist.items || [];

export const selectOptionalFeedbackChecklist = (
  (state) => state.feedbackChecklist.optionalItems || []
);

export const selectIsFeedbackChecklistComplete = (state) => state.feedbackChecklist.isComplete;

export default feedbackChecklistSlice.reducer;
