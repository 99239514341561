import { Link32 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ConversionStatus from './conversionStatus';
import OpenInFusion from './openInFusion';
import ForgeProjectSelector from './projectSelector';
import ForgeReauthButton from './reauth';

function ForgeProjectSelectorLauncher({ partID }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        renderIcon={Link32}
        onClick={() => { setIsOpen(true); }}
        kind="secondary"
      >
        Link Fusion project
      </Button>
      <ForgeProjectSelector partID={partID} isOpen={isOpen} close={() => setIsOpen(false)} />
    </>
  );
}

ForgeProjectSelectorLauncher.propTypes = {
  partID: PropTypes.string.isRequired,
};

export default ForgeProjectSelectorLauncher;
export {
  ConversionStatus,
  ForgeReauthButton,
  ForgeProjectSelector,
  OpenInFusion,
};
