import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFunctions, httpsCallable } from 'firebase/functions';

const initialState = {
  usersByID: {},
  usersByEmail: {},
};

export const getUserByEmail = createAsyncThunk(
  'user/getIDByEmail',
  async (userEmail, { rejectWithValue }) => {
    try {
      const functions = getFunctions();
      const getUserIDByEmail = httpsCallable(functions, 'getUserIDByEmail');
      const result = await getUserIDByEmail({ userEmail });
      return { userEmail, userID: result.data };
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
  {
    condition: (userEmail, { getState }) => {
      const { user } = getState();

      // check if user is already in the store
      if (!user.usersByEmail[userEmail]) {
        return true;
      }

      // check if user is already loading
      if (user.usersByEmail[userEmail].status === 'loading' || user.usersByEmail[userEmail].status === 'fulfilled') {
        return false;
      }

      return true;
    },
  },
);

export const getUserByID = createAsyncThunk(
  'user/getEmailByID',
  async (userID, { rejectWithValue }) => {
    try {
      const functions = getFunctions();
      const getUserEmailByID = httpsCallable(functions, 'getUserEmailByID');
      const result = await getUserEmailByID({ userID });
      if (result.data) {
        return { userID, userEmail: result.data, isAnonymous: false };
      }
      return { userID, userEmail: result.data, isAnonymous: true };
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
  {
    condition: (userID, { getState }) => {
      const { user } = getState();

      // check if user is already in the store
      if (!user.usersByID[userID]) {
        return true;
      }

      // check if user is already loading
      if (user.usersByID[userID].status === 'loading' || user.usersByID[userID].status === 'fulfilled') {
        return false;
      }

      return true;
    },
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // getUserByEmail
      .addCase(getUserByEmail.pending, (state, action) => {
        state.usersByEmail[action.meta.arg] = {
          status: 'loading',
          id: null,
          error: null,
        };
      })
      .addCase(getUserByEmail.rejected, (state, action) => {
        state.usersByEmail[action.meta.arg] = {
          status: 'fulfilled',
          id: null,
          error: action.payload,
        };
      })
      .addCase(getUserByEmail.fulfilled, (state, action) => {
        state.usersByEmail[action.payload.userEmail] = {
          status: 'fulfilled',
          id: action.payload.userID,
          error: null,
        };
        state.usersByID[action.payload.userID] = {
          status: 'fulfilled',
          email: action.payload.userEmail,
          error: null,
        };
      })
      // getUserByID
      .addCase(getUserByID.pending, (state, action) => {
        state.usersByID[action.meta.arg] = {
          status: 'loading',
          email: null,
          error: null,
        };
      })
      .addCase(getUserByID.rejected, (state, action) => {
        state.usersByID[action.meta.arg] = {
          status: 'fulfilled',
          email: null,
          error: action.payload,
        };
      })
      .addCase(getUserByID.fulfilled, (state, action) => {
        if (action.payload.isAnonymous) {
          state.usersByID[action.payload.userID] = {
            status: 'fulfilled',
            isAnonymous: true,
            error: null,
          };
        } else {
          state.usersByEmail[action.payload.userEmail] = {
            status: 'fulfilled',
            id: action.payload.userID,
            error: null,
          };
          state.usersByID[action.payload.userID] = {
            status: 'fulfilled',
            email: action.payload.userEmail,
            isAnonymous: false,
            error: null,
          };
        }
      });
  },
});

export const selectUserIDByEmail = (userEmail) => (state) => (
  (userEmail && (state.user.usersByEmail[userEmail] || {})) || {});
export const selectUserEmailByID = (userID) => (state) => (
  (userID && (state.user.usersByID[userID] || {})) || {});

export default userSlice.reducer;
