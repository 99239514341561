/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  DataTable,
  Modal,
  OverflowMenu,
  OverflowMenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarAction,
  TableToolbarContent,
  TableToolbarMenu,
  TextInput,
} from 'carbon-components-react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  quantity: Yup.number().required().min(1).integer(),
  multiplier: Yup.number().required().min(0),
});

function EditBreakpoint({
  breakpoint, setBreakpoint, isOpen, close,
}) {
  return (
    <Formik
      initialValues={breakpoint}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        setBreakpoint(values);
        close();
        setSubmitting(false);
      }}
    >
      {({
        values, errors, handleBlur, handleChange, handleSubmit,
      }) => (
        <Modal
          size="lg"
          open={isOpen}
          onRequestClose={() => { close(); }}
          modalHeading="Edit breakpoint"
          primaryButtonText="Update"
          secondaryButtonText="Cancel"
          onRequestSubmit={handleSubmit}
        >
          <div className="bx--row">
            <div className="bx--col">
              <TextInput
                labelText="Quantity"
                id="quantity"
                key="quantity"
                value={values.quantity}
                onBlur={handleBlur}
                onChange={handleChange}
                invalidText={errors.quantity}
                invalid={!!errors.quantity}
              />
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <TextInput
                labelText="Multiplier"
                id="multiplier"
                key="multipler"
                value={values.multiplier}
                onBlur={handleBlur}
                onChange={handleChange}
                invalidText={errors.multiplier}
                invalid={!!errors.multiplier}
              />
            </div>
          </div>
        </Modal>
      )}
    </Formik>
  );
}

EditBreakpoint.propTypes = {
  breakpoint: PropTypes.shape({
    quantity: PropTypes.number,
    multiplier: PropTypes.number,
  }).isRequired,
  setBreakpoint: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

function Breakpoints({
  breakpoints: breakPointsIn,
  setFieldValue,
  setFieldTouched,
  uncappedBasePrice,
  uncappedUnscaledPrice,
  cappedBasePrice,
  cappedUnscaledPrice,
  disabled,
  touched,
  isSubmitting,
  onSubmit: handleSubmit,
}) {
  const [focusedBreakpointItem, setFocusedBreakpointItem] = useState({
    quantity: 0,
    multiplier: 0,
  });
  const [updateBreakpointFunc, setUpdateBreakpointFunc] = useState(() => () => {});
  const [isBreakpointEditorOpen, setBreakpointEditorOpen] = useState(false);

  const breakpoints = useMemo(() => {
    if (!breakPointsIn) return [];
    return [...breakPointsIn].sort((a, b) => a.quantity - b.quantity);
  }, [breakPointsIn]);

  const headerData = [
    {
      header: 'Quantity',
      key: 'quantity',
    },
    {
      header: 'Multiplier',
      key: 'multiplier',
    },
    {
      header: 'Uncapped Unit Price',
      key: 'uncappedPrice',
    },
    {
      header: 'Capped Unit Price',
      key: 'cappedPrice',
    },
  ];
  return (
    <>
      {typeof document === 'undefined'
        ? null
        : ReactDOM.createPortal(
          <EditBreakpoint
            breakpoint={focusedBreakpointItem}
            isOpen={isBreakpointEditorOpen}
            close={() => {
              setBreakpointEditorOpen(false);
              setFocusedBreakpointItem({ quantity: 0, multiplier: 0 });
            }}
            setBreakpoint={updateBreakpointFunc}
          />,
          document.body,
        )}
      <DataTable
        rows={breakpoints.map((row, index) => ({
          id: `breakpoint-${index}`,
          quantity: row.quantity,
          multiplier: row.multiplier,
          uncappedPrice: (uncappedBasePrice * row.multiplier + uncappedUnscaledPrice).toFixed(2),
          cappedPrice: (cappedBasePrice * row.multiplier + cappedUnscaledPrice).toFixed(2),
        }))}
        headers={headerData}
      >
        {({
          rows, headers, getToolbarProps, getHeaderProps, getTableProps,
        }) => {
          // edit a breakpoint
          const handleEditBreakpoint = (index) => {
            setFocusedBreakpointItem({
              quantity: breakpoints[index].quantity,
              multiplier: breakpoints[index].multiplier,
            });
            setUpdateBreakpointFunc(() => (newBreakpoint) => {
              setFieldValue(`breakpoints[${index}].quantity`, parseFloat(newBreakpoint.quantity));
              setFieldTouched(`breakpoints[${index}].quantity`);
              setFieldValue(`breakpoints[${index}].multiplier`, parseFloat(newBreakpoint.multiplier));
              setFieldTouched(`breakpoints[${index}].multiplier`);
            });
            setBreakpointEditorOpen(true);
          };

          // add a new breakpoint
          const handleAddBreakpoint = () => {
            const newBreakpoint = {
              quantity: 20,
              multiplier: 1.0,
            };
            setFieldValue('breakpoints', [...breakpoints, newBreakpoint]);
            setFieldTouched('breakpoints');
          };

          // delete a breakpoint
          const handleDeleteBreakpoint = (index) => {
            setFieldValue('breakpoints', breakpoints.filter((item, i) => i !== index));
            setFieldTouched('breakpoints');
          };

          return (
            <TableContainer>
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <TableToolbarMenu>
                    <TableToolbarAction
                      onClick={handleAddBreakpoint}
                      disabled={disabled || isSubmitting}
                    >
                      Add new breakpoint
                    </TableToolbarAction>
                  </TableToolbarMenu>
                  <Button
                    disabled={!touched || disabled || isSubmitting}
                    onClick={handleSubmit}
                  >
                    {isSubmitting ? 'Please wait...' : 'Save'}
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                    <TableHeader key="overflow-menu" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, rowIndex) => (
                    <TableRow key={row.id}>
                      {row.cells.map((cell) => {
                        switch (cell.info.header) {
                          case 'uncappedPrice':
                          case 'cappedPrice': {
                            return (
                              <TableCell key={cell.id}>
                                { new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value) }
                              </TableCell>
                            );
                          }
                          default: {
                            return <TableCell key={cell.id}>{cell.value}</TableCell>;
                          }
                        }
                      })}
                      <TableCell key={`${row.id}:overflow`} width={32} className="bx--table-column-menu">
                        <OverflowMenu size="sm" light flipped>
                          <OverflowMenuItem
                            key={`${row.id}:overflow:edit`}
                            itemText="Edit"
                            onClick={() => { handleEditBreakpoint(rowIndex); }}
                            disabled={disabled || isSubmitting}
                          />
                          <OverflowMenuItem
                            key={`${row.id}:overflow:delete`}
                            isDelete
                            itemText="Delete"
                            disabled={breakpoints.length === 2 || disabled || isSubmitting}
                            onClick={() => { handleDeleteBreakpoint(rowIndex); }}
                          />
                        </OverflowMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </DataTable>
    </>
  );
}

Breakpoints.propTypes = {
  breakpoints: PropTypes.arrayOf(PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    multiplier: PropTypes.number.isRequired,
  })).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  uncappedBasePrice: PropTypes.number.isRequired,
  uncappedUnscaledPrice: PropTypes.number.isRequired,
  cappedBasePrice: PropTypes.number.isRequired,
  cappedUnscaledPrice: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

Breakpoints.defaultProps = {
  disabled: false,
  touched: false,
};

export default Breakpoints;
