import { InlineNotification } from 'carbon-components-react';
import React from 'react';
import { useParams } from 'react-router-dom';

import SummaryTable from '../../../components/ProcessData/v1';

export default function SummaryPage() {
  const { version, processType } = useParams();

  if (!['forming', 'bonding'].includes(processType)) {
    return (
      <InlineNotification
        title="Invalid process"
        subtitle={`"${processType}" is not a valid process`}
        kind="error"
        hideCloseButton
        lowContrast
        style={{ minWidth: '100%', width: '100%' }}
      />
    );
  }

  return <SummaryTable processType={processType} version={version} pageSize={25} />;
}
