import {
  Button,
  TextInput,
} from 'carbon-components-react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import {
  getUserByEmail,
  selectUserIDByEmail,
} from '../../features/user/userSlice';

function UserSearch() {
  const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState(null);
  const user = useSelector(selectUserIDByEmail(userEmail));
  const initialValues = { userEmail: '' };

  if (!user.id) {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          setUserEmail(values.userEmail);
          dispatch(getUserByEmail(values.userEmail));
        }}
      >
        {({
          values, handleBlur, handleChange, handleSubmit,
        }) => (
          <>
            <TextInput
              id="userEmail"
              type="text"
              disabled={user.status === 'loading'}
              labelText="Search for a user by email address"
              invalid={user.error}
              invalidText={user.error}
              value={values.userEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
            />
            <Button
              onClick={handleSubmit}
              disabled={user.status === 'loading'}
              style={{ marginTop: '1rem' }}
            >
              Search
            </Button>
          </>
        )}
      </Formik>
    );
  }

  return <Navigate to={`/user/${user.id}`} />;
}

export default UserSearch;
