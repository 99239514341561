import {
  ContentSwitcher,
  Switch,
} from 'carbon-components-react';
import {
  collection,
  getFirestore,
  limit,
  orderBy,
  query,
} from 'firebase/firestore';
import React, { useState } from 'react';

import OrdersList from '../../components/OrdersList';
import PartsList from '../../components/PartsList';
import UserSearch from '../../components/UserSearch';

function Home() {
  const params = new URLSearchParams(window.location.search);
  const uploadLimit = Number.parseInt(params.get('uploadLimit'), 10) || 20;

  const userPartsQuery = query(collection(getFirestore(), 'parts'), orderBy('uploadedAt', 'desc'), limit(uploadLimit));
  const [visibleSection, setVisibleSection] = useState('orders');

  return (
    <>
      <div className="bx--row">
        <div className="bx--col">
          <UserSearch />
        </div>
      </div>
      <ContentSwitcher onChange={(tab) => { setVisibleSection(tab.name); }} style={{ marginBottom: '1rem' }}>
        <Switch name="orders" text="Orders" />
        <Switch name="uploads" text="Recent Uploads" />
      </ContentSwitcher>
      <div className="bx--row" style={visibleSection !== 'orders' ? { display: 'none' } : {}}>
        <div className="bx--col">
          <OrdersList />
        </div>
      </div>
      <div className="bx--row" style={visibleSection !== 'uploads' ? { display: 'none' } : {}}>
        <div className="bx--col">
          <PartsList
            title="Recent uploads"
            partsQuery={userPartsQuery}
          />
        </div>
      </div>
    </>
  );
}

export default Home;
