import '@carbon/charts/styles.css';

import { LineChart } from '@carbon/charts-react';
import { Button, NumberInput } from 'carbon-components-react';
import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectCurrentSession,
  selectCurrentSessionInstronData,
  selectCurrentSessionProcessData,
  selectEndOffset,
  selectIsCurrentSessionPLCDataLoading,
  setEndOffset,
} from '../../features/processData/processDataSlice';

function ProcessGraph() {
  const ref = useRef();
  const dispatch = useDispatch();

  const session = useSelector(selectCurrentSession);
  const endOffset = useSelector(selectEndOffset);
  const isPLCDataLoading = useSelector(selectIsCurrentSessionPLCDataLoading);
  const plcPoints = useSelector(selectCurrentSessionProcessData);
  const instronData = useSelector(selectCurrentSessionInstronData);

  const [chartData, defaultSelectedGroups] = useMemo(() => {
    if (!plcPoints) {
      return [];
    }

    const selectedGroups = ['Top Temp', 'Bottom Temp'];
    const tempData = [];

    if (instronData.timestamps && instronData.timestamps.length > 0) {
      for (let i = 0; i < instronData.timestamps.length; i += 1) {
        tempData.push({ group: 'Force', timestamp: instronData.timestamps[i], force: instronData.forces[i] });
      }
      selectedGroups.push('Force');
    }

    for (let i = 0; i < plcPoints.length; i += 1) {
      const {
        LowerHFTS_ToolTemp: lowerHFTSToolTemp,
        LowerHFTS_HP1Temp: lowerHFTSHP1Temp,
        LowerHFTS_HP2Temp: lowerHFTSHP2Temp,
        LowerHFTS_HP3Temp: lowerHFTSHP3Temp,
        LowerHFTS_HP4Temp: lowerHFTSHP4Temp,
        LowerHFTS_HP5Temp: lowerHFTSHP5Temp,
        LowerHFTS_HP6Temp: lowerHFTSHP6Temp,
        UpperHFTS_ToolTemp: upperHFTSToolTemp,
        UpperHFTS_HP1Temp: upperHFTSHP1Temp,
        UpperHFTS_HP2Temp: upperHFTSHP2Temp,
        UpperHFTS_HP3Temp: upperHFTSHP3Temp,
        UpperHFTS_HP4Temp: upperHFTSHP4Temp,
        UpperHFTS_HP5Temp: upperHFTSHP5Temp,
        UpperHFTS_HP6Temp: upperHFTSHP6Temp,
        timestamp,
      } = plcPoints[i];

      tempData.push({ group: 'Top Temp', timestamp: new Date(timestamp), temperature: upperHFTSToolTemp });
      tempData.push({ group: 'Bottom Temp', timestamp: new Date(timestamp), temperature: lowerHFTSToolTemp });
      tempData.push({ group: 'Top HP1', timestamp: new Date(timestamp), temperature: upperHFTSHP1Temp });
      tempData.push({ group: 'Top HP2', timestamp: new Date(timestamp), temperature: upperHFTSHP2Temp });
      tempData.push({ group: 'Top HP3', timestamp: new Date(timestamp), temperature: upperHFTSHP3Temp });
      tempData.push({ group: 'Top HP4', timestamp: new Date(timestamp), temperature: upperHFTSHP4Temp });
      tempData.push({ group: 'Top HP5', timestamp: new Date(timestamp), temperature: upperHFTSHP5Temp });
      tempData.push({ group: 'Top HP6', timestamp: new Date(timestamp), temperature: upperHFTSHP6Temp });
      tempData.push({ group: 'Bottom HP1', timestamp: new Date(timestamp), temperature: lowerHFTSHP1Temp });
      tempData.push({ group: 'Bottom HP2', timestamp: new Date(timestamp), temperature: lowerHFTSHP2Temp });
      tempData.push({ group: 'Bottom HP3', timestamp: new Date(timestamp), temperature: lowerHFTSHP3Temp });
      tempData.push({ group: 'Bottom HP4', timestamp: new Date(timestamp), temperature: lowerHFTSHP4Temp });
      tempData.push({ group: 'Bottom HP5', timestamp: new Date(timestamp), temperature: lowerHFTSHP5Temp });
      tempData.push({ group: 'Bottom HP6', timestamp: new Date(timestamp), temperature: lowerHFTSHP6Temp });
    }

    return [tempData, selectedGroups];
  }, [plcPoints, instronData]);

  const options = {
    axes: {
      bottom: {
        title: 'Time',
        mapsTo: 'timestamp',
        scaleType: 'time',
      },
      left: {
        mapsTo: 'temperature',
        title: 'Temperature (F)',
        scaleType: 'linear',
        includeZero: false,
      },
      right: {
        mapsTo: 'force',
        title: 'Force (kN)',
        scaleType: 'linear',
        correspondingDatasets: [
          'Force',
        ],
      },
    },
    curve: 'curveLinear',
    data: {
      loading: isPLCDataLoading,
      selectedGroups: defaultSelectedGroups,
    },
    points: { enabled: false },
    height: '600px',
    tooltip: {
      showTotal: false,
      valueFormatter: (value) => {
        if (value instanceof Date) {
          return value.toLocaleDateString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric' }).split(' ')[1];
        }
        return value;
      },
    },
    legend: {
      truncation: { type: 'none' },
    },
  };

  if (!chartData) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {endOffset ? (
        <>
          <div className="bx--row">
            <div className="bx--col">
              <p>
                {session.end ? null : 'End time wasn\'t captured correctly. '}
                Use the options below to manually set the time window (in minutes).
              </p>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <NumberInput
                id="offset"
                max={1000}
                min={0}
                step={1}
                defaultValue={endOffset}
                ref={ref}
                disabled={isPLCDataLoading}
              />
            </div>
            <div className="bx--col">
              <Button
                onClick={() => {
                  const newValue = Number.parseInt(ref.current?.value, 10);
                  dispatch(setEndOffset({ offset: newValue * 60 }));
                }}
                disabled={isPLCDataLoading}
                size="md"
              >
                Reload
              </Button>
            </div>
            <div className="bx--col" />
            <div className="bx--col" />
          </div>
        </>
      ) : null}
      <div className="bx--row">
        <div className="bx--col">
          <LineChart
            data={chartData}
            options={options}
          />
        </div>
      </div>
    </>
  );
}

export default ProcessGraph;
