import { Button } from 'carbon-components-react';
import React from 'react';

const REAUTH_URL = (
  'https://developer.api.autodesk.com/authentication/v1/authorize?'
  + 'response_type=code&'
  + 'client_id=q1FIj3QL6Gbhsl3PSDn7HeUhxuRZAHeL&'
  + `redirect_uri=${encodeURIComponent(`${window.location.protocol}//${window.location.host}/forge/callback`)}&`
  + 'scope=data%3Aread'
);

function ForgeReauthButton() {
  return (
    <Button onClick={() => { window.open(REAUTH_URL); }}>
      Reauthenticate with Autodesk Forge
    </Button>
  );
}

export default ForgeReauthButton;
