import { Tag } from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { usePLCValue } from '../../../features/plc/context';
import FirebaseSession from '../components/firebaseSession';
import PLCTag from '../components/plcTag';
import PLCToggleButton from '../components/plcToggleButton';
import PLCValue from '../components/plcValue';
import { titleify, usePressHandle, useProgramHandle } from '../hooks/context';
import useHFTSTarget from '../hooks/useHFTSTarget';

function HeaterStatus({ side }) {
  const programHandle = useProgramHandle();
  const target = useHFTSTarget(side);
  const title = useMemo(() => titleify(side), [side]);

  return (
    <div className="bx--row">
      <div className="bx--col">
        <p style={{ textAlign: 'center', fontSize: 16 }}>
          {`${title} Temp: `}
          <PLCValue
            handle={`${programHandle}.${side}ToolRTD`}
            format={(value) => `${value / 10} °C`}
            style={{ fontWeight: 800 }}
          />
          {' '}
          (Target:
          {' '}
          {typeof target === 'number' ? `${target}°C` : `${target}`}
          )
        </p>
      </div>
    </div>
  );
}

HeaterStatus.propTypes = {
  side: PropTypes.oneOf(['ChipSide', 'CapSide']).isRequired,
};

function SystemStatus() {
  const pressHandle = usePressHandle();
  const programHandle = useProgramHandle();

  const capSideHotBlockHeaterSystemOn = usePLCValue(`${programHandle}.bCapSideHotBlockHeaterSystemOn`);
  const capSideRearHeaterSystemOn = usePLCValue(`${programHandle}.bCapSideRearHeaterSystemOn`);
  const capSideFrontHeaterSystemOn = usePLCValue(`${programHandle}.bCapSideFrontHeaterSystemOn`);
  const chipSideHotBlockHeaterSystemOn = usePLCValue(`${programHandle}.bChipSideHotBlockHeaterSystemOn`);
  const chipSideRearHeaterSystemOn = usePLCValue(`${programHandle}.bChipSideRearHeaterSystemOn`);
  const chipSideFrontHeaterSystemOn = usePLCValue(`${programHandle}.bChipSideFrontHeaterSystemOn`);

  const allHeatersOn = useMemo(() => [
    capSideHotBlockHeaterSystemOn,
    capSideRearHeaterSystemOn,
    capSideFrontHeaterSystemOn,
    chipSideHotBlockHeaterSystemOn,
    chipSideRearHeaterSystemOn,
    chipSideFrontHeaterSystemOn,
  ].every((heater) => heater), [
    capSideHotBlockHeaterSystemOn,
    capSideRearHeaterSystemOn,
    capSideFrontHeaterSystemOn,
    chipSideHotBlockHeaterSystemOn,
    chipSideRearHeaterSystemOn,
    chipSideFrontHeaterSystemOn,
  ]);

  return (
    <div className="bx--col-lg-4">
      <div className="bx--row">
        <div className="bx--col">
          <h4>System Status</h4>
        </div>
      </div>
      <h6 style={{ textAlign: 'center' }}>Chip Side</h6>
      <HeaterStatus side="ChipSide" />
      <h6 style={{ textAlign: 'center' }}>Cap Side</h6>
      <HeaterStatus side="CapSide" />
      <FirebaseSession />
      <div className="bx--row" style={{ marginTop: 16 }}>
        <div className="bx--col">
          <PLCTag
            handle={`${programHandle}.AirStatus`}
            onText="Air On"
            offText="Air Off"
            style={{ width: '100%', height: '3rem' }}
          />
        </div>
        <div className="bx--col">
          <PLCTag
            handle={`${programHandle}.ChillerStatus`}
            onText="Chiller On"
            offText="Chiller Off"
            style={{ width: '100%', height: '3rem' }}
          />
        </div>
      </div>
      <div className="bx--row" style={{ marginTop: 16 }}>
        <div className="bx--col">
          <Tag type={allHeatersOn ? 'green' : 'purple'} style={{ width: '100%', height: '3rem' }}>
            {allHeatersOn ? 'All Heaters On' : 'Some (or All) Heaters Off'}
          </Tag>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <PLCTag
            handle={`${programHandle}.InstronAudio`}
            onText="Instron Audio On"
            offText="Instron Audio Off"
            offColor="gray"
            style={{ width: '100%', height: '3rem' }}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <PLCToggleButton
            handle={`${pressHandle}.Vacuum`}
            label="vacuum"
          />
        </div>
      </div>
    </div>
  );
}

export default SystemStatus;
