import {
  DataTable,
  DataTableSkeleton,
  SkeletonText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from 'carbon-components-react';
import {
  doc, getDocs, getFirestore, onSnapshot, Query,
} from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  removePart, selectParts, setPart, setQuote,
} from '../../features/parts/partsSlice';
import { OpenInFusion } from '../Forge';
import QuoteStatusTag from '../QuoteStatusTag';
import UserEmail from '../UserEmail';

function PartsList({ title, partsQuery }) {
  const dispatch = useDispatch();

  const [partsAreLoading, setPartsAreLoading] = useState(false);
  const [partIDs, setPartIDs] = useState([]);
  const [quoteIDs, setQuoteIDs] = useState([]);
  const [partsError, setPartsError] = useState(null);

  useEffect(() => {
    setPartsAreLoading(true);

    getDocs(partsQuery)
      .then((querySnapshot) => {
        const newPartIDs = [];
        const newQuoteIDs = [];
        querySnapshot.docs.forEach((part) => {
          newPartIDs.push(part.id);
          newQuoteIDs.push(part.data().quote);
          dispatch(setPart({
            id: part.id,
            ...part.data(),
          }));
        });
        setPartIDs(newPartIDs);
        setQuoteIDs(newQuoteIDs);
      })
      .catch((err) => {
        setPartsError(err.message);
      })
      .finally(() => {
        setPartsAreLoading(false);
      });
  }, [partsQuery]);

  // subscribe to part updates
  useEffect(() => {
    if (!partIDs) {
      return () => {};
    }

    const unsubscribeFuncs = [];
    partIDs.forEach((partID) => {
      unsubscribeFuncs.push(onSnapshot(doc(getFirestore(), 'parts', partID), { includeMetadataChanges: true }, (snapshot) => {
        dispatch(setPart({
          id: partID,
          hasPendingWrites: snapshot.metadata.hasPendingWrites,
          ...snapshot.data(),
        }));
      }));
    });
    return () => {
      unsubscribeFuncs.forEach((func) => { func(); });
      partIDs.forEach((partID) => { dispatch(removePart({ id: partID })); });
    };
  }, [partIDs]);

  // subscribe to quote updates
  useEffect(() => {
    if (!quoteIDs) {
      return () => {};
    }

    const unsubscribeFuncs = [];
    quoteIDs.forEach((quoteID) => {
      unsubscribeFuncs.push(onSnapshot(doc(getFirestore(), 'quotes', quoteID), { includeMetadataChanges: true }, (snapshot) => {
        dispatch(setQuote({
          id: quoteID,
          hasPendingWrites: snapshot.metadata.hasPendingWrites,
          ...snapshot.data(),
        }));
      }));
    });
    return () => {
      unsubscribeFuncs.forEach((func) => { func(); });
      quoteIDs.forEach((quoteID) => { dispatch(removePart({ id: quoteID })); });
    };
  }, [quoteIDs]);

  const parts = useSelector(selectParts(partIDs));

  const headerData = [
    {
      header: 'Filename',
      key: 'filename',
      isSortable: true,
    },
    {
      header: 'Status',
      key: 'status',
      isSortable: true,
    },
    {
      header: 'Upload Date',
      key: 'uploadedAt',
      isSortable: true,
    },
    {
      header: 'User',
      key: 'user',
      isSortable: true,
    },
    {
      header: 'Fusion',
      key: 'fusion',
      isSortable: false,
    },
    {
      header: 'Quote',
      key: 'quoteLinks',
      isSortable: false,
    },
  ];

  if (partsAreLoading) {
    return (
      <DataTableSkeleton
        headers={headerData}
        columnCount={6}
        rowCount={20}
        showToolbar={false}
      />
    );
  }

  if (partsError) {
    return <p>{partsError}</p>;
  }

  if (parts.length === 0) {
    return <p>No parts found</p>;
  }

  const rowData = parts.map((part) => {
    const uploadedAt = new Date(part.uploadedAt);
    const dd = String(uploadedAt.getDate()).padStart(2, '0');
    const mm = String(uploadedAt.getMonth() + 1).padStart(2, '0');
    const yyyy = uploadedAt.getFullYear();
    return {
      id: part.id,
      filename: part.filename || <SkeletonText />,
      status: <QuoteStatusTag partID={part.id} />,
      user: part.uid ? <UserEmail userID={part.uid} backupEmail={part.email} /> : <SkeletonText />,
      uploadedAt: part.uploadedAt ? `${yyyy}-${mm}-${dd}` : <SkeletonText />,
      fusion: part.quoteData.forgeProjectID ? <OpenInFusion kind="link" forgeProjectID={part.quoteData.forgeProjectID} /> : null,
      quoteLinks: (
        <>
          <Link to={`/part/${part.id}`}>Edit</Link>
          {' | '}
          <a href={`https://parallelfluidics.com/parts/view?quote=${part.id}`} target="_blank" rel="noreferrer">View Live</a>
        </>),
    };
  }).sort((a, b) => {
    if (a.uploadedAt > b.uploadedAt) {
      return -1;
    } if (a.uploadedAt < b.uploadedAt) {
      return 1;
    }
    return 0;
  });

  return (
    <DataTable rows={rowData} headers={headerData} isSortable>
      {({
        rows, headers, getHeaderProps, getTableProps,
      }) => (
        <TableContainer title={title}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <TableHeader {...getHeaderProps({ header })} isSortable={header.isSortable}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id}>{cell.value}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  );
}

PartsList.defaultProps = {
  title: 'Parts',
};

PartsList.propTypes = {
  title: PropTypes.string,
  partsQuery: PropTypes.instanceOf(Query).isRequired,
};

export default PartsList;
