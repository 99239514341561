/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  DataTable,
  Modal,
  OverflowMenu,
  OverflowMenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarAction,
  TableToolbarContent,
  TableToolbarMenu,
  Tag,
  TextInput,
  Toggle,
} from 'carbon-components-react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import * as Yup from 'yup';

function EditMaterial({
  material, unallowedIDs, setMaterial, isOpen, close,
}) {
  const schema = Yup.object().shape({
    id: Yup.string().required().notOneOf(unallowedIDs, 'ID already exists'),
    display: Yup.string().required(),
    isDefault: Yup.boolean(),
    cappingStyle: Yup.array().of(Yup.object().shape({
      display: Yup.string().required('Display name is required'),
      isDefault: Yup.boolean(),
    })).required(),
  });

  return (
    <Formik
      initialValues={material}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        setMaterial(values);
        close();
        setSubmitting(false);
      }}
    >
      {({
        values, errors, handleBlur, handleChange, handleSubmit, setFieldValue,
      }) => (
        <Modal
          size="lg"
          open={isOpen}
          onRequestClose={() => { close(); }}
          modalHeading="Edit material"
          primaryButtonText="Update"
          secondaryButtonText="Cancel"
          onRequestSubmit={handleSubmit}
        >
          <div className="bx--row">
            <div className="bx--col">
              <TextInput
                labelText="Material Display Name"
                id="display"
                key="display"
                value={values.display}
                onBlur={handleBlur}
                onChange={(e) => {
                  const newDisplay = e.target.value;
                  if (newDisplay) {
                    setFieldValue('id', newDisplay.toLowerCase().replace(/[^a-z0-9]+/g, '-'));
                  }
                  handleChange(e);
                }}
                invalidText={errors.display}
                invalid={!!errors.display}
              />
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <TextInput
                labelText="Material Internal ID (only manually set this if you're sure you know what you're doing)"
                id="id"
                key="id"
                value={values.id}
                onBlur={handleBlur}
                onChange={handleChange}
                invalidText={errors.id}
                invalid={!!errors.id}
              />
            </div>
            <div className="bx--col">
              <Toggle
                labelText={material.isDefault ? 'Is Default (make another material default to uncheck this one)' : 'Is Default'}
                id="isDefault"
                key="isDefault"
                toggled={!!values.isDefault}
                disabled={!!material.isDefault}
                onClick={(e) => {
                  setFieldValue('isDefault', e.target.checked);
                }}
              />
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <DataTable
                rows={values.cappingStyle}
                headers={[
                  {
                    key: 'display',
                    header: 'Capping Option Display Name',
                  },
                  {
                    key: 'isDefault',
                    header: 'Is Default',
                  },
                ]}
                render={({
                  rows, headers, getHeaderProps, getRowProps, getTableProps,
                }) => (
                  <TableContainer>
                    <TableToolbar>
                      <TableToolbarContent>
                        <Button
                          kind="primary"
                          onClick={() => {
                            setFieldValue('cappingStyle', [
                              ...values.cappingStyle,
                              {
                                id: '',
                                display: '',
                                isDefault: false,
                              },
                            ]);
                          }}
                        >
                          Add Capping Option
                        </Button>
                      </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          {headers.map((header) => (
                            <TableHeader {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                          <TableHeader key="overflow-menu" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, rowIndex) => (
                          <TableRow {...getRowProps({ row })}>
                            {row.cells.map((cell) => {
                              switch (cell.info.header) {
                                case 'display':
                                  return (
                                    <TableCell key={cell.id}>
                                      <TextInput
                                        labelText="Display Name"
                                        hideLabel
                                        id={`cappingStyle[${rowIndex}].display`}
                                        key={cell.id}
                                        value={cell.value}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        invalidText={(
                                          errors.cappingStyle
                                           && errors.cappingStyle[rowIndex]
                                           && errors.cappingStyle[rowIndex].display)}
                                        invalid={!!(
                                          errors.cappingStyle
                                          && errors.cappingStyle[rowIndex]
                                          && errors.cappingStyle[rowIndex].display)}
                                      />
                                    </TableCell>
                                  );
                                case 'isDefault':
                                  return (
                                    <TableCell key={cell.id}>
                                      <Button
                                        kind="ghost"
                                        size="small"
                                        onClick={() => {
                                          const newValue = !cell.value;
                                          if (newValue) {
                                            for (let i = 0;
                                              i < values.cappingStyle.length;
                                              i += 1) {
                                              if (i !== rowIndex) {
                                                setFieldValue(`cappingStyle[${i}].isDefault`, false);
                                              }
                                            }
                                          }
                                          setFieldValue(`cappingStyle[${rowIndex}].isDefault`, newValue);
                                        }}
                                      >
                                        {cell.value ? 'Yes' : 'No'}
                                      </Button>
                                    </TableCell>
                                  );
                                default:
                                  return (
                                    <TableCell key={cell.id}>
                                      {cell.value}
                                    </TableCell>
                                  );
                              }
                            })}
                            <TableCell key={`${row.id}:overflow`} width={32} className="bx--table-column-menu">
                              <Button
                                kind="danger--ghost"
                                size="small"
                                onClick={() => {
                                  setFieldValue('cappingStyle', values.cappingStyle.filter((item, i) => i !== rowIndex));
                                }}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              />
            </div>
          </div>
        </Modal>
      )}
    </Formik>
  );
}

EditMaterial.propTypes = {
  material: PropTypes.shape({
    id: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    isDefault: PropTypes.bool,
    cappingStyle: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
      isDefault: PropTypes.bool,
    })).isRequired,
  }).isRequired,
  unallowedIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  setMaterial: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

function Materials({
  materials,
  setFieldValue,
  setFieldTouched,
  touched,
  disabled,
  isSubmitting,
  onSubmit: handleSubmit,
}) {
  const [focusedMaterialItem, setFocusedMaterialItem] = useState({
    id: '',
    display: '',
    isDefault: false,
    cappingStyle: [],
  });
  const unallowedIDs = useMemo(() => materials.map(
    (material) => material.id,
  ).filter(
    (id) => id !== focusedMaterialItem.id,
  ), [materials, focusedMaterialItem]);
  const [updateMaterialFunc, setUpdateMaterialFunc] = useState(() => () => {});
  const [isMaterialEditorOpen, setMaterialEditorOpen] = useState(false);

  const headerData = [
    {
      header: 'Material',
      key: 'display',
    },
    {
      header: 'Default',
      key: 'isDefault',
    },
    {
      header: 'Material Internal ID',
      key: 'id',
    },
    {
      header: 'Capping Options',
      key: 'cappingStyle',
    },
  ];
  return (
    <>
      {typeof document === 'undefined'
        ? null
        : ReactDOM.createPortal(
          <EditMaterial
            material={focusedMaterialItem}
            unallowedIDs={unallowedIDs}
            isOpen={isMaterialEditorOpen}
            close={() => {
              setMaterialEditorOpen(false);
              setFocusedMaterialItem({
                id: '', display: '', isDefault: false, cappingStyle: [],
              });
            }}
            setMaterial={updateMaterialFunc}
          />,
          document.body,
        )}
      <DataTable
        rows={materials}
        headers={headerData}
      >
        {({
          rows, headers, getToolbarProps, getHeaderProps, getTableProps,
        }) => {
          // edit a material
          const handleEditMaterial = (index) => {
            setFocusedMaterialItem({
              id: materials[index].id,
              display: materials[index].display,
              isDefault: materials[index].isDefault,
              cappingStyle: materials[index].cappingStyle,
            });

            setUpdateMaterialFunc(() => (values) => {
              const newMaterial = { ...values };
              newMaterial.cappingStyle = newMaterial.cappingStyle.map((cappingOption) => ({
                ...cappingOption,
                isDefault: cappingOption.isDefault || false,
                id: cappingOption.display.toLowerCase().replace(/[^a-z0-9]+/g, '-'),
              }));

              if (newMaterial.isDefault) {
                for (let i = 0; i < materials.length; i += 1) {
                  if (i !== index) {
                    setFieldValue(`materials[${i}].isDefault`, false);
                  }
                }
              }

              setFieldValue(`materials[${index}]`, newMaterial);
              setFieldTouched(`materials[${index}]`);
            });

            setMaterialEditorOpen(true);
          };

          // add a new material
          const handleAddMaterial = () => {
            const newMaterial = {
              id: '',
              display: '',
              isDefault: false,
              cappingStyle: [],
            };
            setFieldValue('materials', [...materials, newMaterial]);
            setFieldTouched('materials');
          };

          // delete a material
          const handleDeleteMaterial = (index) => {
            setFieldValue('materials', materials.filter((item, i) => i !== index));
            setFieldTouched('materials');
          };

          return (
            <TableContainer title="Materials">
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <TableToolbarMenu>
                    <TableToolbarAction
                      onClick={handleAddMaterial}
                      disabled={disabled || isSubmitting}
                    >
                      Add new material
                    </TableToolbarAction>
                  </TableToolbarMenu>
                  <Button
                    disabled={!touched || disabled || isSubmitting}
                    onClick={handleSubmit}
                  >
                    {isSubmitting ? 'Please wait...' : 'Save'}
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                    <TableHeader key="overflow-menu" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, rowIndex) => (
                    <TableRow key={row.id}>
                      {row.cells.map((cell) => {
                        switch (cell.info.header) {
                          case 'isDefault': {
                            return <TableCell key={cell.id}>{cell.value ? '✅' : null}</TableCell>;
                          }
                          case 'cappingStyle': {
                            return (
                              <TableCell key={cell.id}>
                                {cell.value.map((cappingOption) => (
                                  <Tag key={cappingOption.display} type={cappingOption.isDefault ? 'green' : 'gray'}>{cappingOption.display}</Tag>
                                ))}
                              </TableCell>
                            );
                          }
                          default: {
                            return <TableCell key={cell.id}>{cell.value}</TableCell>;
                          }
                        }
                      })}
                      <TableCell key={`${row.id}:overflow`} width={32} className="bx--table-column-menu">
                        <OverflowMenu size="sm">
                          <OverflowMenuItem
                            key={`${row.id}:overflow:edit`}
                            itemText="Edit"
                            onClick={() => { handleEditMaterial(rowIndex); }}
                            disabled={disabled || isSubmitting}
                          />
                          <OverflowMenuItem
                            key={`${row.id}:overflow:delete`}
                            isDelete
                            itemText="Delete"
                            disabled={materials.length === 1 || disabled || isSubmitting}
                            onClick={() => { handleDeleteMaterial(rowIndex); }}
                          />
                        </OverflowMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </DataTable>
    </>
  );
}

Materials.propTypes = {
  materials: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    isDefault: PropTypes.bool,
    cappingStyle: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
      isDefault: PropTypes.bool,
    })).isRequired,
  })).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

Materials.defaultProps = {
  disabled: false,
  touched: false,
};

export default Materials;
