import {
  Button,
  Tag,
} from 'carbon-components-react';
import React, { useMemo, useState } from 'react';

import { usePLCValue, usePLCWrite } from '../../../features/plc/context';
import ButtonWithDisabledReason from '../../ButtonWithDisabledReason';
import DownloadConfigButton from '../components/downloadConfigButton';
import EndAutomationModal from '../components/endModal';
import FirebaseSession from '../components/firebaseSession';
import LoadConfigModal from '../components/loadConfig';
import PLCTimeInput from '../components/plcTimeInput';
import PLCToggleButton from '../components/plcToggleButton';
import StartAutomationModal from '../components/startModal';
import { usePressHandle, useProgramHandle } from '../hooks/context';

function AutomationControls() {
  const write = usePLCWrite();

  const pressHandle = usePressHandle();
  const programHandle = useProgramHandle();

  const airStatus = usePLCValue(`${programHandle}.AirStatus`);
  const chillerStatus = usePLCValue(`${programHandle}.ChillerStatus`);
  const instronAudio = usePLCValue(`${programHandle}.InstronAudio`);
  const strain = usePLCValue(`${programHandle}.Strain`);

  const automationButtonsDisabled = useMemo(
    () => !airStatus || !chillerStatus,
    [airStatus, chillerStatus],
  );

  const lowerHotBlockHeaterSystemOn = usePLCValue(`${programHandle}.bLowerHotBlockHeaterSystemOn`);
  const lowerRearHeaterSystemOn = usePLCValue(`${programHandle}.bLowerRearHeaterSystemOn`);
  const lowerFrontHeaterSystemOn = usePLCValue(`${programHandle}.bLowerFrontHeaterSystemOn`);
  const upperHotBlockHeaterSystemOn = usePLCValue(`${programHandle}.bUpperHotBlockHeaterSystemOn`);
  const upperRearHeaterSystemOn = usePLCValue(`${programHandle}.bUpperRearHeaterSystemOn`);
  const upperFrontHeaterSystemOn = usePLCValue(`${programHandle}.bUpperFrontHeaterSystemOn`);

  const allHeatersOn = useMemo(() => [
    lowerHotBlockHeaterSystemOn,
    lowerRearHeaterSystemOn,
    lowerFrontHeaterSystemOn,
    upperHotBlockHeaterSystemOn,
    upperRearHeaterSystemOn,
    upperFrontHeaterSystemOn,
  ].every((heater) => heater), [
    lowerHotBlockHeaterSystemOn,
    lowerRearHeaterSystemOn,
    lowerFrontHeaterSystemOn,
    upperHotBlockHeaterSystemOn,
    upperRearHeaterSystemOn,
    upperFrontHeaterSystemOn,
  ]);

  const [isStartModalOpen, setIsStartModalOpen] = useState(false);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const automationEnabledHandle = useMemo(() => `${programHandle}.bProcessAutomationEnabled`, [pressHandle]);
  const automationEnabled = usePLCValue(automationEnabledHandle);

  return (
    <>
      <StartAutomationModal
        isOpen={isStartModalOpen}
        close={() => { setIsStartModalOpen(false); }}
        process="forming"
      />
      <LoadConfigModal
        isOpen={isConfigModalOpen}
        close={() => { setIsConfigModalOpen(false); }}
        process="forming"
      />
      <EndAutomationModal />
      <div className="bx--row">
        <div className="bx--col">
          {automationEnabled ? (
            <Button
              onClick={() => { write({ handle: automationEnabledHandle, value: false }); }}
              kind="danger"
            >
              Disable automation
            </Button>
          ) : (
            <ButtonWithDisabledReason
              onClick={() => setIsStartModalOpen(true)}
              disabled={automationButtonsDisabled || !allHeatersOn}
              disabledReason={automationButtonsDisabled ? 'Air or chiller is off' : 'Not all heaters are on'}
            >
              Enable automation
            </ButtonWithDisabledReason>
          )}
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <Button
            onClick={() => setIsConfigModalOpen(true)}
          >
            Load configuration
          </Button>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <DownloadConfigButton process="forming" />
        </div>
      </div>
      <FirebaseSession />
      <div className="bx--row">
        <div className="bx--col">
          <PLCTimeInput
            label="Presoak Time"
            handle={`${programHandle}.tPresoakDelayTime`}
          />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <PLCTimeInput
            label="Coolsoak Time"
            handle={`${programHandle}.tCoolsoakDelayTime`}
          />
        </div>
      </div>
      <div className="bx--row" style={{ marginTop: 16 }}>
        <div className="bx--col">
          <Tag type={airStatus ? 'green' : 'red'} style={{ width: '100%', height: '3rem' }}>
            {airStatus ? 'Air On' : 'Air Off'}
          </Tag>
        </div>
        <div className="bx--col">
          <Tag type={chillerStatus ? 'green' : 'red'} style={{ width: '100%', height: '3rem' }}>
            {chillerStatus ? 'Chiller On' : 'Chiller Off'}
          </Tag>
        </div>
      </div>
      <div className="bx--row" style={{ marginTop: 16 }}>
        <div className="bx--col">
          <Tag type={allHeatersOn ? 'green' : 'purple'} style={{ width: '100%', height: '3rem' }}>
            {allHeatersOn ? 'All Heaters On' : 'Some (or All) Heaters Off'}
          </Tag>
        </div>
      </div>
      <div className="bx--row" style={{ marginTop: 16 }}>
        <div className="bx--col">
          <Tag type={instronAudio ? 'green' : 'gray'} style={{ width: '100%', height: '3rem' }}>
            {instronAudio ? 'Instron Audio On' : 'Instron Audio Off'}
          </Tag>
        </div>
      </div>
      <div className="bx--row" style={{ marginTop: 16 }}>
        <div className="bx--col">
          <Button
            onClick={() => { write({ handle: `${programHandle}.Strain`, value: !strain }); }}
          >
            Trigger Strain (force Instron forward)
          </Button>
        </div>
        <div className="bx--col">
          <Button
            onClick={() => { write({ handle: `${programHandle}.bInstronManualOverride`, value: true }); }}
          >
            Trigger Instron Audio (force PLC forward)
          </Button>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <PLCToggleButton
            handle={`${pressHandle}.Vacuum`}
            label="vacuum"
          />
        </div>
      </div>
    </>
  );
}

export default AutomationControls;
