import {
  InlineNotification,
  Pagination,
} from 'carbon-components-react';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import SummaryTable from '../../../components/ProcessData/v0';

export default function SummaryPage() {
  const processType = 'forming';
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ? parseInt(searchParams.get('page'), 10) : 1;

  if (!['forming', 'bonding'].includes(processType)) {
    return (
      <InlineNotification
        title="Invalid process"
        subtitle={`${processType} is not a valid process type`}
        kind="error"
        hideCloseButton
        lowContrast
        style={{ minWidth: '100%', width: '100%' }}
      />
    );
  }

  return (
    <>
      <SummaryTable processType={processType} page={page} pageSize={50} />
      <Pagination
        pageInputDisabled
        pagesUnknown
        page={page}
        pageSize={50}
        pageSizes={[50]}
        onChange={(newPageInfo) => { setSearchParams({ page: newPageInfo.page }); }}
      />
    </>
  );
}
