import {
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';
import React, {
  useEffect,
  useState,
} from 'react';

import { usePLCValue } from '../../../features/plc/context';
import { useProgramHandle } from '../hooks/context';

export default function FirebaseSession() {
  const programHandle = useProgramHandle();
  const firebaseSessionId = usePLCValue(`${programHandle}.sFirebaseSessionID`);

  const [session, setSession] = useState();
  useEffect(() => {
    setSession(undefined);
    if (!firebaseSessionId) return;
    (async () => {
      const firestore = getFirestore();
      const snapshot = await getDoc(doc(firestore, 'process_logs', firebaseSessionId));
      setSession(snapshot.data());
    })();
  }, [firebaseSessionId]);

  if (!session) {
    return null;
  }

  return (
    <div className="bx--row">
      <div className="bx--col">
        <p>
          <span style={{ fontWeight: 700 }}>Project:</span>
          {' '}
          {session.project}
        </p>
        <p>
          <span style={{ fontWeight: 700 }}>Number:</span>
          {' '}
          {session.number}
        </p>
        <p>
          <span style={{ fontWeight: 700 }}>Hypothesis:</span>
          {' '}
          {session.hypothesis}
        </p>
      </div>
    </div>
  );
}
