import { Gift32, Scan32 } from '@carbon/icons-react';
import {
  Box,
  Button,
  Modal,
} from '@cloudscape-design/components';
import {
  Button as CarbonButton,
  Header,
  HeaderContainer,
  HeaderGlobalBar,
  HeaderMenu,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderName,
  HeaderNavigation,
  HeaderSideNavItems,
  SideNav,
  SideNavItems,
  SideNavMenu,
  SkipToContent,
} from 'carbon-components-react';
import { getAuth, signOut } from 'firebase/auth';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import FirebaseContext from '../../app/firebase';
import TravelerScanner from '../QRCodeScanner';

function Navigation() {
  const { user } = useContext(FirebaseContext);
  const [isScannerOpen, setIsScannerOpen] = useState(false);

  if (!user) {
    return null;
  }

  return (
    <>
      <Modal
        header="Scan Traveler"
        footer={(
          <Box float="right">
            <Button
              variant="link"
              onClick={() => { setIsScannerOpen(false); }}
            >
              Cancel
            </Button>
          </Box>
        )}
        visible={isScannerOpen}
        onDismiss={() => { setIsScannerOpen(false); }}
      >
        {isScannerOpen ? (
          <TravelerScanner
            id="nav-scanner"
            onQRCodeSuccess={(qrCode) => { window.location.href = qrCode; }}
          />
        ) : null}
      </Modal>
      <HeaderContainer render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header
          aria-label="Parascope"
        >
          <SkipToContent />
          <HeaderMenuButton
            aria-label="Open menu"
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <img src={`${process.env.PUBLIC_URL}/badge-white.svg`} alt="Logo badge" width={30} height={30} style={{ marginLeft: 16 }} />
          <HeaderName
            element={Link}
            to="/"
            prefix="Parallel"
          >
            Parascope
          </HeaderName>
          <HeaderNavigation aria-label="Parascope">
            <HeaderMenu aria-label="HMI" menuLinkName="HMI">
              <HeaderMenuItem element={Link} to="/live/press1">Press 1</HeaderMenuItem>
              <HeaderMenuItem element={Link} to="/live/press2">Press 2</HeaderMenuItem>
              <HeaderMenuItem element={Link} to="/live/press3">Press 3 (Forming Only)</HeaderMenuItem>
              <HeaderMenuItem element={Link} to="/live/press4">Press 4 (Bonding Only)</HeaderMenuItem>
            </HeaderMenu>
            <HeaderMenu aria-label="Process Data" menuLinkName="Process Data">
              <HeaderMenuItem element={Link} to="/process-data/forming">Forming</HeaderMenuItem>
              <HeaderMenuItem element={Link} to="/process-data/bonding">Bonding</HeaderMenuItem>
            </HeaderMenu>
            <HeaderMenu aria-label="Legacy Process Data" menuLinkName="Legacy Process Data">
              <HeaderMenuItem element={Link} to="/process-data/v2/forming">Line 2 Forming</HeaderMenuItem>
              <HeaderMenuItem element={Link} to="/process-data/v1/forming">Line 1.5 Forming</HeaderMenuItem>
              <HeaderMenuItem element={Link} to="/process-data/v0/forming">Line 0 Forming</HeaderMenuItem>
              <HeaderMenuItem element={Link} to="/process-data/v2/bonding">Line 2 Bonding</HeaderMenuItem>
              <HeaderMenuItem element={Link} to="/process-data/v0/bonding">Line 0 Bonding</HeaderMenuItem>
            </HeaderMenu>
          </HeaderNavigation>
          <SideNav
            aria-label="Side navigation"
            expanded={isSideNavExpanded}
            isPersistent={false}
          >
            <SideNavItems>
              <HeaderSideNavItems>
                <SideNavMenu title="HMI">
                  <HeaderMenuItem element={Link} to="/live/press1">Press 1</HeaderMenuItem>
                  <HeaderMenuItem element={Link} to="/live/press2">Press 2</HeaderMenuItem>
                  <HeaderMenuItem element={Link} to="/live/press3">Press 3 (Forming Only)</HeaderMenuItem>
                  <HeaderMenuItem element={Link} to="/live/press4">Press 4 (Bonding Only)</HeaderMenuItem>
                </SideNavMenu>
                <SideNavMenu title="Process Data">
                  <HeaderMenuItem element={Link} to="/process-data/forming">Forming</HeaderMenuItem>
                  <HeaderMenuItem element={Link} to="/process-data/bonding">Bonding</HeaderMenuItem>
                </SideNavMenu>
                <SideNavMenu title="Legacy Process Data">
                  <HeaderMenuItem element={Link} to="/process-data/v2/forming">Line 2 Forming</HeaderMenuItem>
                  <HeaderMenuItem element={Link} to="/process-data/v1/forming">Line 1.5 Forming</HeaderMenuItem>
                  <HeaderMenuItem element={Link} to="/process-data/v0/forming">Line 0 Forming</HeaderMenuItem>
                  <HeaderMenuItem element={Link} to="/process-data/v2/bonding">Line 2 Bonding</HeaderMenuItem>
                  <HeaderMenuItem element={Link} to="/process-data/v0/bonding">Line 0 Bonding</HeaderMenuItem>
                </SideNavMenu>
              </HeaderSideNavItems>
            </SideNavItems>
          </SideNav>
          <HeaderGlobalBar>
            <CarbonButton
              renderIcon={Scan32}
              iconDescription="Scan"
              hasIconOnly
              kind="secondary"
              onClick={() => { setIsScannerOpen(true); }}
            />
            <CarbonButton
              renderIcon={Gift32}
              iconDescription="Feedback"
              hasIconOnly
              kind="secondary"
              onClick={() => {
                window.open('https://www.notion.so/parallelfluidics/Parascope-Github-Tasks-3f81ee7001c342a987b4aa5f9b29c495', '_blank');
              }}
            />
            <CarbonButton
              onClick={() => {
                signOut(getAuth());
              }}
              kind="danger"
            >
              Sign out
            </CarbonButton>
          </HeaderGlobalBar>
        </Header>
      )}
      />
    </>
  );
}

export default Navigation;
