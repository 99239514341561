import Multiselect from '@cloudscape-design/components/multiselect';
import {
  collection,
  doc,
  documentId,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, {
  useMemo,
  useState,
} from 'react';
import {
  useFirestoreCollectionData,
  useFirestoreDocData,
} from 'reactfire';

function ProcessMultiselect({
  projectId, process, selectedOptions, setValue,
}) {
  // Run queries for potential matches
  // selected process_logs
  const {
    data: selectedProcessLogs,
    status: selectedProcessLogQueryStatus,
  } = useFirestoreCollectionData(
    query(
      collection(getFirestore(), 'process_logs'),
      where(documentId(), 'in', [...selectedOptions.map((option) => option.id), 'nonexistent']),
    ),
    { idField: 'id' },
  );

  // process_logs that match the inputted projectId
  const {
    data: projectProcessLogs,
    status: projectProcessLogQueryStatus,
  } = useFirestoreCollectionData(
    query(
      collection(getFirestore(), 'process_logs'),
      where('process', '==', process),
      where('project', '==', projectId),
      orderBy('start', 'desc'),
    ),
    { idField: 'id' },
  );

  // 10 most recent process_logs
  const {
    data: recentProcessLogs,
    status: recentProcessLogQueryStatus,
  } = useFirestoreCollectionData(
    query(
      collection(getFirestore(), 'process_logs'),
      where('process', '==', process),
      orderBy('start', 'desc'),
      limit(10),
    ),
    { idField: 'id' },
  );

  // matching process_logs for the inputted filterText (in case an id is entered manually)
  const [filterText, setFilterText] = useState('');
  const { data: filteredRunId } = useFirestoreDocData(doc(getFirestore(), 'process_logs', filterText || 'nonexistent'), { idField: 'id' });

  // Combine all the process_logs into one array
  const [multiselectOptions, multiselectSelectedOptions] = useMemo(() => {
    const processLogMap = new Set();
    const mapProcessLogs = (processLogs) => (processLogs || []).map((processLog) => {
      if (processLogMap.has(processLog.id)) {
        return null;
      }
      processLogMap.add(processLog.id);
      return {
        label: `${processLog.project} - ${processLog.number}`,
        description: `https://admin.parallelfluidics.com/process-data/${process}/${processLog.id}`,
        value: {
          id: processLog.id,
          project: processLog.project,
          runID: processLog.number,
        },
      };
    }).filter(
      (processLog) => processLog,
    );

    const mappedProjectProcessLogs = mapProcessLogs(projectProcessLogs);
    const mappedRecentProcessLogs = mapProcessLogs(recentProcessLogs);
    // this needs to go last so we filter out duplicates in the standard queries
    const mappedSelectedProcessLogs = mapProcessLogs(selectedProcessLogs);

    const options = [];
    if (mappedSelectedProcessLogs.length > 0) {
      options.push(...mappedSelectedProcessLogs);
    }
    if (mappedProjectProcessLogs.length > 0) {
      options.push({ label: `Project: ${projectId}`, disabled: true, value: 'INVALID' });
      options.push(...mappedProjectProcessLogs);
    }
    if (mappedRecentProcessLogs.length > 0) {
      options.push({ label: 'Recent runs', disabled: true, value: 'INVALID' });
      options.push(...mappedRecentProcessLogs);
    }

    return [
      options,
      selectedOptions
        .map((selectedOption) => options.find((option) => option?.value?.id === selectedOption.id))
        .filter((option) => option),
    ];
  }, [projectProcessLogs, recentProcessLogs, selectedProcessLogs, selectedOptions]);

  // Filter the options based on the inputted filterText
  const filteredOptions = useMemo(() => {
    if (!filterText) {
      return multiselectOptions;
    }
    return multiselectOptions.filter((processLog) => (
      processLog.label.toLowerCase().includes(filterText.toLowerCase())
        || (processLog.description
          && processLog.description.toLowerCase().includes(filterText.toLowerCase()))
    ));
  }, [filterText, multiselectOptions]);

  return (
    <Multiselect
      selectedOptions={multiselectSelectedOptions}
      // if any status is loading, statusType is loading
      statusType={[
        selectedProcessLogQueryStatus,
        projectProcessLogQueryStatus,
        recentProcessLogQueryStatus,
      ].some((status) => status === 'loading') ? 'loading' : 'finished'}
      onChange={({ detail }) => { setValue(detail.selectedOptions.map((option) => option.value)); }}
      options={filteredRunId ? [{
        label: `${filteredRunId.project} - ${filteredRunId.number}`,
        description: `https://admin.parallelfluidics.com/process-data/${process}/${filteredRunId.id}`,
        value: {
          id: filteredRunId.id,
          project: filteredRunId.project,
          runID: filteredRunId.number,
        },
      }] : filteredOptions}
      filteringType="manual"
      placeholder={`Select ${process} runs`}
      onLoadItems={({ detail }) => setFilterText(detail.filteringText)}
    />
  );
}

ProcessMultiselect.propTypes = {
  projectId: PropTypes.string,
  process: PropTypes.string.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    project: PropTypes.string,
    runID: PropTypes.number,
  })),
  setValue: PropTypes.func.isRequired,
};

ProcessMultiselect.defaultProps = {
  projectId: null,
  selectedOptions: [],
};

export default ProcessMultiselect;
