import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/webpack-resolver';

import { transform } from '@babel/standalone';
import {
  Button,
  CodeSnippet,
  InlineNotification,
} from 'carbon-components-react';
import {
  doc, getFirestore, updateDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import AceEditor from 'react-ace';
import { useSelector } from 'react-redux';

import FirebaseContext from '../../app/firebase';
import { selectQuote } from '../../features/parts/partsSlice';

const V1_PRICING_VERSION = 'v1';
const V2_PRICING_VERSION = 'v2';

function V1Pricing({ partID }) {
  // only allow some users to edit pricing using this form
  const { user } = useContext(FirebaseContext);
  let allowEdits = false;
  if (user.email === 'andy@parallelfluidics.com') {
    allowEdits = true;
  }

  // get quote informaiton
  const quote = useSelector(selectQuote(partID));
  const { pricingVersion, pricingV1, hasPendingWrites } = quote;
  const v1PricingEnabled = pricingVersion === V1_PRICING_VERSION;

  // set up code parsing
  const [convertedCode, setConvertedCode] = useState(pricingV1 ? pricingV1.convertedCode : null);
  useEffect(() => {
    if (pricingV1) {
      setConvertedCode(pricingV1.convertedCode);
    }
  }, [pricingV1]);
  const calculate = useMemo(() => {
    // eslint-disable-next-line no-new-func
    if (convertedCode) { return Function('quote', 'options', convertedCode); }
    return null;
  }, [convertedCode]);
  const [conversionError, setConversionError] = useState(null);

  // set up form values
  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const initialValues = {
    pricingCode: pricingV1 ? pricingV1.originalCode : '',
    options: JSON.stringify({
      material: 'pmma',
      cappingStyle: 'uncapped',
      quantity: 5,
      delivery: 'leadtime-standard',
    }, null, 2),
  };

  return (
    <>
      <div className="bx--row">
        <div className="bx--col">
          {!allowEdits
            ? (
              <InlineNotification
                title="Heads up!"
                subtitle="This is a read-only view of the new pricing system. While it's under development, you can view code, but not edit it."
                kind="warning"
                hideCloseButton
                lowContrast
                style={{ minWidth: '100%', width: '100%' }}
              />
            ) : null}
          <p>
            Write a function that has access to
            {' '}
            <code>quote</code>
            {' '}
            and
            {' '}
            <code>{'options = { material, cappingStyle, numConnectors, quantity, leadtimeMultiples, delivery }'}</code>
            .
            {' '}
          </p>
        </div>
      </div>
      {conversionError ? (
        <InlineNotification
          title="Error setting pricing function"
          subtitle={conversionError}
          kind="error"
          hideCloseButton
          lowContrast
          style={{ minWidth: '100%', width: '100%' }}
        />
      ) : null}
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          setConversionError(null);
          try {
            if (!values.pricingCode) {
              setConvertedCode(null);
            } else {
              const { code } = await transform(`const calculate = (quote, options) => { ${values.pricingCode} }`, { presets: ['env'] });
              setConvertedCode(code.substring(67, code.length - 3)); // remove the function wrapper
            }
          } catch (err) {
            setConversionError(err.message);
            setConvertedCode(null);
          } finally {
            setSubmitting(false);
            setHasBeenEdited(false);
          }
        }}
      >
        {({
          values, handleSubmit, isSubmitting, setFieldValue,
        }) => {
          const price = useMemo(() => {
            try {
              if (calculate) {
                return JSON.stringify(calculate(
                  quote,
                  values.options && values.options.trim() ? JSON.parse(values.options) : {},
                ));
              }
              return 'No calculate function';
            } catch (err) {
              return `Error: ${err.message}`;
            }
          }, [calculate, values.quote, values.options]);

          return (
            <>
              <div className="bx--row">
                <div className="bx--col">
                  <AceEditor
                    mode="javascript"
                    theme="monokai"
                    fontSize={14}
                    value={values.pricingCode}
                    onChange={(newValue) => { setFieldValue('pricingCode', newValue); setHasBeenEdited(true); }}
                    name="pricingCode"
                    editorProps={{ $blockScrolling: true }}
                    style={{ width: '100%' }}
                    setOptions={{
                      readOnly: !allowEdits,
                    }}
                  />
                </div>
                {convertedCode ? (
                  <div className="bx--col">
                    <h4>Browser-safe code</h4>
                    <CodeSnippet
                      type="multi"
                      language="javascript"
                      minCollapsedNumberOfRows={1}
                      maxCollapsedNumberOfRows={5}
                    >
                      {convertedCode}
                    </CodeSnippet>
                    <h4>Quote</h4>
                    <CodeSnippet
                      type="multi"
                      language="javascript"
                      minCollapsedNumberOfRows={1}
                      maxCollapsedNumberOfRows={5}
                    >
                      {JSON.stringify(quote, null, 2)}
                    </CodeSnippet>
                    <h4>Options</h4>
                    <AceEditor
                      mode="javascript"
                      theme="monokai"
                      fontSize={12}
                      value={values.options}
                      onChange={(newValue) => { setFieldValue('options', newValue); }}
                      name="options"
                      editorProps={{ $blockScrolling: true }}
                      minLines={3}
                      maxLines={10}
                      style={{ width: '100%', marginBottom: '1rem' }}
                    />
                    <h4>Response</h4>
                    <CodeSnippet
                      type="multi"
                      language="javascript"
                      style={{ minWidth: '100%', width: '100%', marginBottom: '1rem' }}
                    >
                      {price}
                    </CodeSnippet>
                  </div>
                ) : null}
              </div>
              <div className="bx--row">
                <div className="bx--col">
                  <Button kind="secondary" onClick={handleSubmit} disabled={!allowEdits || isSubmitting || hasPendingWrites} style={{ marginRight: '1rem' }}>Verify</Button>
                  <Button
                    onClick={() => {
                      const quoteRef = doc(getFirestore(), 'quotes', quote.id);
                      updateDoc(quoteRef, {
                        pricingV1: {
                          originalCode: values.pricingCode,
                          convertedCode,
                        },
                      });
                    }}
                    disabled={!allowEdits || hasBeenEdited || !convertedCode || hasPendingWrites}
                    style={{ marginRight: '1rem' }}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      const quoteRef = doc(getFirestore(), 'quotes', quote.id);
                      const quoteUpdate = { pricingVersion: null };
                      if (v1PricingEnabled) {
                        quoteUpdate.pricingVersion = V2_PRICING_VERSION;
                      } else {
                        quoteUpdate.pricingVersion = V1_PRICING_VERSION;
                      }
                      updateDoc(quoteRef, quoteUpdate);
                    }}
                    disabled={!allowEdits || hasPendingWrites
                      || (!v1PricingEnabled // if pricing is not enabled
                      && ((hasBeenEdited // disable if editor has been changed
                        || !convertedCode) // or there's no converted code
                        || !pricingV1) // and ensure there's code to enable
                      )}
                  >
                    {v1PricingEnabled ? 'Disable V1 pricing' : 'Enable V1 pricing'}
                  </Button>
                </div>

              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
}

V1Pricing.propTypes = {
  partID: PropTypes.string.isRequired,
};

export default V1Pricing;
